import styled from "styled-components";
import { Button as ButtonUI, ResponsiveControl } from '../../../lib/jfy-ui';

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(ButtonUI)`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;

  @media ${ResponsiveControl.tablet("min")} {
    width: 45%;
    min-width: 45%;
  }
`;
