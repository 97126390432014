const slackBot = {};

const SLACK_LOG = process.env.REACT_APP_SLACK_LOG?.toLowerCase?.() === 'true';

slackBot.sendMessage = (type, ...messages) => {
  if (!SLACK_LOG || !process.env.REACT_APP_SLACK_WEBHOOK) return;

  let messagesFormated = '';

  messages[0] = messages[0].replace('[JFY] ', '');

  messages.forEach((msg) => {
    if (msg === null || msg === undefined) return;

    if (msg.constructor === Object || msg.constructor === Array) {
      if (msg.credit_card) {
        msg.credit_card.credit_card_hash = '*confidential*';
      }

      msg = '```' + JSON.stringify(msg, null, 2) + '```';
    }
    messagesFormated += '\n' + msg;
  });

  fetch(process.env.REACT_APP_SLACK_WEBHOOK, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: ':rotating_light: Client Area Front ' + type,
            emoji: true,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: messagesFormated,
          },
        },
      ],
    }),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        console.error(
          `Error trying to send message to slack: ${response.status} - ${
            response.statusText
          }\n${await response.text()}`
        );
      }
    })
    .catch((error) => console.error(`Error trying to send message to slack\n${error}`));
};

export default slackBot;
