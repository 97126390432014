import * as React from 'react'

import { IValidIconProps } from './ValidIcon.i';

const ValidIcon: React.FC<IValidIconProps> = ({ viewBox = "0 0 14 15", fill = "#3BBD86" }) => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
            <path d="M7 0C3.142 0 0 3.326 0 7.41s3.142 7.41 7 7.41 7-3.326 7-7.41S10.858 0 7 0zm0 14.13c-3.5 0-6.349-3.015-6.349-6.72C.651 3.705 3.5.69 7 .69s6.349 3.015 6.349 6.72c0 3.705-2.849 6.72-6.349 6.72z" fill={fill} />
            <path d="M5.942 9.34 3.923 7.013l-.472.449 2.442 2.843 4.64-4.48-.44-.5L5.942 9.34z" fill={fill} />
        </svg>
    )
}


export { ValidIcon }
