import { useEffect, useState } from 'react';
import { CopyIcon } from '../../../../lib/jfy-icons';
import logger from '../../../../utils/logger';
import formatPrice from '../../../../utils/formatPrice';
import { formatTime } from '../../../../utils/formatTime';
import { useHistory } from 'react-router-dom';

import {
  GridContainer,
  QrContainer,
  QrCode,
  TextContainer,
  Button,
  ButtonContainer,
  PixPasteCopyContainer,
  KitBoxIcon,
  Text,
} from './styles';

const Pix = ({ pixContentQrCode, countdownDesablePix, purchaseAmount }) => {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const [configButton, setConfigButton] = useState({
    text: 'Copiar código',
    showIcon: true,
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <GridContainer data-testid='qrcode-pix-payment'>
      <TextContainer>
        <Text fontweight='500'>Falta pouco para receber seu kit!</Text>
        <Text fontweight='500' data-testid='timer-pix-await-payment'>
          Pague seu Pix dentro de{' '}
          {countdownDesablePix > 0 ? formatTime(countdownDesablePix) : '00:00'} para garantir sua
          compra!
        </Text>
      </TextContainer>
      <QrContainer>
        {isMobile ? <KitBoxIcon /> : <QrCode content={pixContentQrCode} />}
        <Text data-testid='content-pix-await-payment'>
          Valor a ser pago no PIX{' '}
          <b data-testid='amount-pix-await-payment'>{formatPrice(purchaseAmount)}</b>
        </Text>
      </QrContainer>
      {isMobile ? (
        <>
          <Text>
            <b>1</b> Copie o código abaixo para pagar via Pix em qualquer aplicativo habilitado
          </Text>
          <Text>
            <b>2</b> Abra o app do seu banco e escolha a opção Pix Copia e Cola e insira o código
            copiado.
          </Text>
        </>
      ) : (
        <>
          <Text>
            <b>1</b> Abra seu aplicativo de pagamento onde você utiliza o Pix e escolha a opção{' '}
            <b>Ler QR Code</b>
          </Text>
          <Text>
            <b>2</b> Você também pode utilizar a opção Pix Copia e Cola no seu app de pagamento.
            Caso prefira esta opção, copie o código abaixo.
          </Text>
        </>
      )}
      <PixPasteCopyContainer>
        <Text bold>
          <p>{pixContentQrCode}</p>
        </Text>
      </PixPasteCopyContainer>
      <ButtonContainer>
        <Button
          variant='solid'
          onClick={() => {
            navigator.clipboard
              .writeText(pixContentQrCode)
              .then(() => {
                setConfigButton({ text: 'Código copiado!', showIcon: false });
                setTimeout(
                  () =>
                    setConfigButton({
                      text: 'Copiar copiado',
                      showIcon: true,
                    }),
                  3000
                );
              })
              .catch((error) => {
                logger.silentError('Copy to clipboard failed\n', error);
              });
          }}
        >
          {configButton.showIcon && <CopyIcon width='20' height='20' />}
          {configButton.text}
        </Button>
        <Button
          variant='outline'
          onClick={() =>
            window.open(`https://api.whatsapp.com/send?text=${pixContentQrCode}`, '_blank').focus()
          }
        >
          Compartilhar código
        </Button>
        <Button
          variant='outline'
          onClick={() =>
            history.push({
              pathname: '/',
              search: window.location.search,
            })
          }
        >
          Retornar à área do cliente
        </Button>
      </ButtonContainer>
    </GridContainer>
  );
};

export { Pix };
