import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { CreditCardPreview } from '../../../components/CreditCardPreview';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';

import styles from './CardDetails.module.css';

const Layout = ({
  goBack,
  cardPreviewData,
  fields,
  isVerifyData,
  handleChange,
  handleSave,
  validateField,
  saveCardToNewOrder,
  setSaveCardToNewOrder,
  isLoading,
}) => {
  return (
    <ClientAreaContainer
      onGoBack={isVerifyData ? false : goBack}
      headerTitle={isVerifyData ? 'Verifique os dados do cartão' : 'Adicionar Cartão'}
      headerClassName={styles.header}
    >
      <Block title={isVerifyData ? 'Verifique as informações abaixo' : 'Insira as informações abaixo'}>
        <div className={styles.cardRootContainer}>
          <div className={styles.creditCardPreviewContainer}>
            <CreditCardPreview
              className={styles.cardPreviewStyle}
              number={cardPreviewData.number}
              holder={cardPreviewData.holder}
              expirationMonth={cardPreviewData.expirationMonth}
              expirationYear={cardPreviewData.expirationYear}
            />
          </div>
          <div className={styles.numberValidAndCvv}>
            <div>
              <h2 className={styles.label}>Número do cartão</h2>
              <Input
                placeholder='Digite o número escrito no cartão'
                className={styles.input}
                maxLength={19}
                type='tel'
                name='cardNumber'
                autoComplete='cc-number'
                onChange={handleChange}
                value={fields.cardNumber?.value}
                valid={!fields.cardNumber?.valid ? fields.cardNumber?.valid : null}
                message={fields.cardNumber.message}
                onBlur={validateField}
                onlyDigit
              />
            </div>
            <div>
              <div className={styles.shortInputs}>
                <div className={styles.expiration}>
                  <h2 className={styles.label}>Validade</h2>
                  <Input
                    className={styles.shortInput}
                    placeholder='MM/AA'
                    maxLength={4}
                    type='tel'
                    name='expirationDate'
                    autoComplete='cc-exp'
                    onChange={handleChange}
                    value={fields.expirationDate?.value}
                    valid={!fields.expirationDate?.valid ? fields.expirationDate?.valid : null}
                    onBlur={validateField}
                    message={fields.expirationDate.message}
                    onlyDigit
                  />
                </div>
                <div>
                  <h2 className={styles.label}>CVV</h2>
                  <Input
                    className={styles.shortInput}
                    placeholder='Código'
                    maxLength={3}
                    type='tel'
                    name='cvv2'
                    autoComplete='cc-csc'
                    onChange={handleChange}
                    value={fields.cvv2?.value}
                    valid={!fields.cvv2?.valid ? fields.cvv2?.valid : null}
                    message={fields.cvv2.message}
                    onlyDigit
                  />
                </div>
              </div>
            </div>
          </div>
          <h2 className={styles.label}>Nome no cartão</h2>
          <Input
            placeholder='Digite o nome escrito no cartão'
            className={styles.input}
            maxLength={100}
            type='text'
            name='cardHolder'
            autoComplete='cc-name'
            onChange={handleChange}
            value={fields.cardHolder?.value}
            valid={!fields.cardHolder?.valid ? fields.cardHolder?.valid : null}
            message={fields.cardHolder.message}
          />
          <div className={styles.shortInputs}>
            <div className={styles.document}>
              <h2 className={styles.label}>CPF do titular</h2>
              <Input
                className={styles.shortInput}
                placeholder='000.000.000-00'
                name='cpfCnpj'
                type='tel'
                maxLength={11}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, true)}
                value={fields.cpfCnpj?.value}
                valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
                message={fields.cpfCnpj.message}
                onlyDigit
              />
            </div>
            <div>
              <h2 className={styles.label}>Nascimento</h2>
              <Input
                className={styles.shortInput}
                placeholder='DD/MM/AAAA'
                maxLength={8}
                type='tel'
                name='birthdate'
                autoComplete='bday'
                value={fields.birthdate?.value}
                valid={!fields.birthdate?.valid ? fields.birthdate?.valid : null}
                message={fields.birthdate.message}
                onChange={handleChange}
                onBlur={validateField}
                onlyDigit
              />
            </div>
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              name='saveCard'
              checked={saveCardToNewOrder}
              onClick={() => setSaveCardToNewOrder(!saveCardToNewOrder)}
              label='Ao finalizar essa compra, desejo salvar meu cartão para compras futuras'
              onChange={handleChange}
            />
          </div>
          <Button
            loading={isLoading}
            data-gtm='action-save-card'
            primary
            className={styles.button}
            onClick={handleSave}
            text='Salvar cartão e continuar'
          />
        </div>
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
