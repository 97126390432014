import * as React from 'react';
import { ICardIconProps } from './CardIcon.i'

const CardIcon: React.FC<ICardIconProps> = ({ color = "#000", width = 20, height = 12, viewBox = "0 0 20 12", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M3.22 6.24h1.868M3.22 7.64h5.692"
      ></path>
      <path stroke={color} strokeMiterlimit="10" strokeWidth="0.75" d="M1 3.12h17.787"></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M17.076 11.51H1.763A.773.773 0 011 10.73V1.78c0-.43.342-.78.763-.78h16.262c.42 0 .762.35.762.78v8.94c0 .43-.342.78-.762.78l-.949.01z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M16.137 8.38h-4.4a.135.135 0 01-.137-.14v-2.7c0-.08.058-.14.136-.14h4.4c.08 0 .138.06.138.14v2.69c-.01.09-.059.15-.137.15z"
      ></path>
    </svg>
  );
}

export { CardIcon };
