import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import * as dataLayerHandler from '../../analytics/dataLayerHandler';
import { sendPurchaseEvent } from '../../analytics/events';
import logger from '../../utils/logger';
import { Layout } from './Layout';
import { useFetch } from '../../hooks';

const Feedback = () => {
  const { orderId, status } = useParams();
  const [countdown, setCountdown] = useState(15);
  const history = useHistory();
  const [orderInfos, setOrderInfos] = useState({});
  const [cartInfos, setCartInfos] = useState({});
  const [products, setProducts] = useState([]);
  const { fetchFromBackend } = useFetch();

  const orderIdParam = orderId?.replace(/[^0-9]/g, '');

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  let checkPaymentStatus = () => {
    logger.debug('Requesting...');

    if (orderIdParam) {
      fetchFromBackend('/api/purchase/payment/credit_card/status/' + orderIdParam, 'GET', {}, true)
        .then((response) => {
          if (response.content.toLowerCase() === 'paid') {
            // Fetch order and then cart to send purchase event
            fetchFromBackend('/api/purchase/orders/' + orderIdParam, 'GET', {}, true)
              .then((orderResponse) => {
                fetchFromBackend(
                  '/api/purchase/carts/' + orderResponse.content.cart_id,
                  'GET',
                  {},
                  true
                )
                  .then((cartResponse) => {
                    if (cartResponse.response_code === 0 && cartResponse.content.result) {
                      dispatchPurchaseEventDatalayer(cartResponse.content.result);
                    }
                  })
                  .catch((error) => {
                    logger.silentError(
                      'Failed to get cart info to datalayer\n',
                      error,
                      '\n',
                      error?.content?.responseJson || null
                    );
                  });
              })
              .catch((error) => {
                logger.silentError(
                  'Failed to get order info\n',
                  error,
                  '\n',
                  error?.responseJson || null
                );
              });

            // Redirect to final page
            history.push('/checkout/order/' + orderIdParam + '/feedback/approved');
          } else if (response.content.toLowerCase() === 'refused') {
            // Redirect to final page
            history.push('/checkout/order/' + orderIdParam + '/feedback/denied');
          }
        })
        .catch((error) => {
          logger.error('Failed to check payment status\n', error, '\n', error.responseJson || null);
        });
    }
    return;
  };

  useEffect(() => {
    if (orderIdParam) {
      fetchFromBackend('/api/purchase/orders/' + orderIdParam, 'GET', {})
        .then((response) => {
          setOrderInfos(response.content);
          fetchFromBackend('/api/purchase/carts/' + response.content.cart_id, 'GET', {})
            .then(async (_response) => {
              let cart = _response.content.result;
              logger.debug('cart', cart);

              const parsedProducts = cart.products.map((element) => ({
                formId: element.form_id,
                formulaName: element.form_name,
                products: element.items,
              }));
              parsedProducts.sort((a, b) => b.products.length - a.products.length);

              setProducts(parsedProducts);
              setCartInfos(cart);
            })
            .catch((error) => {
              logger.error(
                'Failed to get cart info - preview info\n',
                error,
                '\n',
                error?.content?.responseJson || null
              );
            });
        })
        .catch((error) => {
          logger.error(
            'Failed to get order info\n',
            error,
            '\n',
            error?.content?.responseJson || null
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderIdParam]);

  useEffect(() => {
    if (countdown > 0) {
      if (status !== 'awaiting-approval') return;

      setTimeout(() => {
        if (countdown % 3 === 0) {
          checkPaymentStatus();
        }
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      history.push({
        pathname: '/checkout/order/' + orderIdParam + '/feedback/denied',
        search: window.location.search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const dispatchPurchaseEventDatalayer = (cartInfo) => {
    //format object to dataLayer
    const objFormateDataLayerCart = dataLayerHandler.formatCartForDataLayer(cartInfo);

    sendPurchaseEvent(objFormateDataLayerCart?.products, orderIdParam, cartInfo, 'credit_card');
  };

  return (
    <Layout
      orderId={orderIdParam}
      status={status}
      purchaseAmount={cartInfos?.cart?.final_value}
      addressCheckout={orderInfos}
      productsCheckout={products}
    />
  );
};

export { Feedback };
