export const handleChangeGeneric = (props) => (event) => {
    const {onSuggestion, setSuggestion, onChange, setInputContent, onlyDigit, noSpace, maxLength} = props

    event.target.value = formatInput(event.target.value, onlyDigit, noSpace, maxLength);

    if (onSuggestion) {
        setSuggestion();
    }

    if (onChange) {
        onChange?.(event);
    } else {
        setInputContent(event.target.value);
    }
};

export const handleBlurGeneric = (props) => (event) => {
    const {onSuggestion, getSuggestion, setSuggestion, onBlur, type} = props

    if (onSuggestion) {
        setSuggestion(getSuggestion(type, event.target.value));
    }

    if (onBlur) {
        onBlur?.(event);
    }
};

export const formatInput = (inputValue, onlyDigit, noSpace, maxLength) => {
    if (onlyDigit) {
        inputValue = inputValue.replace(/\D/g, '');
    }

    if (noSpace && !onlyDigit) {
        inputValue = inputValue.replace(/\s/g, '');
    }

    if (maxLength) {
        inputValue = inputValue.slice(0, maxLength);
    }

    return inputValue;
};