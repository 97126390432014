export const PaidIcon = ({ color = '#000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      fill="none"
      viewBox="0 0 36 37"
      className={className}
    >
      <path stroke={color} strokeMiterlimit="10" strokeWidth="1.279" d="M5.272 12.438h25.287"></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M8.424 19.329h8.106M17.145 25.223H6.36c-.598 0-1.088-.537-1.088-1.194V10.4c0-.656.49-1.193 1.088-1.193h23.11c.599 0 1.089.537 1.089 1.193V24.03c0 .657-.49 1.194-1.089 1.194h-1.353"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M22.746 28.345c2.382 0 4.313-2.118 4.313-4.731s-1.93-4.732-4.313-4.732c-2.382 0-4.314 2.119-4.314 4.732s1.932 4.731 4.314 4.731z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M25.04 22.56l-.903-.989-1.933 2.12-.85-.934-.904.992 1.737 1.907.017-.015.014.015 2.823-3.096z"
      ></path>
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.75"
        d="M13.844 35.456H1.26V1.576h12.585M22.461 35.456h12.11V1.576h-12.11"
      ></path>
    </svg>
  );
};

export default PaidIcon;
