export const DeliveredIcon = ({ color = '#000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      fill="none"
      viewBox="0 0 40 41"
      className={className}
    >
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.743"
        d="M1.15 21.415V1.87H38.135l-.005 19.545M38.128 35.039l.005 4.445H1.15l-.002-4.445"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M34.87 32.613H1.525c-.29 0-.525-.26-.525-.576V26.75c0-.318.237-.576.525-.576h36.229c.29 0 .525.26.525.576v5.288c0 .319-.238.576-.525.576h-3.921"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M1.21 26.278l5.024-4.455c.225-.2.647-.361.934-.361h26.2c.29 0 .688.188.889.416l3.815 4.4"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M12.507 26.07l1.707-1.723a.558.558 0 01.343-.148h9.726c.108 0 .259.073.33.163l1.398 1.707"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M26.014 26.07v3.649c0 .333-.248.605-.552.605H13.058c-.303 0-.551-.272-.551-.605v-3.65"
      ></path>
      <path
        fill="#fff"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M19.642 18.828c3.014 0 5.457-2.68 5.457-5.986 0-3.305-2.443-5.985-5.457-5.985-3.013 0-5.457 2.68-5.457 5.985 0 3.306 2.444 5.986 5.457 5.986z"
      ></path>
      <path
        fill="#fff"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
        d="M22.545 11.51l-1.143-1.252-2.446 2.683-1.076-1.181-1.143 1.253 2.2 2.414.02-.02.018.02 3.57-3.916z"
      ></path>
    </svg>
  );
};

export default DeliveredIcon;
