import { IAwaitingIconProps } from './AwaitingIcon.i'
import * as React from 'react'


const AWaitingIcon: React.FC<IAwaitingIconProps> = ({ color = "#000", width = 35, height = 36, viewBox = "0 0 35 36", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      {...props}
    >
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.75"
        d="M13.537 35.106H1.08V1.576h12.456M22.065 35.106H34.05V1.576H22.065"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 29c6.075 0 11-4.925 11-11S24.075 7 18 7 7 11.925 7 18s4.925 11 11 11zM18 18l5 3M18 18v-8"
      ></path>
    </svg>
  )
}


export { AWaitingIcon }
