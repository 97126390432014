import { LoadingDots } from '../../../lib/jfy-ui';

import { ClientAreaContainer } from '../../../components/ClientAreaContainer';
import { TabNavigator } from '../../../components/TabNavigator';
import Modal from '../../../components/Modal';
import { DisplayPaymentMethod } from './DisplayPaymentMethod';

import styles from './MyCards.module.css';
import { Button, ButtonContainer } from './styles';

const Layout = ({
  myCards,
  isFetching,
  selectedCard,
  setSelectedCard,
  handleSetPaymentMethodToCheckout,
  addNewCard,
  isOpen,
  openConfirm,
  confirmAction,
  cancelAction,
  isCheckout,
  activeTab,
  handleSwichActiveTab,
  goBack
}) => {
  return (
    <ClientAreaContainer
      initializeMenuClosed={isCheckout}
      onGoBack={isCheckout && goBack}
      headerMenu={!isCheckout && goBack}
      headerClassName={styles.header}
      headerTitle={
        isCheckout ? 'Selecionar uma cartão de crédito' : 'Meus cartões'
      }
      className={styles.pageRootContainer}
    >
      <Modal active={isOpen} onCancel={cancelAction} onAccept={confirmAction} acceptText='Remover'>
        <span className={styles.ContentModal}>
          Deseja remover Cartão de Crédito?
        </span>
      </Modal>
      {isCheckout && myCards.length > 0 && (
        <TabNavigator
          activeTab={activeTab}
          handleSwichActiveTab={handleSwichActiveTab}
        />
      )}
      {isFetching ? (
        <LoadingDots
          className={styles.loadingContainer}
          data-testid='loding-dots'
          size='md'
          diffTimeLoadingAnimation={1.6}
        />
      ) : myCards.length > 0 || isCheckout ? (
        <DisplayPaymentMethod
          styles={styles}
          isCheckout={isCheckout}
          activeTab={activeTab}
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
          myCards={myCards}
          openConfirm={openConfirm}
        />
      ) : (
        <div className={styles.noContent}>
          <span>Nenhum cartão foi encontrado </span>
        </div>
      )}
      <ButtonContainer>
        {isCheckout && myCards.length === 0 ? (
          <Button
            variant='solid'
            onClick={handleSetPaymentMethodToCheckout}
            data-gtm='action-checkout-card-continue'
          >
            Continuar
          </Button>
        ) : isCheckout && (
          <Button
            variant={isCheckout ? 'outline' : 'solid'}
            onClick={addNewCard}
            data-gtm='action-card-add-new-card'
          >
            Adicionar novo cartão
          </Button>
        )}
      </ButtonContainer>
    </ClientAreaContainer>
  );
};
export default Layout;
