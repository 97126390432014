import { ICopyIconProps } from "./CopyIcon.i";
import * as React from 'react'


const CopyIcon: React.FC<ICopyIconProps> = ({ fill = "#fff", width = 17, height = 20, viewBox = "0 0 17 20", ...props }) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path d="M3.682 15h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1Z" fill={fill} />
            <path d="M4.682 1v1h9v11h1V2a1 1 0 0 0-1-1h-9Z" fill={fill} />
        </svg>
    )
}


export { CopyIcon }
