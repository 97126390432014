import { useEffect, useRef, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import logResponseError from '../../utils/loggerMessages';
import logger from '../../utils/logger';
import notify, { PAYMENT_ERRO } from '../../utils/notify';

import { CheckoutContext } from '../../contexts/CheckoutSession/CheckoutSession';
import { useFetch } from '../../hooks';

import { Layout } from './Layout';

const ProcessOrder = () => {
  const history = useHistory();
  let { token } = useParams();
  const location = useLocation();
  const urlUtms = useRef({});
  const { cart } = useContext(CheckoutContext);
  const { fetchFromBackend } = useFetch();

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);

    urlUtms.current = {
      ...(urlSearch.has('utm_medium') && {
        utm_medium: urlSearch.get('utm_medium'),
      }),
      ...(urlSearch.has('utm_campaign') && {
        utm_campaign: urlSearch.get('utm_campaign'),
      }),
      ...(urlSearch.has('utm_content') && {
        utm_content: urlSearch.get('utm_content'),
      }),
      ...(urlSearch.has('utm_term') && { utm_term: urlSearch.get('utm_term') }),
      utm_source: 'area_cliente',
    };

    if (!cart.token) {
      history.replace({
        pathname: '/',
        search: window.location.search,
      });
    }

    getOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnToSummaryPurchase = (statusCode, redirectReason) => {
    const attempt = location?.attempt ? Number(location?.attempt) + 1 : 1;
    history.push({
      pathname: '/checkout/' + token + '/summary-purchase',
      search: window.location.search,
      state: {
        redirectReason: redirectReason,
        statusCode: statusCode,
        attempt: attempt,
      },
    });
  };

  const getOrderId = async () => {
    let currentDate = new Date();
    let date =
      currentDate.getFullYear() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getUTCDate();

    let creditCardInfo = undefined;
    if (cart.paymentInfo.payment_method === 'credit-card') {
      creditCardInfo = {
        credit_card_hash: cart.paymentInfo.cardInfo?.credit_card_hash,
        cvv: cart.paymentInfo.cardInfo?.cvv,
        exp_date: cart.paymentInfo.cardInfo?.expire_date,
        customer_cpf: cart.paymentInfo.cardInfo?.customer_cpf,
        installment_count: cart.installment?.value,
        installment_formatted: cart.installment?.label,
        birth_date: cart.paymentInfo.cardInfo?.birth_date,
        customer_fullname: cart.paymentInfo.cardInfo?.card_owner,
        credit_card_id: cart.paymentInfo.cardInfo?.id_card,
        customer_gateway_id: cart.paymentInfo.cardInfo?.customer_id,
      };
    }

    const reqBody = {
      token: token,
      payment_type:
        cart.paymentInfo.payment_method === 'credit-card'
          ? 'credit_card'
          : cart.paymentInfo.payment_method,
      create_date: date,
      address_alias: cart.addressInfo.customer_id,
      credit_card: creditCardInfo,
      register_card: cart.paymentInfo?.cardInfo?.save_card,
      ...urlUtms.current,
    };

    try {
      const response = await fetchFromBackend(`/api/jfy/purchase/orders/create`, 'POST', reqBody);

      let status_code = response.status;
      let error = response.content;

      if (status_code === 201 || status_code === 200) {
        if (cart.paymentInfo.payment_method === 'pix') {
          history.push({
            pathname: '/checkout/order/' + response.content.id_order + '/awaiting-payment',
            search: window.location.search,
            state: {
              pix_data: {
                code_qr: response.content.qr_code,
                pix_expire_date: response.content.pix_expire_date,
                type: 'pix',
              },
            },
          });
        } else {
          history.push({
            pathname:
              '/checkout/order/' + response.content.id_order + '/feedback/awaiting-approval',
            search: window.location.search,
          });
        }
      } else if (status_code === 500 || status_code === 405 || status_code === 502) {
        notify(PAYMENT_ERRO, 'error');
        logger.silentError(
          'Failed to make a credit card payment\n',
          error,
          '\nStatus code of the Request:',
          status_code,
          '\nBody of the Request:',
          reqBody,
          '\nResponse of the Request:',
          response
        );
        returnToSummaryPurchase(status_code, response?.message);
      } else {
        if (location?.attempt && Number(location?.attempt) > 2) {
          logger.silentError(
            'Failed to make a credit card payment\n',
            error,
            '\nStatus code of the Request:',
            status_code,
            '\nBody of the Request:',
            reqBody,
            '\nResponse of the Request:',
            response
          );
        }
        returnToSummaryPurchase(status_code, response?.message);
      }
    } catch (error) {
      logResponseError(
        'Failed to complete checkout, OrderId not created',
        '/api/purchase/orders',
        'POST',
        error,
        reqBody
      );
    }
  };

  return <Layout />;
};

export { ProcessOrder };
