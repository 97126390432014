import { createContext, useState } from "react";

import {
  formatObjectToPatternProducts,
  formatObjectToPatternVoucher,
  formatObjectToPatternShipping,
  formatObjectToPatternCart,
  formatObjectToPatternPaymentInfo,
  formatObjectToPatternAddressInfo,
  formatObjectToPatternInstallment
} from './CheckoutSessionHelper'

const CheckoutContext = createContext({});
const APP_VERSION = process.env.REACT_APP_VERSION;
const appStorageKey = "ClientArea@" + APP_VERSION;

const CheckoutProvider = ({ children }) => {
  const [cart, setCart] = useState({});
  const token = localStorage.getItem(appStorageKey)?.token;
  const user = localStorage.getItem(appStorageKey)?.user;

  const updateCart = (cart) => {
    const referenceId = cart.referenceId;
    const referenceTypeId = cart.referenceTypeId;
    const cartToken = cart.token || cart.cart_id;
    const { products, totalValue } = formatObjectToPatternProducts(cart);
    const { voucher } = formatObjectToPatternVoucher(cart);
    const { shipping } = formatObjectToPatternShipping(cart);
    const { paymentInfo } = formatObjectToPatternPaymentInfo(cart);
    const { addressInfo } = formatObjectToPatternAddressInfo(cart);
    const { installment } = formatObjectToPatternInstallment(cart);

    let finalValue = totalValue;
    if (shipping && !voucher?.freeShipping) finalValue += Number(shipping.v_valfee);
    if (voucher) {
      finalValue -= voucher.percent
        ? (totalValue / 100) * voucher.discountValue
        : voucher.discountValue;
    }

    const newCart = formatObjectToPatternCart({ referenceId, referenceTypeId, gateway_alias: cart?.gateway_alias, cartToken, products, paymentInfo, totalValue, voucher, shipping, addressInfo, installment, finalValue });
    setCart(newCart);

    return newCart;
  };

  return (
    <CheckoutContext.Provider
      value={{
        token,
        user,
        cart,
        updateCart
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export { CheckoutProvider, CheckoutContext };
