import { useState, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Layout from "./Layout";
import { CheckoutContext } from "../../contexts/CheckoutSession/CheckoutSession";
import notify, {
  INTERNAL_ERROR,
  CARD_REMOVE_SUCCESS,
  CARD_ADD_TO_CHECKOUT_WARN,
  PAYMENT_METHOD_NOT_SELECTED,
} from "../../utils/notify";
import logResponseError from "../../utils/loggerMessages";
import { useFetch } from "../../hooks";

const MyCards = ({ isCheckout }) => {
  let { token } = useParams();
  const { state } = useLocation();
  const { cart, updateCart } = useContext(CheckoutContext);
  const [cardsList, setCardsList] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [cardToDelete, setCardToDelete] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [activeTab, setActiveTab] = useState("my-cards");
  const history = useHistory();
  const { fetchFromBackend } = useFetch();

  useEffect(() => {
    if (!cart?.token && isCheckout) {
      redirectToHome(history);
    }
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHome = (history) => {
    history.replace({
      pathname: "/",
      search: window.location.search,
    });
  };

  async function getCards() {
    setIsFetching(true);
    try {
      const res = await fetchFromBackend(
        `/api/jfy/customers/payment_settings`,
        "GET",
        {}
      );
      if (Number(res?.response_code) === 0) {
        setCardsList(res.content.credit_cards);
        if (cart?.token) {
          updateCart({ ...cart, gateway_alias: res.content.gateway_alias });
        }
        setIsFetching(false);
      }
    } catch (error) {
      setIsFetching(false);
      logResponseError(
        "list of Cards - getCards - MyCards",
        "/api/jfy/customers/payment_settings",
        "GET",
        error,
        {}
      );
    }
  }

  const deleteCard = async (id) => {
    try {
      await fetchFromBackend(
        `/api/jfy/customers/payment_settings/remove_card`,
        "PATCH",
        {
          gateway_card_id: id
        }
      );
      let newListOfCards = cardsList.filter((element) => element.card_id !== id);
      setCardsList(newListOfCards);
      notify(CARD_REMOVE_SUCCESS, "success");
    } catch (error) {
      notify(INTERNAL_ERROR, "error");
      logResponseError(
        "Failed Remove Cards",
        "/api/jfy/customers/payment_settings/remove_card",
        "PATCH",
        error,
        {
          gateway_card_id: id
        }
      );
    }
  };

  const handleSetPaymentMethodToCheckout = () => {
    if (selectedCard) {
      if (cardsList.length === 0 && selectedCard?.toLowerCase() !== "pix") {
        notify(CARD_ADD_TO_CHECKOUT_WARN, "warn");
      } else {
        const cardInfo = cardsList.find(
          (element) => element.card_id === selectedCard
        );
        const paymentInfo = {
          cardInfo: {
            card: cardInfo?.card || 'Cartão de Crédito',
            card_flag: cardInfo?.brand,
            card_owner: cardInfo?.holder_name,
            expire_date: cardInfo?.exp_date,
            id_card: cardInfo?.card_id,
            last4_number_card: cardInfo?.last_digits,
            customer_id: cardInfo?.customer_id,
          },
          payment_method: cardInfo?.brand ? 'credit-card' : "pix",
        };
        updateCart({ ...cart, paymentInfo });

        //redirect to checkout on edit button has cliked
        state?.origin === "checkout"
          ? history.goBack()
          : history.push({
            pathname: "/checkout/" + token + "/summary-purchase",
            search: window.location.search,
          });
      }
    } else {
      notify(PAYMENT_METHOD_NOT_SELECTED, "warn");
    }
  };

  const handleSwichActiveTab = (tab) => {
    setActiveTab(tab);
    //reset selected card
    setSelectedCard();
  };

  return (
    <Layout
      goBack={() => history.goBack()}
      isCheckout={isCheckout}
      isFetching={isFetching}
      myCards={cardsList}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      addNewCard={() => {
        history.push(
          isCheckout ? "/checkout/" + token + "/payment/new" : "/cards/new"
        );
      }}
      openConfirm={(value) => {
        setCardToDelete(value);
        setOpenConfirm(true);
      }}
      isOpen={openConfirm}
      confirmAction={() => {
        deleteCard(cardToDelete);
        setCardToDelete(null);
        setOpenConfirm(false);
      }}
      activeTab={activeTab}
      handleSwichActiveTab={handleSwichActiveTab}
      cancelAction={() => {
        setOpenConfirm(false);
        setCardToDelete(null);
      }}
      handleSetPaymentMethodToCheckout={handleSetPaymentMethodToCheckout}
    />
  );
};
export default MyCards;
