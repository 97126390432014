import styled from "styled-components";

import { ResponsiveControl } from "../../lib/jfy-ui";

export const Container = styled.div`
  display: grid;
  gap: 0;
  grid-template-columns: 0.5fr 2fr 1fr;
  margin-bottom: 0.5rem;
  cursor: pointer;

  ${(props) =>
    props.selected
      ? `border: 1px solid ${props.theme.color?.primary?.i800}`
      : ""};

  @media ${ResponsiveControl.tablet("min")} {
    width: 50%;
  }
  @media ${ResponsiveControl.tablet("max")} {
    width: 100%;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Marked = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.theme.color?.primary?.i800};
`;

export const Radio = styled.input`
  min-width: 19px;
  min-height: 19px;
  accent-color: ${(props) => props.theme.color?.terciary?.i400};
  transition: accent-color 0.75s ease;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.6rem 0.6rem;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: -1px;
`;

export const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.selected ? "50%" : "100%")};
  width: 100%;
`;
