import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import LoginPage from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import MyOrders from '../pages/MyOrders';
import MyFormulas from '../pages/MyFormulas';
import MyInfos from '../pages/MyInfos';
import MyAddress from '../pages/MyAddress';
import AddAddress from '../pages/AddAddress';
import MyCards from '../pages/MyCards';
import ResetPasswordPublic from '../pages/ResetPasswordPublic';
import ResetPasswordPrivate from '../pages/ResetPasswordPrivate';
import FormulaDetails from '../pages/FormulaDetails';
import OrdersDetails from '../pages/OrdersDetails';
import CardDetails from '../pages/CardDetails';
import Help from '../pages/Help';
import Signup from '../pages/SignUp';
import SendEmail from '../pages/SendEmail';
import Guide from '../pages/Guide';
import { Checkout } from '../pages/Checkout';
import { PreCheckout } from '../pages/PreCheckout';
import { ProcessOrder } from '../pages/ProcessOrder';
import { AwaitPayment } from '../pages/AwaitPayment';
import { Feedback } from '../pages/Feedback';

import CustomRoute from './CustomRoute';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <CustomRoute exact path="/" component={Home} isPrivate />
      <CustomRoute exact path="/signup" component={Signup} />
      <CustomRoute exact path="/login" component={LoginPage} />
      <CustomRoute exact path="/forgot-password" component={ForgotPassword} />
      <CustomRoute exact path="/reset-password-confirm" component={ResetPasswordPublic} />
      <CustomRoute exact path="/orders" component={MyOrders} isPrivate />
      <CustomRoute exact path="/orders/details/:orderId" component={OrdersDetails} isPrivate />
      <CustomRoute exact path="/pre-checkout/:id/:typeId" component={PreCheckout} isPrivate />
      <CustomRoute exact path="/checkout/:token/address" render={<MyAddress isCheckout />} isPrivate />
      <CustomRoute exact path="/checkout/:token/address/new" render={<AddAddress isCheckout />} isPrivate />
      <CustomRoute exact path="/checkout/:token/payment" render={<MyCards isCheckout />} isPrivate />
      <CustomRoute exact path="/checkout/:token/payment/new" render={<CardDetails isCheckout />} isPrivate />
      <CustomRoute exact path="/checkout/:token/summary-purchase" render={<Checkout />} isPrivate />
      <CustomRoute exact path="/checkout/:token/order/processing" render={<ProcessOrder />} isPrivate />
      <CustomRoute exact path="/checkout/order/:orderId/awaiting-payment" render={<AwaitPayment />} isPrivate />
      <CustomRoute exact path="/checkout/order/:orderId/feedback/:status" render={<Feedback />} isPrivate />
      <CustomRoute exact path="/formulas" component={MyFormulas} isPrivate />
      <CustomRoute exact path="/formulas/details/:formulaId" component={FormulaDetails} isPrivate />
      <CustomRoute exact path="/personal-data" component={MyInfos} isPrivate />
      <CustomRoute exact path="/address" component={MyAddress} isPrivate />
      <CustomRoute exact path="/address/add" component={AddAddress} isPrivate />
      <CustomRoute exact path="/address/edit/:addressSlug" render={<AddAddress edit />} isPrivate />
      <CustomRoute exact path="/help/send-email" component={SendEmail} isPrivate />
      <CustomRoute
        exact
        path="/address/order-change/:orderId"
        render={<MyAddress orderChange />}
        isPrivate
      />
      <CustomRoute exact path="/cards" component={MyCards} isPrivate />
      <CustomRoute exact path="/cards/new" component={CardDetails} isPrivate />
      <CustomRoute exact path="/reset-password" component={ResetPasswordPrivate} isPrivate />
      <CustomRoute exact path="/help" component={Help} isPrivate />
      <CustomRoute exact path="/logout" />
      <CustomRoute exact path="/manual" component={Guide} />
      <CustomRoute exact path="*" component={LoginPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
