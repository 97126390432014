import { Container, Decrease, Increase } from './styles'
import * as React from 'react'
import { IQuantifyerProps } from './Quantifyer.i'

export const Quantifyer: React.FC<IQuantifyerProps> = ({ numberToRender, sizeControl, decrementFunction, incrementFunction, ...props }) => {
  return (
    <Container {...props}>
      <Decrease onClick={decrementFunction} sizeControl={sizeControl}>-</Decrease>
      {numberToRender}
      <Increase onClick={incrementFunction} sizeControl={sizeControl}>+</Increase>
    </Container>
  )
}
