import { useState, useEffect } from 'react';
import Layout from './Layout';
import { useHistory } from 'react-router-dom';

const BurguerMenu = ({ onClose, onOpen, isOpen }) => {
  const history = useHistory();
  const [selectedRoute, setSelectedRoute] = useState('');

  const redirectTo = (path) => {
    if (path !== '/') {
      history.replace({ pathname: '/' + path, search: window.location.search });
    } else {
      history.push({ pathname: '/', search: window.location.search });
    }

  };

  useEffect(() => {
    let location = window.location.href.split('/').pop();
    setSelectedRoute(location);
  }, []);
  return (
    <Layout
      redirectTo={redirectTo}
      selectedRoute={selectedRoute}
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
    />
  );
};

export default BurguerMenu;
