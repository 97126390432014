import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { deviceIsMobile } from "../../../utils/deviceIsMobile";
import ClientAreaContainer from '../../../components/ClientAreaContainer';
import { FormulaCard } from '../../../components/Cards';
import LoadingDots from '../../../components/LoadingDots';

import tabsStyle from '../../../styles/Tabs.module.css';
import styles from './MyFormulas.module.css';
import { Button, NewFormulaContainer } from './styles'

const Layout = ({
  activeTab,
  onClickTab,
  onClickFavorite,
  onClickNewFormula,
  recentForms,
  favoriteForms,
  allForms,
  redirectToDetails,
  redirectToRebuy,
  loading,
}) => {
  const renderForms = (forms, emptyMessage = '', loading) => {
    if (forms?.length > 0) {
      return (
        <div className={styles.forms}>
          {forms.map((form, index) => {
            return (
              <FormulaCard
                key={index}
                formId={form.id}
                formulaName={form.name}
                formulaDate={form.date}
                formulaDesiredEffects={form.desiredEffects}
                favorite={form.favorite}
                onChange={(formId, favorite) => onClickFavorite(formId, favorite)}
                className={styles.card}
                onClickDetails={() => {
                  redirectToDetails(form.id);
                }}
                onClickRebuy={() => {
                  redirectToRebuy(form.id);
                }}
              />
            );
          })}
        </div>
      );
    } else {
      if (loading) {
        return <LoadingDots className={styles.loading} />;
      } else {
        return (
          <div className={styles.message}>
            <h2>{emptyMessage}</h2>
          </div>
        );
      }
    }
  };

  const isMobile = deviceIsMobile()

  return (
    <ClientAreaContainer
      headerTitle="Minhas fórmulas"
      headerMenu
      className={styles.container}
      headerClassName={styles.header}
      blocksClassName={styles.content}
    >
      <Tabs
        defaultIndex={activeTab}
        onSelect={(index) => onClickTab(index)}
        className={tabsStyle.tabs + ' ' + styles.tabs}
      >

        <div className={styles.containerTabList}>
          <TabList className={tabsStyle.list}>
            <Tab className={tabsStyle.tab + (activeTab === 0 ? ` ${tabsStyle.active}` : '')}>
              Mais recentes
            </Tab>
            <Tab className={tabsStyle.tab + (activeTab === 1 ? ` ${tabsStyle.active}` : '')}>
              Favoritas
            </Tab>
            <Tab className={tabsStyle.tab + (activeTab === 2 ? ` ${tabsStyle.active}` : '')}>
              Todas as fórmulas
            </Tab>
          </TabList>
          {!isMobile && (<NewFormulaContainer>
            <Button variant="solid" onClick={onClickNewFormula}>
              Criar nova fórmula
            </Button>
          </NewFormulaContainer>)}
        </div>
        {isMobile && <NewFormulaContainer>
          <Button variant="solid" onClick={onClickNewFormula}>
            Criar nova fórmula
          </Button>
        </NewFormulaContainer>}
        <TabPanel>{renderForms(recentForms, 'Nenhuma fórmula encontrada!', loading)}</TabPanel>
        <TabPanel>
          {renderForms(favoriteForms, 'Nenhuma fórmula favorita encontrada!', loading)}
        </TabPanel>
        <TabPanel>{renderForms(allForms, 'Nenhuma fórmula encontrada!', loading)}</TabPanel>
      </Tabs>
    </ClientAreaContainer>
  );
};

export default Layout;
