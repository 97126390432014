import { ReactComponent as GrowthIcon } from '../../../assets/icons/effects/crescimento.svg';
import { ReactComponent as GlowIcon } from '../../../assets/icons/effects/brilho.svg';
import { ReactComponent as HidratationIcon } from '../../../assets/icons/effects/hidratacao.svg';
import { ReactComponent as OilinessIcon } from '../../../assets/icons/effects/controle_oleosidade.svg';
import { ReactComponent as RepairIcon } from '../../../assets/icons/effects/reparacao.svg';
import { ReactComponent as ColorShieldIcon } from '../../../assets/icons/effects/blindagem.svg';
import { ReactComponent as StrandFortificationIcon } from '../../../assets/icons/effects/fortificacao.svg';
import { ReactComponent as AntifrizzIcon } from '../../../assets/icons/effects/antifrizz.svg';
import { ReactComponent as FallPreventionIcon } from '../../../assets/icons/effects/prevencao_queda.svg';
import { ReactComponent as VolumeIcon } from '../../../assets/icons/effects/volume_controlado.svg';


const Layout = ({ icon }) => {
  switch (icon) {
    case 'crescimento':
      return <GrowthIcon />;
    case 'brilho':
      return <GlowIcon />;
    case 'hidratacao':
      return <HidratationIcon />;
    case 'controle_oleosidade':
      return <OilinessIcon />;
    case 'reparacao':
      return <RepairIcon />;
    case 'blindagem':
      return <ColorShieldIcon />;
    case 'fortificacao':
      return <StrandFortificationIcon />;
    case 'antifrizz':
      return <AntifrizzIcon />;
    case 'prevencao_queda':
      return <FallPreventionIcon />;
    case 'volume_controlado':
      return <VolumeIcon />;
    default:
      return <></>;
  }
};
export default Layout;
