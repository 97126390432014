export const InProductionIcon = ({ width = "143", height = "135", viewBox = "0 0 143.78 135.62", stroke = "", color = "#1e1e1c", ...props }) => {
    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
            <g>
                <path d="M109.15,120.98l-26.65-49.42v-22.78c.12-.67,.58-.85,2.08-1.13,1.02-.19,2.18-.4,3.02-1.21,.1-.1,.18-.21,.23-.34,.84-2.02,.85-5.82,.85-5.98,0-.55-.44-1-.99-1h-31.63c-.55,0-.99,.45-.99,1,0,.16,0,3.96,.85,5.98,.05,.13,.13,.24,.23,.34,.85,.81,2,1.03,3.03,1.21,1.5,.27,1.95,.45,2.08,1.13v22.78l-26.6,49.38c-.11,.19-2.76,4.68-.62,8.52,1.76,3.17,6.17,4.78,13.1,4.78h49.52c6.93,0,11.34-1.61,13.1-4.78,2.13-3.84-.51-8.33-.6-8.48Zm-1.12,7.5c-1.37,2.46-5.3,3.76-11.38,3.76H47.13c-6.07,0-10-1.3-11.37-3.75-.81-1.45-.63-3.13-.26-4.43,.03,0,.05,.02,.08,.02h13.25c.55,0,.99-.45,.99-1s-.44-1-.99-1h-12.53c.04-.08,.07-.13,.08-.14l5.95-11.04h13.06c.55,0,.99-.45,.99-1s-.44-1-.99-1h-11.98l5.65-10.49c.14,.08,.3,.14,.47,.14h13.25c.55,0,.99-.45,.99-1s-.44-1-.99-1h-12.72l6.18-11.48h13.19c.55,0,.99-.45,.99-1s-.44-1-.99-1h-12.11l5.8-10.77c.08-.15,.12-.31,.12-.48v-23.12s0-.1-.01-.15c-.33-2.25-2.27-2.61-3.69-2.86-.73-.13-1.43-.26-1.87-.57-.37-1.07-.53-2.79-.59-3.99h29.61c-.06,1.2-.21,2.93-.59,3.99-.44,.31-1.13,.44-1.86,.57-1.42,.26-3.36,.61-3.69,2.86,0,.05-.01,.1-.01,.15v23.12c0,.17,.04,.33,.12,.48l26.79,49.69s2.16,3.67,.59,6.5Z" fill={color} strokeWidth="3px" stroke={stroke} />
                <path d="M67.33,8.81c-2.12,0-3.85-1.75-3.85-3.9s1.73-3.9,3.85-3.9,3.85,1.75,3.85,3.9-1.73,3.9-3.85,3.9Zm0-5.81c-1.04,0-1.88,.85-1.88,1.9s.84,1.9,1.88,1.9,1.88-.85,1.88-1.9-.84-1.9-1.88-1.9Z" fill={color} strokeWidth="3px" stroke={stroke} />
                <path d="M79.05,19.21c.33-.77,.51-1.62,.51-2.52,0-3.54-2.84-6.42-6.33-6.42s-6.04,2.59-6.31,5.88c-3.65,1.64-6.06,5.35-6.06,9.43,0,5.69,4.57,10.33,10.2,10.33s10.2-4.63,10.2-10.33c0-2.32-.78-4.55-2.2-6.37Zm-5.83-6.93c2.4,0,4.36,1.98,4.36,4.42,0,.86-.26,1.67-.68,2.35-.04,.07-.08,.13-.13,.2-.14,.2-.29,.39-.46,.56-.03,.03-.05,.05-.08,.07-.15,.15-.32,.28-.49,.41-.06,.05-.13,.09-.19,.13-.18,.12-.37,.22-.57,.31-.04,.02-.07,.04-.11,.05-.21,.09-.44,.16-.67,.21-.07,.02-.14,.03-.21,.04-.25,.05-.51,.08-.77,.08-1.5,0-2.83-.77-3.61-1.95,0,0,0,0,0-.01-.15-.22-.27-.46-.38-.71-.05-.13-.09-.26-.13-.39-.04-.13-.09-.26-.12-.4-.04-.2-.06-.41-.08-.63,0-.11-.03-.22-.03-.33,0-2.44,1.96-4.42,4.36-4.42Zm-2.18,21.63c-4.53,0-8.22-3.74-8.22-8.33,0-3.05,1.67-5.84,4.26-7.29,0,.03,.02,.05,.03,.08,.08,.29,.17,.58,.29,.85,.04,.09,.08,.18,.13,.26,.08,.17,.17,.33,.26,.49,.11,.19,.23,.37,.36,.54,.07,.1,.15,.2,.23,.3,.11,.13,.23,.26,.35,.39,.05,.05,.1,.09,.14,.14,.22,.21,.45,.41,.7,.58,.09,.06,.18,.13,.28,.19,.18,.12,.37,.22,.57,.32,.16,.08,.32,.15,.49,.22,.11,.04,.22,.08,.33,.12,.26,.09,.53,.16,.8,.21,.07,.01,.14,.02,.22,.03,.31,.05,.63,.08,.96,.08,.22,0,.45-.01,.66-.04,.03,0,.06,0,.09-.01,.19-.02,.38-.05,.57-.09,.03,0,.05-.01,.08-.02,.19-.04,.37-.09,.55-.15,.03-.01,.06-.02,.09-.03,.17-.06,.34-.12,.51-.2,.04-.02,.08-.04,.12-.06,.15-.07,.3-.15,.44-.23,.05-.03,.1-.06,.15-.1,.13-.08,.25-.16,.38-.25,.06-.04,.12-.09,.18-.13,.11-.08,.21-.17,.32-.26,.06-.06,.12-.11,.18-.17,.09-.09,.18-.18,.27-.27,.03-.03,.06-.06,.09-.09,.89,1.35,1.36,2.94,1.36,4.58,0,4.59-3.69,8.33-8.22,8.33Z" fill={color} strokeWidth="3px" stroke={stroke} />
            </g>
            <g>
                <polygon points="23.29 134.12 1.5 134.12 1.5 1.83 56.87 1.83 56.87 3.58 3.23 3.58 3.23 132.37 23.29 132.37 23.29 134.12" fill={color} stroke={stroke} strokeWidth="6px" />
                <polygon points="142.28 134.12 119.51 134.12 119.51 132.37 140.55 132.37 140.55 3.58 86.91 3.58 86.91 1.83 142.28 1.83 142.28 134.12" fill={color} stroke={stroke} strokeWidth="6px" />
            </g>
        </svg>
    )
}
