import Layout from './Layout';

export const OrderTimeline = ({ status, className, iconsClassName }) => {
  const statusStrings = ['', '', '', 'awaiting', 'paid', '', 'shipping', 'delivered', '', '', '', 'production'];

  return (
    <Layout
      orderStatus={statusStrings[status]}
      className={className}
      iconsClassName={iconsClassName}
    />
  );
};

export default OrderTimeline;
