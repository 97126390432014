import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFetch, useComponentWillUnmount } from '../../hooks';
import logger from '../../utils/logger';
import dayjs from 'dayjs';

import Layout from './Layout';
import notify, { OLD_FORM } from '../../utils/notify';

const effectIconNames = {
  'antifrizz': 'antifrizz',
  'blindagemdacor': 'blindagem',
  'brilhointenso': 'brilho',
  'hidrataçãocontínua': 'hidratacao',
  'controledeoleosidade': 'controle_oleosidade',
  'reparaçãoimediata': 'reparacao',
  'crescimento': 'crescimento',
  'fortificaçãodosfios': 'fortificacao',
  'fortalecimentodosfios': 'fortificacao',
  'prevençãodequeda/quebra': 'prevencao_queda',
  'volumecontrolado': 'volume_controlado',
};

const FormulaDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formulaName, setFormulaName] = useState('');
  const [formulaDate, setFormulaDate] = useState('');
  const [oldForm, setOldForm] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [favoriteChanged, setFavoriteChanged] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [effects, setEffects] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [composition, setComposition] = useState('');
  const [loading, setLoading] = useState(true);
  const { formulaId } = useParams();
  const history = useHistory();
  const { fetchFromBackend } = useFetch();

  useComponentWillUnmount(() => {
    if (favoriteChanged) {
      if (favorite) {
        updateFavoriteForms([formulaId], []);
      } else {
        updateFavoriteForms([], [formulaId]);
      }
    }
  });

  const addToCart = () => {
    history.push({ pathname: '/pre-checkout/' + formulaId + '/formula', search: window.location.search });
  };

  const updateFavoriteForms = async (addToFavorites, removeFromFavorites) => {
    const body = {
      add_to_favorite: addToFavorites,
      remove_from_favorite: removeFromFavorites,
    };
    await fetchFromBackend('/api/customers/formulas/set_favorite', 'POST', body).catch((error) => {
      logger.error('Failed to update favorite forms\n', error, '\nBody of the Request:', body);
    });
  };

  const createNewForm = () => {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set('callback', `${window.location.origin}/formulas/details/{{formId}}`)
    window.location.replace(process.env.REACT_APP_FORM_URL + '?' + urlParams.toString())
  }

  const validateIsOldForm = (resForm) => {
    return resForm.content.some(form => form.oldForm)
  }

  const fetchFormula = (formId) => {
    const url = `/api/customers/formulas/${formId}`;
    const method = 'GET';
    fetchFromBackend(url, method, {})
      .then((response) => {
        if (response.response_code === 0) {
          const form = response.content[0];
          const isOldForm = validateIsOldForm(response);
          setOldForm(isOldForm);
          setFormulaName(form?.formulaName || response?.content.formulaName);
          setFormulaDate(dayjs(form?.date?.['$date'] || response?.date?.['$date']).format('DD/MM/YYYY'));
          setFavorite(form?.is_favorite || false);

          if (isOldForm) {
            notify(OLD_FORM, 'warn')
          }

          setIngredients(!isOldForm ?
            form.ingredients.map((item) => {
              return { id: item.id, ingredient: item.title };
            }) : []
          );

          let effectsAnswer;
          if (!isOldForm) {
            effectsAnswer = form.questions
              .find((question) => question.questionId === 13)
              .questionAnswer.split('|');
          } else {
            effectsAnswer = response.content.questions
              .find((question) => question.questionId === 13)
              .questionAnswer.split('|');
          }
          setEffects(
            effectsAnswer.map((answer) => {
              return { description: answer.trim(), iconName: effectIconNames[answer.trim().replace(/ /g, '').toLowerCase()] };
            })
          );

          setAnswers(
            !isOldForm ?
              form.questions.map((item) => {
                return {
                  question: item.shortDescription,
                  answers: [...item.questionAnswer.split('|')],
                };
              }) : []
          );

          let formulaComposition = '';
          !isOldForm && form.ingredients.forEach((item) => {
            formulaComposition += `${item.title}: ${item.text} `;
          });
          setComposition(formulaComposition);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.name === 'NetworkError') {
          logger.error('Failed to fetch formula\n', error);
        } else {
          logger.error('Failed to fetch formula\n', 'Request:', `*${method}* \`${url}\`\n`, error);
        }
      });
  };

  useEffect(() => {
    fetchFormula(formulaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      oldForm={oldForm}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      formulaName={formulaName}
      formulaDate={formulaDate}
      effects={effects}
      ingredients={ingredients}
      composition={composition}
      answers={answers}
      favorite={favorite}
      onCreateNewForm={
        createNewForm
      }
      onClickFavorite={() => {
        setFavorite(!favorite);
        setFavoriteChanged(true);
      }}
      goBack={() => {
        history.goBack();
      }}
      onAddToCart={addToCart}
      loading={loading}
    />
  );
};

export default FormulaDetails;
