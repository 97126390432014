import styled from "styled-components";

import { Block } from "../../../components/ClientAreaContainer";

export const BlockDisplay = styled(Block)`
  ${(props) =>
    props.backgroudNone
      ? `background-color: transparent;`
      : `background-color: ${props.theme.color.base.i100};`}
  ${(props) => props.margin && `margin-bottom: ${props.margin}`}
`;
