import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import OrderTimeline from '../../../components/OrderTimeline';
import Button from '../../../components/Button';
import ProductDisplay from '../../../components/ProductDisplay';
import LoadingDots from '../../../components/LoadingDots';
import formatPrice from '../../../utils/formatPrice';
import styles from './OrdersDetails.module.css';

const paymentMethods = {
  'pix': 'Pix',
  'credit_card': 'Cartão de crédito',
  'hash': 'Código Promocional'
};

const Layout = ({
  finishedFormFetch,
  finishedFetch,
  orderNumber,
  date,
  formulas,
  formulaDetails,
  genericProducts,
  addressInfos,
  paymentInfos,
  orderInfos,
  goBack,
  onClickFormulaDetails,
  redirectToReorder,
  handleAddressChange,
  formatInstallments,
  noFormulaOrder,
}) => {
  return (
    <ClientAreaContainer
      onGoBack={goBack}
      headerTitle="Detalhes do Pedido"
      className={styles.container}
      headerClassName={styles.header}
    >
      {finishedFetch ? (
        <>
          <Block
            title={'N°' + orderInfos?.display_number}
            description={date}
            className={styles.block}
          >
            {!noFormulaOrder ? (
              <div className={styles.attribute}>
                <span className={styles.name}>
                  {formulas.length > 1 ? 'Fórmulas: ' : 'Fórmula: '}
                </span>
                <span className={styles.value}>
                  {orderInfos?.formulas.map((formula) => formula.formula_name).join(', ')}
                </span>
              </div>
            ) : null}
            <div className={styles.attribute}>
              <span className={styles.name}>Status: </span>
              <span className={styles.value}>{orderInfos?.status}</span>
            </div>
            {orderInfos?.delivery_date !== '' && (
              <div className={styles.attribute}>
                <span className={styles.name}>Previsão de entrega até: </span>
                <span className={styles.value}>{orderInfos?.delivery_date}</span>
              </div>
            )}
            {orderInfos?.status_id !== 8 && (
              <OrderTimeline
                status={orderInfos?.status_id}
                className={styles.timeline}
                iconsClassName={styles.timelineIcons}
              />
            )}
            {orderInfos?.track_url && (
              <div className={styles.buttons}>
                <Button
                  primary
                  text="Rastrear entrega"
                  className={styles.buttonStyle}
                  onClick={() => {
                    window.location.replace(orderInfos?.track_url);
                  }}
                />
              </div>
            )}
          </Block>
          <div className={styles.items}>
            <h1 className={styles.desktop}>Seus produtos</h1>
            <Block className={styles.block}>
              <h2 className={styles.mobile}>Seus produtos</h2>
              {formulas.map((formula, formIndex) => {
                return (
                  <div key={formIndex}>
                    {!noFormulaOrder ? (
                      <h2 className={styles.formulaName}>Fórmula: {formula.formula_name}</h2>
                    ) : null}

                    <div className={styles.products}>
                      {formula.products.map((element, productIndex) => {
                        return (
                          <ProductDisplay
                            outlined
                            productName={element.name}
                            title={element.display_name}
                            size={element.size}
                            price={formatPrice(element.value)}
                            className={styles.product}
                            value={element.value}
                            key={productIndex}
                            imageUrl={element.image}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {genericProducts && (
                <div>
                  <h2 className={styles.formulaName}>Outros</h2>
                  <div className={styles.products}>
                    {genericProducts?.map((product, index) => {
                      return (
                        <ProductDisplay
                          outlined
                          productName={product.name}
                          title={product.display_name}
                          className={styles.product}
                          imageUrl={product.image}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </Block>
          </div>
          <Block className={styles.block}>
            <div className={styles.cardRoot}>
              <h2 className={styles.title}>Endereço para Entrega</h2>
              <div>
                <div className={styles.attribute}>
                  <span className={styles.name}>CEP: </span>
                  <span className={styles.value}>{addressInfos.zipcode}</span>
                </div>
                <div className={styles.attribute}>
                  <span className={styles.name}>Cidade: </span>
                  <span className={styles.value}>
                    {addressInfos.city + '/' + addressInfos.state}
                  </span>
                </div>
                <div className={styles.attribute}>
                  <span className={styles.name}>Rua: </span>
                  <span className={styles.value}>
                    {addressInfos.street +
                      ', ' +
                      addressInfos.number +
                      ' - ' +
                      addressInfos.neighborhood}
                  </span>
                </div>
              </div>
              {orderInfos?.status_id === 4 && (
                <div className={styles.buttons}>
                  <Button
                    primary
                    text="Alterar endereço"
                    className={styles.button}
                    onClick={handleAddressChange}
                  />
                </div>
              )}
            </div>
          </Block>
          <Block className={styles.block}>
            <h2 className={styles.title}>Pagamento</h2>
            <div>
              {paymentInfos.value ? (
                <div className={styles.attribute}>
                  <span className={styles.name}>Valor total: </span>
                  <span className={styles.value}>
                    {formatPrice(parseFloat(paymentInfos.value) * 100)}
                  </span>
                </div>
              ) : null}
              <div className={styles.attribute}>
                <span className={styles.name}>Método de pagamento: </span>
                <span className={styles.value}>
                  {paymentMethods[paymentInfos?.method]}
                </span>
              </div>
              {paymentInfos.installments && paymentInfos.method?.toLocaleUpperCase() !== 'PIX' && (
                <div className={styles.attribute}>
                  <span className={styles.name}>Parcelas: </span>
                  <span className={styles.value}>
                    {formatInstallments(paymentInfos.installments)}
                  </span>
                </div>
              )}
            </div>
          </Block>
          {!noFormulaOrder ? (
            <div className={styles.formulas}>
              <h1 className={styles.desktop}>Suas fórmulas</h1>
              <Block className={styles.block}>
                <h2 className={styles.mobile}>Suas fórmulas</h2>
                {formulaDetails.length > 0
                  ? formulaDetails.map((formula, index) => {
                    return (
                      <div className={styles.formula} key={index}>
                        <div>
                          <h2 className={styles.title}>Fórmula: {formula.formula_name}</h2>
                          <div>
                            <div className={styles.attribute}>
                              <span className={styles.name}>Efeitos: </span>
                              {finishedFormFetch ? (
                                <span className={styles.value}>{formula.effects}</span>
                              ) : (
                                <span className={styles.value}> Carregando...</span>
                              )}
                            </div>
                            <div className={styles.attribute}>
                              <span className={styles.name}>Ingredientes: </span>
                              {finishedFormFetch ? (
                                <span className={styles.value}>{formula.ingredients}</span>
                              ) : (
                                <span className={styles.value}> Carregando...</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <Button
                          tertiary
                          text="Ver mais detalhes"
                          onClick={() => onClickFormulaDetails(formula.id)}
                        />
                      </div>
                    );
                  })
                  : null}
              </Block>
            </div>
          ) : null}

          {orderInfos?.rebuy_allowed && (
            <Block className={styles.block + ' ' + styles.buttons}>
              <Button primary text="Repetir minha compra!" onClick={redirectToReorder} />
            </Block>
          )}
        </>
      ) : (
        <LoadingDots />
      )}
    </ClientAreaContainer>
  );
};
export default Layout;
