import { Button } from '../../../lib/jfy-ui';
import { useHistory } from 'react-router-dom';

import { OrderInfo } from './OrderInfo';
import { CartInfo } from './CartInfo';
import { StatusPayment } from './StatusPayment';
import { InfoText } from './InfoText';

import {
  Container,
  JFYLogo,
  ButtonContainer,
  ClientAreaContainer,
} from './styles';

const Layout = ({
  orderId,
  status,
  purchaseAmount,
  shippingCheckout,
  productsCheckout,
  addressCheckout,
}) => {
  const history = useHistory();

  return (
    <ClientAreaContainer>
      <JFYLogo height={40} />
      {status === 'denied' || status === 'expired' ? (
        <Container>
          <StatusPayment status={status} />
          <InfoText status={status} />
          <ButtonContainer>
            <Button
              variant='solid'
              onClick={() =>
                history.push({
                  pathname: '/',
                  search: window.location.search,
                })
              }
            >
              Retornar para área do cliente
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <>
          <Container>
            <StatusPayment status={status} />
            <OrderInfo
              orderId={orderId}
              status={status}
              purchaseAmount={purchaseAmount}
            />
          </Container>
          <Container>
            <CartInfo
              orderId={orderId}
              status={status}
              purchaseAmount={purchaseAmount}
              shippingCheckout={shippingCheckout}
              productsCheckout={productsCheckout}
              addressCheckout={addressCheckout}
            />
            <ButtonContainer>
              <Button
                variant='solid'
                onClick={() =>
                  history.push({
                    pathname: '/',
                    search: window.location.search,
                  })
                }
              >
                Retornar para área do cliente
              </Button>
            </ButtonContainer>
          </Container>
        </>
      )}
    </ClientAreaContainer>
  );
};

export { Layout };
