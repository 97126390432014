export const ShippingIcon = ({ color = '#000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.245"
        d="M7.505 9.099h15.494c.25 0 .458.225.458.502v16.315c0 .275-.205.502-.458.502h-5.71M7.05 26.416h2.3M24.225 12.706h5.462c.25 0 .553.2.668.445l3.084 6.458c.118.244.213.67.213.944v5.36c0 .275-.206.503-.458.503h-1.626"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.245"
        d="M27.296 15.834v3.717c0 .275.206.502.458.502h4.86M4.764 13.066h7.62M1.42 16.193h7.238M3.558 19.26h7.621M1.037 22.506h7.621M13.263 30.144c1.664 0 3.013-1.48 3.013-3.306s-1.349-3.306-3.013-3.306c-1.665 0-3.014 1.48-3.014 3.306s1.35 3.306 3.014 3.306zM27.789 30.144c1.664 0 3.014-1.48 3.014-3.306s-1.35-3.306-3.014-3.306c-1.665 0-3.014 1.48-3.014 3.306s1.35 3.306 3.014 3.306z"
      ></path>
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.743"
        d="M1.357 13.066V1h35.229v35.67h-8.62"
      ></path>
      <path stroke={color} strokeMiterlimit="10" strokeWidth="1.743" d="M28.94 36.67H1.357V24.543"></path>
    </svg>
  );
};

export default ShippingIcon;
