import Layout from './Layout';
import { cpfCnpjSchema, emailSchema, nameSchema, birthdateSchema } from '../../utils/validator';
import { useForm, useFetch } from '../../hooks';
import { cellphoneMask, cpfCnpjMask, fullDateMask } from '../../utils/formater';
import { useEffect, useState, useContext } from 'react';
import notify, { MISSING_LAST_NAME, USER_DATA_UPDATED, WRONG_PASSWORD } from '../../utils/notify';
import logger from '../../utils/logger';
import formatAndValidateName from '../../utils/validator/name';

import DataContext from '../../contexts/Data';

const APP_DATA_KEY = 'userData';

const MyInfos = () => {
  const [finishedFetch, setFinishedFetch] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { saveAppLevelData, appData } = useContext(DataContext);
  const { fetchFromBackend } = useFetch();

  const {
    fields,
    setFieldValue,
    handleChange,
    validateField,
    handleFinish,
    anyChanged,
    setInitialValues,
    getFieldsValues,
  } = useForm({
    name: {
      required: true,
      schema: nameSchema,
    },
    email: {
      required: true,
      schema: emailSchema,
    },
    cpfCnpj: {
      required: true,
      mask: cpfCnpjMask,
      schema: cpfCnpjSchema,
    },
    phone: {
      mask: cellphoneMask,
    },
    birthdate: {
      required: true,
      schema: birthdateSchema,
      mask: fullDateMask,
    },
  });

  const {
    fields: fieldsMod,
    setFieldValue: setFieldValueMod,
    validateField: validateFieldMod,
    handleChange: handleChangeMod,
    handleFinish: handleFinishMod,
  } = useForm({
    password: {
      required: true,
    },
  });

  useEffect(() => {
    appData[APP_DATA_KEY] ? loadLocalData() : fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load from data context
  const loadLocalData = () => {
    Object.keys(appData[APP_DATA_KEY]).forEach((fieldName) =>
      setFieldValue(fieldName, appData[APP_DATA_KEY][fieldName])
    );
    setInitialValues();
  };

  // fetch users data in the first page load
  const fetchUserData = async () => {
    try {
      const res = await fetchFromBackend('/api/customers/profile/', 'GET', true);
      let showDate = ''
      if (res.content.birthdate) {
        try {
          const bdate_raw = new Date(res.content.birthdate);
          const bdate = bdate_raw.toISOString().split('T')[0].split('-');
          showDate = `${bdate[2]}/${bdate[1]}/${bdate[0]}`
        } catch (error) {
          logger.error('Failed to split birthdate on fetchUserData function\n', error);
        }
      }

      const data = {
        name: `${res.content.first_name} ${res.content.last_name}`, // TO-DO: separate first and last
        email: res.content.email,
        cpfCnpj: res.content.cpf ? res.content.cpf : res.content.cnpj,
        phone: res.content.phone ? res.content.phone : '',
        birthdate: showDate,
      };
      Object.keys(fields).forEach((fieldName) => setFieldValue(fieldName, data[fieldName]));
      setInitialValues();
      setFinishedFetch(true);
      saveAppLevelData(APP_DATA_KEY, data);
    } catch (error) {
      logger.error('Failed to load user data\n', error, '\n', error.responseJson || null);
    }
  };

  const requestDataUpdate = async (fieldsToIgnore = []) => {
    // format name (at this point it was already validated)
    const formattedName = formatAndValidateName(fields.name.value);
    let bdate = fields.birthdate.value?.split('/')
    if (bdate) {
      bdate = `${bdate[2]}-${bdate[1]}-${bdate[0]}`
    }

    // Defines default body with parsed data
    const default_body = {
      first_name: formattedName[0],
      last_name: formattedName[1],
      email: fields.email.value,
      phone: fields.phone.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
      birthdate: bdate
    };

    // Filter fields
    const body = Object.keys(default_body)
      .filter((key) => !fieldsToIgnore.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: default_body[key],
        };
      }, {});

    await fetchFromBackend('/api/customers/profile/update', 'PUT', body, true);
  };

  const checkCredentials = async () => {
    const body = {
      username: fields.cpfCnpj.value.match(/\d/g).join(''),
      password: fieldsMod.password.value,
    };
    const res = await fetchFromBackend('/api/customers/login/', 'POST', body);
    if (!res.token) {
      return false;
    }
    return true;
  };

  const handleFirstSubmit = async () => {
    const { valid } = await handleFinish();
    if (valid) {
      // apply name validation
      const formattedName = formatAndValidateName(fields.name.value);
      if (!formattedName) {
        notify(MISSING_LAST_NAME, 'warn');
        return;
      }

      if (fields.email.value !== fields.email.initialValue) {
        setModalOpen(true);
      } else {
        try {
          await requestDataUpdate(['email']);
          setInitialValues();
          notify(USER_DATA_UPDATED, 'success');
          saveAppLevelData(APP_DATA_KEY, getFieldsValues());
        } catch (error) {
          logger.error(
            'Failed to update user data (without email)\n',
            error,
            '\n',
            error.responseJson || null
          );
        }
      }
    }
  };

  const handleModalSubmit = async () => {
    const { valid } = await handleFinishMod();
    if (valid) {
      try {
        if (await checkCredentials()) {
          await requestDataUpdate();
          setModalOpen(false);
          setInitialValues();
          setFieldValueMod('password', '');
          notify(USER_DATA_UPDATED, 'success');
          saveAppLevelData(APP_DATA_KEY, getFieldsValues());
        } else {
          notify(WRONG_PASSWORD, 'warn');
        }
      } catch (error) {
        logger.error(
          'Failed to update user data (with email)\n',
          error,
          '\n',
          error.responseJson || null
        );
      }
    }
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    setFieldValueMod('password', '');
  };

  return (
    <Layout
      handleChange={handleChange}
      validateField={validateField}
      fields={fields}
      setFieldValue={setFieldValue}
      handleChangeMod={handleChangeMod}
      validateFieldMod={validateFieldMod}
      fieldsMod={fieldsMod}
      finishedFetch={finishedFetch}
      handleFirstSubmit={handleFirstSubmit}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
      modalOpen={modalOpen}
      anyChanged={anyChanged}
    />
  );
};
export default MyInfos;
