import { IButtonProps } from "./Button.i"
import { SButton } from "./styles";
import * as React from "react";
import { LoadingDots } from '../LoadingDot'

export const Button: React.FC<IButtonProps> = ({ variant, children = '', diffTimeLoadingAnimation = 1.6, isLoading = false, ...props }) => {
  return (
    <SButton variant={variant} {...props}>
      {isLoading ? <LoadingDots diffTimeLoadingAnimation={diffTimeLoadingAnimation} variant={variant} /> : children}
    </SButton>
  )
}
