import { useEffect, useRef, useState } from 'react';


import { PensilIcon, TrashIcon } from './styles';

import styles from './Block.module.css';

export const Block = ({ className, children, title, collapsible, description, deleteAction, editAction, border }) => {
  const [height, setHeight] = useState('1000px');
  const contentSpace = useRef(null);

  useEffect(() => {
    setHeight(contentSpace.current.scrollHeight);
  }, []);

  return (
    <div
      style={{ width: '100%' }}
      className={
        styles.block + (className ? ' ' + className : '') + (collapsible ? ' ' + styles.collapsible : '')
      }
    >
      {(title || description) && (
        <div className={styles.title}>
          <div>
            {title && <h2>{title}</h2>}
            {description && <h2>{description}</h2>}
            {!!deleteAction && <TrashIcon onClick={deleteAction} />}
            {!!editAction && <PensilIcon viewBox="0 0 12 15" onClick={editAction} />}
          </div>
        </div>
      )}
      <div
        className={styles.content}
        ref={contentSpace}
        style={{ maxHeight: collapsible ? `${height}` : 'auto' }}
      >
        {children}
      </div>
    </div>
  );
};
