import { Header } from './Header';

import styles from './ClientAreaContainer.module.css';
import { useEffect, useState } from 'react';

export const ClientAreaContainer = ({
  children,
  className,
  blocksClassName,
  headerTitle,
  onGoBack,
  headerMenu,
  headerLogo,
  headerClassName,
  initializeMenuClosed,
  ...props
}) => {
  const [menuClosed, setMenuClosed] = useState(initializeMenuClosed ? initializeMenuClosed : window.innerWidth < 980);
  useEffect(() => {
    if (typeof (headerMenu) === 'undefined') {
      setMenuClosed(true);
    }
  }, [headerMenu]);
  return (
    <div className={((menuClosed) ? styles.clientAreaContainerClosedMenu : styles.clientAreaContainer) + (className ? ' ' + className : '')} {...props}>
      <Header
        title={headerTitle}
        onGoBack={onGoBack}
        menu={headerMenu}
        onClose={() => { setMenuClosed(true); }}
        onOpen={() => { setMenuClosed(false); }}
        isOpen={!menuClosed}
        logo={headerLogo}
        className={headerClassName}
      />
      <div className={styles.blocksWrap}>
        <div className={styles.blocks + (blocksClassName ? ' ' + blocksClassName : '')}>{children}</div>
      </div>
    </div>
  );
};

export default ClientAreaContainer;
export * from './Block';
