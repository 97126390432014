import { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckoutContext } from "../../contexts/CheckoutSession/CheckoutSession";
import { Layout } from "./Layout";
import { useFetch } from "../../hooks";
import logger from "../../utils/logger";
import * as dataLayerHandler from "../../analytics/dataLayerHandler";
import logResponseError from "../../utils/loggerMessages";
import notify, { CHARGE_NEW_REBUY } from "../../utils/notify";

import { deviceIsMobile } from "../../utils/deviceIsMobile";

import { formatCartToPayload, formatCartToContext, validateIsOldForm } from "./preCheckoutHelpers";

const PreCheckout = () => {
  const history = useHistory();
  const { id, typeId } = useParams();
  const { fetchFromBackend } = useFetch();
  const { updateCart } = useContext(CheckoutContext);

  if (deviceIsMobile()) {
    const zendesk = document.querySelectorAll(
      'iframe[title="Botão para abrir a janela de mensagens"]'
    )[0];
    if (zendesk) {
      zendesk.style.display = "none"
    };
  }

  const redirectToMoreInfoPage = () => {
    window.location.href = process.env.REACT_APP_URL_PAGE_ORDERS_MORE_INFO;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirectToMoreInfoPage(), []);

  const getOrderInfo = async (orderId) => {
    let products = {};
    const url = "/api/customers/orders/" + orderId;
    const method = "GET";
    const response = await fetchFromBackend(url, method, {}).catch((error) => {
      logger.error("Failed to load order details (internal error)\n", error);
    });
    if (response.response_code === 0) {
      // Remove duplicates
      let parsed_formulas = response.content.formulas;
      response.content.formulas.forEach((formula, index) => {
        let new_products = [];
        let skus_added = [];
        formula.products.forEach((element) => {
          if (!skus_added.includes(element.sku)) {
            new_products.push(element);
            skus_added.push(element.sku);
          }
        });
        parsed_formulas[index]["products"] = new_products;
      });

      products = parsed_formulas;
    } else {
      notify(CHARGE_NEW_REBUY, "error");
      logResponseError("load order details", url, method, response);
    }
    return products;
  };

  const generateCartToken = async () => {
    let token;
    try {
      const res = await fetchFromBackend(
        "/api/purchase/carts/checkout",
        "POST",
        {}
      );
      if (res.response_code === 0) {
        token = res.content.cart_token;
      }
    } catch (error) {
      logger.error(error);
    }
    return token;
  };

  const getProducts = async () => {
    let products = [];
    try {
      const res = await fetchFromBackend("/api/purchase/products", "GET", {});
      if (res.response_code === 0) {
        products = res.content;
      }
    } catch (error) {
      logger.error(error);
    }
    return products;
  };

  const createCart = async (cart) => {
    try {
      await fetchFromBackend("/api/purchase/carts/create", "POST", cart);
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };

  const getFormulaInfo = async (formId) => {
    const url = `/api/customers/formulas/${formId}`;
    const method = "GET";
    let formula = { form_id: "", formula_name: "", oldForm: false };
    await fetchFromBackend(url, method, {})
      .then((response) => {
        if (response.response_code === 0) {
          const form = response.content[0];
          formula = { formId: formId, formulaName: form.formulaName, oldForm: validateIsOldForm(response) };
        }
      })
      .catch((error) => {
        if (error.name === "NetworkError") {
          logger.error("Failed to fetch formula\n", error);
        } else {
          logger.error(
            "Failed to fetch formula\n",
            "Request:",
            `*${method}* \`${url}\`\n`,
            error
          );
        }
      });
    return formula;
  };

  const createCartWithProducts = async (cartToken, products) => {
    let cartCreationTries = 0;
    const maxTries = 3;
    let cartCreated = false;

    while (cartCreationTries < maxTries && !cartCreated) {
      cartCreated = await createCart(
        formatCartToPayload(cartToken, products)
      ).then((e) => e);
      if (!cartCreated) {
        if (cartCreationTries === maxTries) {
          logger.error("Backed returned error in cart creation");
          throw new Error("Backed returned error in cart creation");
        }
      }
      cartCreationTries++;
    }

    return cartCreated;
  };

  const PrepareCheckout = async () => {
    let cartCreated = false;
    let orderInfo = [];

    const newCartToken = await generateCartToken();
    const products = await getProducts();

    if (typeId === "formula") {
      //rebuy with formula
      const formulaInfo = await getFormulaInfo(id);

      //when formId is old rebuy is not allowed
      if (formulaInfo?.oldForm) {
        history.replace({
          pathname: "/formulas/details/" + id,
          search: window.location.search,
        });
        return;
      }

      let defaultCartProducts = [];
      products.forEach((product) => {
        if (product.prd_custom && product.prd_shelf) {
          defaultCartProducts.push({ ...product, quantity: 1 });
        }
      });

      orderInfo = [{ ...formulaInfo, form_id: id, products: defaultCartProducts }];
      cartCreated = await createCartWithProducts(newCartToken, orderInfo);
    } else {
      //rebuy with order
      const order = id?.replace(/[^0-9]/g, "");
      orderInfo = await getOrderInfo(order);

      const benefitProducts = products.filter(product => product.prd_benefit).map(product => product.prd_id)

      //all products that have a formula and that their products are not benefit
      orderInfo = orderInfo.filter(form => form.formula_name &&
        !form.products.every(product => benefitProducts.includes(product.prd_id)))

      //updating product values and weight
      orderInfo.map(form =>
        form.products.map(product => {
          const newProduct = products.find(item => item.prd_id === product.prd_id)

          if (newProduct) {
            product.value = newProduct.prd_value;
            product.prd_weight = newProduct.prd_weight;
          }
          return product;
        }))

      cartCreated = await createCartWithProducts(newCartToken, orderInfo);
    }

    if (cartCreated) {
      const newCart = {
        products: formatCartToContext(orderInfo),
        token: newCartToken,
        referenceId: id,
        referenceTypeId: typeId
      };
      updateCart(newCart);

      const objFormateDataLayerCart = dataLayerHandler.formatCartForDataLayer(
        newCart
      );

      dataLayerHandler.addToCart(
        objFormateDataLayerCart.products,
        newCartToken
      );
      dataLayerHandler.beginCheckout(
        objFormateDataLayerCart.products,
        objFormateDataLayerCart.value,
        objFormateDataLayerCart.coupon,
        newCartToken
      );

      history.push({
        pathname: "/checkout/" + newCartToken + "/summary-purchase",
        search: window.location.search,
      });
    }
  };

  return <Layout goBack={() => history.goBack()} />;
};

export { PreCheckout };
