import * as  dataLayerHandler from './dataLayerHandler';

export const sendPurchaseEvent = (itens, orderId, cart, paymentType) => {
  let purchaseInfo = {
    transaction_id: orderId,
    value: cart.absolute_value / 100,
    shipping: cart.cart.cart_shippment_fee / 100,
    coupon: cart?.voucher ? cart?.voucher.code : '',
  };

  dataLayerHandler.purchase(itens, purchaseInfo, cart.cart.cart_id);
  dataLayerHandler.purchaseUA(itens, purchaseInfo);
  dataLayerHandler.purchaseCustom(paymentType, itens, purchaseInfo, cart.cart.cart_id);
};
