import styled from "styled-components";
import { ILabelProps } from "./Label.i";
import { defaultTheme as Theme } from "../../theme";

export const SLabel = styled.label<ILabelProps>`
  margin: 0 0 7px;
  font-family: ${Theme?.font?.primary};
  font-style: normal;
  font-weight: 700;
`;
