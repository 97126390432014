import { useHistory } from 'react-router-dom';
import Layout from './Layout';

const Guide = () => {
  const history = useHistory();

  const redirect = () => {
    history.push({ pathname: "/", search: window.location.search });
  };

  const onClickHelp = () => {
    window.open('https://bit.ly/jfy-manual-whats', '_blank', 'noopener,noreferrer');
  };

  return <Layout onClickPrimary={redirect} onClickHelp={onClickHelp} />;
};

export default Guide;
