import styled from "styled-components";
import { ISpanProps } from "./Span.i";
import { defaultTheme as Theme } from "../../theme";

export const SSpan = styled.span<ISpanProps>`
  margin: 6px 7px;
  color:  ${Theme?.color?.base?.i1000};
  font-size: ${Theme?.config?.sizes?.xsm};
  font-family: ${Theme?.font?.secondary};
  font-style: normal;
  font-weight: 400;
`;
