import React from "react";
import { Text } from "../../../../components/Text";

import { Container } from "./styles";

const InfoText = ({ status }) => {
  return (
    <Container>
      {status === "denied" && (
        <>
          <Text align="center">Transação não autorizada</Text>
          <Text bold>Como Resolver</Text>
          <Text>
            <b>1</b> Você pode ter digitado algum dado errado, reveja
          </Text>
          <Text>
            <b>2</b> Se ainda o erro permanecer, tente outro cartão ou forma de
            pagamento
          </Text>
          <Text>
            <b>3</b> Não se preocupe, sua compra se mantém salva
          </Text>
        </>
      )}
      {status === "expired" && (
        <Text bold align="center" font-size="0.85rem">
          Sua compra não foi efetuada. Para garantir seus produtos faça uma nova
          compra, clicando no botão abaixo.
        </Text>
      )}
    </Container>
  );
};

export { InfoText };
