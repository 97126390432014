import AwaitingPaymentIcon from '../../../assets/customIcons/AwaitingIcon';
import PaidIcon from '../../../assets/customIcons/PaidIcon';
import ShippingIcon from '../../../assets/customIcons/ShippingIcon';
import DeliveredIcon from '../../../assets/customIcons/DeliveredIcon';
import { InProductionIcon } from '../../../assets/customIcons/InProductionIcon';

import styles from './OrderTimeline.module.css';

export const Layout = ({ orderStatus, className, iconsClassName }) => {
  const colors = {
    active: '#FF8717',
    inactive: '#000',
  };

  const statuses = {
    awaiting: {
      iconColor: colors.inactive,
      active: false,
    },
    paid: {
      iconColor: colors.inactive,
      active: false,
    },
    shipping: {
      iconColor: colors.inactive,
      active: false,
    },
    delivered: {
      iconColor: colors.inactive,
      active: false,
    },
    production: {
      iconColor: colors.inactive,
      active: false,
    }
  };

  Object.entries(statuses).forEach((status) => {
    if (status[0] === orderStatus) {
      status[1].iconColor = colors.active;
      status[1].active = true;
    }
  });

  return (
    <div className={className}>
      <div className={styles.icons}>
        <div className={styles.divider}></div>
        <AwaitingPaymentIcon
          color={statuses.awaiting.iconColor}
          className={styles.icon + (iconsClassName ? ` ${iconsClassName}` : '')}
        />
        <div className={styles.divider}></div>
        <PaidIcon
          color={statuses.paid.iconColor}
          className={styles.icon + (iconsClassName ? ` ${iconsClassName}` : '')}
        />
        <div className={styles.divider}></div>
        <InProductionIcon
          stroke={statuses.production.iconColor}
          color={statuses.production.iconColor}
          className={styles.icon + (iconsClassName ? ` ${iconsClassName}` : '')}
        />
        <div className={styles.divider}></div>
        <ShippingIcon
          color={statuses.shipping.iconColor}
          className={styles.icon + (iconsClassName ? ` ${iconsClassName}` : '')}
        />
        <div className={styles.divider}></div>
        <DeliveredIcon
          color={statuses.delivered.iconColor}
          className={styles.icon + (iconsClassName ? ` ${iconsClassName}` : '')}
        />
        <div className={styles.divider}></div>
      </div>
      <div className={styles.titles}>
        <h5 className={statuses.awaiting.active ? styles.active : ''}>
          Aguardando
          <br />
          Aprovação
        </h5>
        <h5 className={statuses.paid.active ? styles.active : ''}>
          Pagamento
          <br />
          Aprovado
        </h5>
        <h5 className={statuses.production.active ? styles.active : ''}>
          Em
          <br />
          Manipulação
        </h5>
        <h5 className={statuses.shipping.active ? styles.active : ''}>
          Em
          <br />
          Transporte
        </h5>
        <h5 className={statuses.delivered.active ? styles.active : ''}>Entregue!</h5>
      </div>
    </div>
  );
};

export default Layout;
