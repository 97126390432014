import { ReactComponent as DropperImage } from '../../../assets/dropper.svg';
import Button from '../../../components/Button';
import { Block, ClientAreaContainer } from '../../../components/ClientAreaContainer';
import LoadingDots from '../../../components/LoadingDots';
import OrderTimeline from '../../../components/OrderTimeline';

import styles from './Home.module.css';

export const Layout = ({
  orderInfos,
  fetching,
  redirectToDetails,
  redirectToReorder,
  redirectToForm,
  userName,
}) => {
  return (
    <ClientAreaContainer headerMenu>
      <div className={styles.homePageRoot}>
        <h2 className={styles.titleContainer}>SUA CONTA</h2>
        <h1 className={styles.welcomeContainer}>Bem-vindo, {userName}</h1>
        <span className={styles.subtitleContainer}> Agradecemos por estar conosco </span>
      </div>
      <Block
        title="Último Pedido"
        description={fetching ? '' : orderInfos?.create_date || ''}
        className={styles.blocks + ' ' + styles.lastOrder}
      >
        {fetching ? (
          <LoadingDots />
        ) : !orderInfos ? (
          <div className={styles.lastOrderContainer}>
            <h2>Não há pedidos registrados</h2>
          </div>
        ) : (
          <>
            <div className={styles.lastOrderContainer}>
              {
                (orderInfos.formulas.length > 0) ?
                  <div className={styles.attributeContainer}>
                    <span className={styles.attributeName}>Fórmula: </span>
                    <span className={styles.attributeValue}>{orderInfos.formulas}</span>
                  </div> : null
              }

              <div className={styles.attributeContainer}>
                <span className={styles.attributeName}>Produtos: </span>
                <span className={styles.attributeValue}>{orderInfos.products}</span>
              </div>
              <div className={styles.attributeContainer}>
                <span className={styles.attributeName}>Status: </span>
                <span className={styles.attributeValue}>{orderInfos.statusLabel}</span>
              </div>
              <div className={styles.attributeContainer}>
                <span className={styles.attributeName}>Previsão de entrega até: </span>
                <span className={styles.attributeValue}>{orderInfos.deliveryDate}</span>
              </div>
            </div>
            <OrderTimeline
              status={orderInfos.statusId}
              className={styles.timeline}
              iconsClassName={styles.timelineIcons}
            />
            {orderInfos?.rebuy_allowed && (
              <div className={styles.buttonsContainer}>
                <Button
                  primary
                  text="Comprar novamente"
                  className={styles.buttonStyle}
                  onClick={redirectToReorder}
                />
              </div>
            )}
            <div className={styles.buttonsContainer + ' ' + styles.lastButton}>
              <Button
                secondary
                text="Ver detalhes"
                className={styles.buttonStyle}
                onClick={redirectToDetails}
              />
            </div>

          </>
        )}
      </Block>
      <Block title="Faça uma nova fórmula!" className={styles.newFormulaRootContainer}>
        <div className={styles.newFormulaContainer}>
          <span className={styles.newFormulaHelperText}>
            Nós mudamos e nosso cabelo também, que tal criar uma nova fórmula?
          </span>
          <span className={styles.dropperContainer}>
            <DropperImage />
          </span>
        </div>
        <div className={styles.buttonsContainer + ' ' + styles.createFormulaButton}>
          <Button
            primary
            text="Criar nova fórmula"
            className={styles.buttonStyle}
            onClick={redirectToForm}
          />
        </div>
      </Block>
    </ClientAreaContainer>
  );
};

export default Layout;
