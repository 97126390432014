import {
  Container,
  ImageProduct,
  ContainerImage,
  QuantifyerProduct,
  ContentQuantifyer,
  ContentDescriptionProduct,
  Product,
  Size,
  Price,
  Quantity,
} from "./styles";

const Layout = ({
  decrementFunction,
  incrementFunction,
  numberToRender,
  productName,
  productSize,
  price,
  imageLink,
  enableBenefitProduct,
}) => {
  return (
    <Container>
      <ContainerImage>
        <ImageProduct src={imageLink} alt={`display ${productName}`} />
      </ContainerImage>
      <ContentDescriptionProduct>
        <Product>{productName}</Product>
        <Size>{productSize}</Size>
        {enableBenefitProduct && <Price>{price}</Price>}
      </ContentDescriptionProduct>
      <ContentQuantifyer>
        {enableBenefitProduct ? (
          <QuantifyerProduct
            decrementFunction={decrementFunction}
            incrementFunction={incrementFunction}
            numberToRender={numberToRender}
          />
        ) : (
          <Quantity>x{numberToRender}</Quantity>
        )}
      </ContentQuantifyer>
    </Container>
  );
};

export { Layout };
