import { CCHashed as gatewayPagarme } from '../implementations/Pagarme'
import { CCHashed as gatewayWirecard } from '../implementations/Wirecard'

export const gatewayConfig = ({ gatewayPayment }) => {
    switch (gatewayPayment) {
        case 'wirecard':
            return { gateway: gatewayWirecard };
        case 'pagarme':
            return { gateway: gatewayPagarme };
        default:
            console.error('[GATEWAY PAYMENT] - Unknown gateway selected')
    }
}
