import * as React from "react";
import { IApprovedIconProps } from './ApprovedIcon.i'

const ApprovedIcon: React.FC<IApprovedIconProps> = ({ color = "#74a8a5", width = "112", height = "96", ...props }) => {
    return (
        <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M64.932 3.737h39.564v88.526H64.838m-18.078 0H7.504V3.737h39.359" stroke={color} stroke-width=".879" stroke-miterlimit="10" />
            <path d="m49.317 72.084 40.647-49.808a1.285 1.285 0 1 1 1.997 1.622L51.305 73.706a1.29 1.29 0 0 1-1.81.186 1.284 1.284 0 0 1-.178-1.808z" fill={color} />
            <path d="M49.438 73.91 20.234 50.145a1.285 1.285 0 1 1 1.624-1.995l29.204 23.767a1.285 1.285 0 1 1-1.623 1.995z" fill={color} />
        </svg>

    )
}

export { ApprovedIcon }
