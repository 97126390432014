import styled from 'styled-components';
import { ResponsiveControl, Input as CInput } from '../../lib/jfy-ui';

export const Container = styled.div`
  width: 30%;
  margin-right: 1rem;
  @media ${ResponsiveControl.tablet('max')} {
    width: 70%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.color.base.i300};

  ${(props) =>
    props.status === 'error' && !props.defaultInput
      ? `
      border: 0.5px solid ${props.theme.color.error};
      box-shadow: 0 4px 4px #CE8181;
      background-color: #eeeeee;
      input {
        background-color: #eeeeee;
        color: #000;
        border: none;
      }
      `
      : props.status === 'success' && !props.defaultInput
      ? `
      border: 0.5px solid ${props.theme.color.success};
      color: ${props.theme.color.success};
      background-color: #eeeeee;
      input {
        color: ${props.theme.color.success};
        background-color: #eeeeee;
        border: none;
      }
      `
      : `border: none;`}

  svg {
    padding-right: 0.2rem;
    width: 17px;
  }
`;

export const Message = styled.div`
  ${(props) => (!props.children ? 'padding-top: 10px;' : 'padding-top: 5px;')}
  margin-left: 0.5rem;
  font-size: 0.65rem;
  color: ${(props) => (props.error ? props.theme.color.error : props.theme.color.success)};
`;

export const Input = styled(CInput)`
  outline: none;
  min-height: 2.1rem;
  height: 2.1rem;
`;
