import styled from 'styled-components';
import { Quantifyer } from '../../../lib/jfy-ui';

export const Container = styled.div`
  display: grid;
  gap: 1;
  grid-template-columns: 0.1fr 1.2fr 0.4fr;

  @media (max-width: 767.5px) {
    border-bottom: 1px solid ${(props) => props.theme.color.base.i500};
    padding: 10px;
  }
`;

export const ContainerImage = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  min-width: 55.5px;
`;

export const ImageProduct = styled.img`
    max-width: 100%;
    max-height: 75px;
    margin: 0 auto;
    border-style: 0;
`;

export const ContentQuantifyer = styled.div`
  display: flex;
  height:100%;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem 1.2rem 1.2rem;
`;

export const ContentDescriptionProduct = styled.div`
  display: grid;
  gap: 0;
  grid-template-rows: auto 1fr auto;
  padding: 1rem 0rem 1rem 0.5rem;
`;

export const QuantifyerProduct = styled(Quantifyer)`
  width: 4.66rem;
  text-align: center;

  div{
    width: 33%;
  }
`;

export const Product = styled.span`
  font-weight: 600;
  font-family: ${(props) => props.theme.font.primary};
  font-size: 0.875rem;
`;

export const Size = styled.span`
  font-weight: 400;
  font-family: ${(props) => props.theme.font.primary};
  font-size: 0.650rem;
`;

export const Price = styled.span`
  font-weight: 500;
  font-family: ${(props) => props.theme.font.primary};
  margin-top: 0.7rem;
`;

export const Quantity = styled.span`
  font-weight: bold;
  font-family: ${(props) => props.theme.font.primary};
  margin-top: 0.7rem;
`;
