import styled from "styled-components";
import { ILoadingDotProps } from "./LoadingDots.i";
import { defaultTheme } from "../../theme/defaultTheme";
import { BounceAnimation } from "../Animations/Bounce";

export const Container = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;

  li:nth-child(1) {
    animation-delay: -1.4s;
  }
  li:nth-child(2) {
    animation-delay: -1.2s;
  }
  li:nth-child(3) {
    animation-delay: -1s;
  }
  li:nth-child(4) {
    animation-delay: -0.7s;
  }
`;

export const Dot = styled.li<ILoadingDotProps>`
  ${(props) => {
    switch (props.size) {
      case "sm":
        return `width: ${defaultTheme?.config?.sizes?.sm};
            height: ${defaultTheme?.config?.sizes?.sm};`;
      case "md":
        return `width: ${defaultTheme?.config?.sizes?.md};
                height: ${defaultTheme?.config?.sizes?.md};`;
      case "lg":
        return `width: ${defaultTheme?.config?.sizes?.lg};
                height: ${defaultTheme?.config?.sizes?.lg};`;
      default:
        return `width: ${defaultTheme?.config?.sizes?.md};
                height: ${defaultTheme?.config?.sizes?.md};`;
    }
  }};

  background-color: ${(props) =>
    props.variant === "solid"
      ? defaultTheme?.color?.base?.i100
      : defaultTheme?.color?.primary?.i1000};
  list-style: none;
  animation: ${BounceAnimation} 1.1s infinite;
`;
