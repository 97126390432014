import Menu from '../../../components/Menu';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as JfyLogo } from '../../../assets/jfy-logo.svg';

import styles from './Header.module.css';

export const Header = ({ title, logo, onGoBack, menu, className, onClose, onOpen, isOpen }) => {
  return (
    <div className={styles.header + (className ? ` ${className}` : '')}>
      {onGoBack && (
        <div className={styles.iconGoBack} onClick={onGoBack}>
          <ArrowLeft />
        </div>
      )}

      {logo && <JfyLogo className={styles.logo} alt="JustForYou Logo" />}

      {title && (
        <div className={styles.title}>
          <h1>{title}</h1>
        </div>
      )}

      {menu && <Menu onClose={onClose} onOpen={onOpen} isOpen={isOpen} />}
    </div>
  );
};
