import React, { createContext, useState, useEffect } from 'react';
import { useFetch } from '../hooks';
import notify, { INTERNAL_ERROR, LOGIN_FAILED } from '../utils/notify';
import logger from '../utils/logger';

const AuthContext = createContext({});
const APP_VERSION = process.env.REACT_APP_VERSION;
const appStorageKey = 'ClientArea@' + APP_VERSION;

export const AuthProvider = ({ children }) => {
  const JsonParsedLocalStorage = JSON.parse(localStorage.getItem(appStorageKey));
  const [token, setToken] = useState(JsonParsedLocalStorage?.token);
  const [user, setUser] = useState(JsonParsedLocalStorage?.user);

  const { fetchFromBackend } = useFetch();

  let login = async (user, password) => {
    let parsed_user = user.match(/\d/g).join('');
    let body = {
      username: parsed_user,
      password: password,
    };

    const response = await fetchFromBackend('/api/customers/login/', 'POST', body).catch(
      (error) => {
        logger.error('Failed to log user in\n', error);
      }
    );
    if (response.token) {
      setToken(response.token);
      setUser(response.first_name);
      let localStorageObj = {
        token: response.token,
        user: response.first_name
      };
      localStorage.setItem(appStorageKey, JSON.stringify(localStorageObj));
    } else if (response.non_field_errors) {
      notify(LOGIN_FAILED, 'error');
    } else {
      notify(INTERNAL_ERROR, 'error');
    }
  };

  let logout = () => {
    setToken(null);
    localStorage.removeItem(appStorageKey);
  };

  useEffect(() => {
    const appInfos = JSON.parse(localStorage.getItem(appStorageKey));
    if (!appInfos) {
      localStorage.clear();
      setToken(null);
      setUser(null);
    } else {
      const savedToken = appInfos.token;
      const user = appInfos.user;
      if (savedToken) {
        setToken(savedToken);
        setUser(user);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ signed: Boolean(token), login, token, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
