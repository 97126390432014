import Layout from './Layout';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useFetch } from '../../hooks';
import notify, {
  CHANGE_ORDER_ADDRESS_FAIL,
  CHANGE_ORDER_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
  ADD_ADDRESS_TO_CHECKOUT_WARN,
  SELECT_ADDRESS_TO_CHECKOUT_WARN
} from '../../utils/notify';
import logger from '../../utils/logger';
import useComponentWillUnmount from '../../hooks/useComponentWillUnmount';
import { CheckoutContext } from '../../contexts/CheckoutSession/CheckoutSession';

const MyAddress = ({ orderChange, isCheckout }) => {
  let history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState();
  const [fetching, setFetching] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [incomingFromOrder, setIncomingFromOrder] = useState(false);
  const [awaitingChangeResult, setAwaitingChangeResult] = useState(false);
  const [addressChangeEnabled, setAddressChangeEnabled] = useState(false);
  const { fetchFromBackend } = useFetch();

  let { orderId, token } = useParams();
  const { state } = useLocation();
  const { cart, updateCart } = useContext(CheckoutContext)

  // Save main address before exiting page
  useComponentWillUnmount(async () => {
    if (!selectedAddress || selectedAddress === currentAddress || incomingFromOrder) {
      return;
    }

    try {
      await fetchFromBackend(
        `/api/customers/profile/addresses/${selectedAddress}`,
        'PUT',
        { is_main_address: true },
        true
      );
    } catch (error) {
      logger.error(
        'Failed to save favorite address\n',
        error,
        '\n',
        error.responseJson || null,
        '\nSlug: ',
        selectedAddress
      );
    }
  });

  useEffect(() => {
    orderChange && setIncomingFromOrder(true);
    fetchAdresses();
    if (!cart?.token && isCheckout) {
      redirectToHome(history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHome = (history) => {
    history.replace({
      pathname: "/",
      search: window.location.search,
    });
  };

  // Load adresses
  const fetchAdresses = async () => {
    setFetching(true);
    try {
      const res = await fetchFromBackend('/api/customers/profile/addresses', 'GET', true);
      const adresses = res?.content.map((address, index) => {
        if (address.is_main_address && !orderChange) {
          setCurrentAddress(address.slug);
          setSelectedAddress(address.slug);
        }
        return {
          alias: address.alias,
          slug: address.slug,
          is_main_address: address.is_main_address,
          zip_code: address.zip_code,
          street: address.street,
          number: address.number,
          complement: address.complement ? address.complement : '',
          reference: address.reference ? address.reference : '',
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
          customerAddressId: address.alias
        };
      });
      setAddressList(adresses);
    } catch (err) {
      logger.error('Failed to fetch adresses\n', err, '\n', err.responseJson || null);
    }
    setFetching(false);
  };

  // Deleting specific address
  const deleteAddress = async (slug) => {
    try {
      await fetchFromBackend(`/api/customers/profile/addresses/${slug}`, 'DELETE', true);
      let newAddress = addressList.filter((element) => element.slug !== slug);
      setAddressList(newAddress);
      notify(DELETE_ADDRESS_SUCCESS, 'success');

      // Restoring card selection
      if (newAddress.length > 0) {
        if (newAddress.find((element) => element.is_main_address) && !incomingFromOrder) {
          setSelectedAddress(currentAddress);
        } else {
          setSelectedAddress(newAddress[0].slug);
        }
      } else {
        setCurrentAddress('');
        setSelectedAddress('');
      }
    } catch (error) {
      logger.error(
        'Failed to delete address\n',
        error,
        '\n',
        error.responseJson || null,
        '\nSlug: ',
        slug
      );
    }
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const submitAddressChange = async () => {
    setAwaitingChangeResult(true);
    const body = { new_addr_slug: selectedAddress };
    try {
      const res = await fetchFromBackend(
        `/api/customers/orders/${orderId}/change_address/`,
        'POST',
        body
      );
      if (res.response_code === 0) {
        notify(CHANGE_ORDER_ADDRESS_SUCCESS, 'success');
        setCurrentAddress(selectedAddress);
      } else {
        notify(CHANGE_ORDER_ADDRESS_FAIL, 'warn');
      }
    } catch (err) {
      logger.error(
        'Failed to update address\n',
        err,
        '\n',
        err.responseJson || null,
        '\nBody of the Request:',
        body,
        '\nOrder id: ',
        orderId
      );
    }
    setAwaitingChangeResult(false);
  };

  // Tracking changes to alow submission
  useEffect(() => {
    if (!selectedAddress || selectedAddress === currentAddress) {
      setAddressChangeEnabled(false);
    } else {
      setAddressChangeEnabled(true);
    }
  }, [currentAddress, selectedAddress]);

  const handleChangeAdressToCheckout = () => {
    if (addressList.length === 0) {
      notify(ADD_ADDRESS_TO_CHECKOUT_WARN, 'warn');
    } else if (!selectedAddress) {
      notify(SELECT_ADDRESS_TO_CHECKOUT_WARN, 'warn');
      return;
    } else {
      const address = addressList.find((element) => element.slug === selectedAddress)
      const shipping = { zipcode: address.zip_code };
      updateCart({ ...cart, addressInfo: address, shipping: shipping })

      if (state?.origin === 'checkout') {
        history.replace({ pathname: '/checkout/' + token + '/summary-purchase', search: window.location.search });
      } else {
        history.push({ pathname: '/checkout/' + token + '/summary-purchase', search: window.location.search });
      }
    }
  }

  return (
    <Layout
      isCheckout={isCheckout}
      addNewAddress={() => {
        history.push({ pathname: isCheckout ? '/checkout/' + token + '/address/new' : '/address/add', search: window.location.search });
      }}
      editAddressCallback={(address) => {
        history.push({ pathname: '/address/edit/' + address, search: window.location.search });
      }}
      selectedAddress={selectedAddress}
      handleAddressPick={(slug) => {
        setSelectedAddress(slug);
      }}
      addressList={addressList}
      deleteAddress={(slug) => {
        setOpenConfirmationModal(true);
        setAddressToDelete(slug);
      }}
      openConfirm={openConfirmationModal}
      cancelAction={closeConfirmationModal}
      confirmAction={() => {
        deleteAddress(addressToDelete);
        setOpenConfirmationModal(false);
      }}
      fetching={fetching}
      incomingFromOrder={incomingFromOrder}
      goBack={() => {
        if (state?.origin === 'checkout') {
          history.goBack()
        } else {
          history.replace({ pathname: '/', search: window.location.search });
        }
      }}
      handleChangeAdressToCheckout={handleChangeAdressToCheckout}
      submitAddressChange={submitAddressChange}
      awaitingChangeResult={awaitingChangeResult}
      addressChangeEnabled={addressChangeEnabled}
    />
  );
};
export default MyAddress;
