import React, { createContext, useState } from 'react';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [appData, setAppData] = useState({});

  const saveAppLevelData = (key, data) => {
    setAppData({ ...appData, [key]: data });
  };

  return (
    <DataContext.Provider value={{ saveAppLevelData, appData }}>{children}</DataContext.Provider>
  );
};

export default DataContext;
