import { ShippingChoice } from '../../../components/ShippingChoice';
import { DisplayProductController } from '../../../components/DisplayProductController';
import { Text } from '../../../components/Text';
import { BlockDisplay } from '../../../components/Layout/BlockDisplay';
import { Box } from '../../../components/Layout/Box';
import { Voucher } from '../../../components/Voucher';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';

import { SummaryDescription } from '../../../components/SummaryDescription';
import Modal from '../../../components/Modal';

import formatPrice from '../../../utils/formatPrice';

import styles from '../../../components/ClientAreaContainer/Header/HeaderMargin.module.css';
import stylesCheckout from './Checkout.module.css';
import { checkBenefit } from '../CheckoutHelpers';

import {
  ContentProducts,
  CartContainer,
  Formula,
  Divider,
  ContentAddress,
  AddressInfo,
  DeliveryContainer,
  ContentPayment,
  PaymentControl,
  PaymentDescriptive,
  VoucherContent,
  Button,
  Cvv,
  SummaryContainer,
  SubTotalContainer,
  HeaderDisplay,
  TextDisplay,
  PensilIcon,
  VoucherContainer,
  ContainerButton,
  CustomSelect,
  ClientAreaContainer,
  NoCardContainer,
  Input,
  ContactContent,
  MessageModal,
} from './styles';

const Layout = ({
  goBack,
  productsCheckout,
  addressCheckout,
  paymentCheckout,
  handleQuantifierChange,
  purchaseAmount,
  shippingCompanies,
  installmentsPurchase,
  handleChangeInstallment,
  handleChangeShipping,
  selectedShipping,
  selectedInstallment,
  summaryPurchase,
  handleFinishPurchase,
  setVoucher,
  voucher,
  history,
  token,
  handleAlterCVV,
  cvv,
  formula,
  freeShipping,
  subtotal,
  isLoadingVoucher,
  handleVoucher,
  voucherValidation,
  handleClearVoucher,
  isLoadingCard,
  favoriteCard,
  selectedPaymentType,
  setSelectedPaymentType,
  addressList,
  handleAlterAdress,
  selectedAddress,
  cart,
  fields,
  handleChange,
  isFinishLoading,
  validateField,
  isOpen,
  confirmAction,
}) => {
  const enableNoCardsSection = Object.keys(favoriteCard).length > 0;
  const enableAddressSection = addressList.length > 0;

  return (
    <ClientAreaContainer
      initializeMenuClosed
      headerTitle='Seu Pedido'
      headerClassName={styles.header}
    >
      <Modal active={isOpen} onAccept={confirmAction} acceptText='Editar cartão'>
        <MessageModal>
          Ops, não conseguimos validar seu cartão de crédito. Verifique novamente as informações.
        </MessageModal>
      </Modal>
      <BlockDisplay title='Resumo da Compra'>
        <CartContainer>
          {formula.map((form) => (
            <div key={form.formId}>
              <Box align='left' border>
                <Formula>
                  {form.items.every((item) => !checkBenefit(cart, item))
                    ? `Fórmula: ${form.formName}`
                    : `Brindes`}
                </Formula>
              </Box>
              <ContentProducts>
                {form.items.map((product) => (
                  <DisplayProductController
                    enableBenefitProduct={!checkBenefit(cart, product)}
                    key={product.id}
                    decrementFunction={() =>
                      handleQuantifierChange(form.formId, product.id, product.quantity - 1)
                    }
                    incrementFunction={() =>
                      handleQuantifierChange(form.formId, product.id, product.quantity + 1)
                    }
                    productName={product.displayName}
                    productSize={product.size}
                    price={formatPrice(product.value)}
                    imageLink={product.image}
                    numberToRender={product.quantity}
                  />
                ))}
              </ContentProducts>
            </div>
          ))}
          <Box align='right' border directionBorder='-top'>
            <SubTotalContainer>
              <span>Subtotal:</span>
              <div>{formatPrice(subtotal)}</div>
            </SubTotalContainer>
          </Box>
        </CartContainer>
      </BlockDisplay>
      <Divider border />
      <BlockDisplay>
        <HeaderDisplay>
          <TextDisplay bold>Endereço de entrega</TextDisplay>
          <PensilIcon
            onClick={() =>
              history.push({
                pathname: '/checkout/' + token + '/address',
                state: { origin: 'checkout' },
                search: window.location.search,
              })
            }
          />
        </HeaderDisplay>
        <Box>
          <Text bold>Selecione o endereço de entrega:</Text>
          <ContentAddress>
            <AddressInfo>
              {enableAddressSection ? (
                <ChoiceGroup
                  className={styles.transparentBlock}
                  selected={selectedAddress}
                  data-testid='card-item-render-list'
                  collapsible
                  onChange={(value) => {
                    handleAlterAdress(value, addressList);
                  }}
                >
                  {addressList.map((address, index) => {
                    return (
                      <Choice
                        keyToRender={address.slug}
                        value={address.slug}
                        key={address.slug}
                        title={`${address.street}, ${address.city}-${address.state}`}
                        className={stylesCheckout.choiceRootContainer}
                        data-testid='card-item-render-item'
                        ignoreBorder
                      >
                        <div className={stylesCheckout.cardChoiceContainer}>
                          <Text margin bold>
                            {address.alias}
                          </Text>
                          <Text margin>
                            {address.street}, nº {address.number}, {address.neighborhood},{' '}
                            {address.city}-{address.state}
                          </Text>
                          <Text margin>Complemento: {address.complement}</Text>
                          <Text margin>
                            CEP: <b>{address.zip_code}</b>
                          </Text>
                        </div>
                      </Choice>
                    );
                  })}
                </ChoiceGroup>
              ) : (
                <NoCardContainer>
                  <Text align='center'>Ops, nenhum endereço encontrado.</Text>
                  <ContainerButton>
                    <Button
                      variant='outline'
                      data-gtm='checkout-add-new-credit-card'
                      onClick={() => {
                        history.push({
                          pathname: '/checkout/' + token + '/address/new',
                          state: { origin: 'checkout' },
                          search: window.location.search,
                        });
                      }}
                    >
                      Adicionar um novo endereço
                    </Button>
                  </ContainerButton>
                </NoCardContainer>
              )}
            </AddressInfo>
          </ContentAddress>
          {enableAddressSection && (
            <>
              <Text bold>Escolha uma forma de entrega:</Text>
              <DeliveryContainer>
                {shippingCompanies?.length > 0 &&
                  shippingCompanies.map((company) => (
                    <ShippingChoice
                      key={company?.v_carrier_id}
                      value={company?.v_carrier_id?.toString()}
                      selected={selectedShipping?.toString()}
                      handleChangeShipping={handleChangeShipping}
                      name='cards'
                      days={company?.v_expday}
                      displayName={company?.v_carrier_alias}
                      price={company?.v_valfee}
                      freeShipping={freeShipping}
                    />
                  ))}
              </DeliveryContainer>
              <ContainerButton>
                <Button
                  variant='outline'
                  data-gtm='checkout-add-new-credit-card'
                  onClick={() => {
                    history.push({
                      pathname: '/checkout/' + token + '/address/new',
                      state: { origin: 'checkout' },
                      search: window.location.search,
                    });
                  }}
                >
                  Adicionar um novo endereço
                </Button>
              </ContainerButton>
            </>
          )}
        </Box>
      </BlockDisplay>
      <Divider border />
      <BlockDisplay>
        <HeaderDisplay>
          <TextDisplay bold>Pagamento</TextDisplay>
          <PensilIcon
            onClick={() =>
              history.push({
                pathname: '/checkout/' + token + '/payment',
                state: { origin: 'checkout' },
                search: window.location.search,
              })
            }
          />
        </HeaderDisplay>
        <Box>
          <ContentPayment>
            <Text bold>Escolha uma forma de pagamento:</Text>
            <ChoiceGroup
              className={styles.transparentBlock}
              selected={selectedPaymentType}
              data-testid='card-item-render-list'
              collapsible
              onChange={(value) => {
                setSelectedPaymentType(value);
              }}
            >
              {enableNoCardsSection ? (
                <Choice
                  keyToRender={favoriteCard.last_digits}
                  value='credit-card'
                  title='Cartão de Crédito'
                  data-testid='card-item-render-item'
                  className={stylesCheckout.choiceRootContainer}
                  ignoreBorder
                >
                  <div className={stylesCheckout.cardChoiceContainer}>
                    <PaymentControl>
                      <PaymentDescriptive>
                        <Text>
                          Cartão de Crédito com final
                          <b> {favoriteCard.last_digits}</b>
                        </Text>
                      </PaymentDescriptive>
                      <CustomSelect
                        value={installmentsPurchase.find(
                          ({ value }) => value === selectedInstallment
                        )}
                        options={installmentsPurchase}
                        placeholder='Selecione o parcelamento...'
                        onChange={(e) => handleChangeInstallment(e.target.value)}
                      />
                      <PaymentDescriptive>
                        <Text>CVV:</Text>
                      </PaymentDescriptive>
                      <Cvv
                        variant='blank'
                        value={cvv}
                        onChange={(e) => handleAlterCVV(e.target.value)}
                      />
                    </PaymentControl>
                  </div>
                </Choice>
              ) : (
                <Choice
                  keyToRender='0'
                  value='credit-card'
                  title='Cartão de Crédito'
                  data-testid='card-item-render-item'
                  className={stylesCheckout.choiceRootContainer}
                  ignoreBorder
                >
                  <NoCardContainer>
                    <Text align='center'>Ops, nenhum cartão de crédito encontrado.</Text>
                    <ContainerButton>
                      <Button
                        variant='outline'
                        data-gtm='checkout-add-new-credit-card'
                        onClick={() => {
                          history.push({
                            pathname: '/checkout/' + token + '/payment/new',
                            state: { origin: 'checkout' },
                            search: window.location.search,
                          });
                        }}
                      >
                        Adicionar um novo cartão
                      </Button>
                    </ContainerButton>
                  </NoCardContainer>
                </Choice>
              )}
              <Choice
                keyToRender='pix'
                value='pix'
                title='Pix'
                className={stylesCheckout.choiceRootContainer}
                data-testid='card-item-render-item'
                ignoreBorder
              >
                <div className={stylesCheckout.cardChoiceContainer}>
                  <Text margin>
                    A confirmação de pagamento é realizada em poucos minutos. Utilize o aplicativo
                    do seu banco para pagar.
                  </Text>
                </div>
              </Choice>
              {enableNoCardsSection && (
                <ContainerButton>
                  <Button
                    variant='outline'
                    data-gtm='checkout-add-new-credit-card'
                    onClick={() => {
                      history.push({
                        pathname: '/checkout/' + token + '/payment/new',
                        state: { origin: 'checkout' },
                        search: window.location.search,
                      });
                    }}
                  >
                    Adicionar um novo cartão
                  </Button>
                </ContainerButton>
              )}
            </ChoiceGroup>
          </ContentPayment>
        </Box>
      </BlockDisplay>
      <Divider />
      <BlockDisplay>
        <HeaderDisplay>
          <TextDisplay bold>Meu contato</TextDisplay>
        </HeaderDisplay>
        <Box>
          <ContactContent>
            <PaymentDescriptive>
              <Text>Celular:</Text>
            </PaymentDescriptive>
            <Input
              value={fields.phone?.value}
              onChange={handleChange}
              onBlur={validateField}
              valid={!fields.phone?.valid ? fields.phone?.valid : null}
              message={fields.phone?.message}
              prepend='+55'
              placeholder=''
              onlyDigit
              maxLength={11}
              autoComplete='tel'
              name='phone'
              type='tel'
            />
          </ContactContent>
        </Box>
      </BlockDisplay>
      <Divider />
      <BlockDisplay>
        <VoucherContainer>
          <Text bold>Possui cupom de desconto?</Text>
          <VoucherContent>
            <Voucher
              variant='blank'
              placeholder='Insira seu cupom'
              onChange={(e) => setVoucher(e.target.value.toUpperCase())}
              value={voucher}
              onBlur={() => handleVoucher(voucher)}
              message={voucherValidation.message}
              status={voucherValidation.status}
              readOnly={voucherValidation.status === 'success'}
            />
            <Button
              variant='outline'
              onClick={() => {
                if (voucherValidation.status === 'success') {
                  handleClearVoucher();
                } else {
                  handleVoucher(voucher);
                }
              }}
              isLoading={isLoadingVoucher}
            >
              {voucherValidation.status === 'success' ? 'Remover' : 'Aplicar'}
            </Button>
          </VoucherContent>
        </VoucherContainer>
      </BlockDisplay>
      <BlockDisplay border margin>
        <Box>
          <SummaryContainer>
            {purchaseAmount > 0 &&
              summaryPurchase.map((item, index) => {
                return (
                  (item.value > 0 || item.freeShipping) && (
                    <SummaryDescription
                      key={index}
                      isVoucher={item.isVoucher}
                      text={`${item.type}:`}
                      price={item.value}
                      freeShipping={item.freeShipping}
                    />
                  )
                );
              })}
          </SummaryContainer>
          <Divider generateLine={purchaseAmount > 0} />
          <SummaryContainer margin>
            <Text bold fontSize='1.2rem'>
              Total:
            </Text>
            <Text bold align='right' fontSize='1.2rem'>
              {formatPrice(purchaseAmount)}
            </Text>
          </SummaryContainer>
          <ContainerButton>
            <Button
              isLoading={isFinishLoading}
              variant='solid'
              data-gtm='checkout-finish-purchase'
              onClick={() => {
                !isFinishLoading && handleFinishPurchase();
              }}
            >
              Comprar
            </Button>
          </ContainerButton>
        </Box>
      </BlockDisplay>
    </ClientAreaContainer>
  );
};

export { Layout };
