import Layout from './Layout';
import {
  cpfCnpjSchema,
  cellphoneSchema,
  emailSchema,
  nameSchema,
  birthdateSchema,
} from '../../utils/validator';
import { useForm, useFetch } from '../../hooks';
import { cellphoneMask, cpfCnpjMask, fullDateMask } from '../../utils/formater';
import { useHistory } from 'react-router-dom';
import notify, {
  MISSING_FIELDS,
  USER_CREATED,
  EXISTING_USER,
  MISSING_LAST_NAME,
} from '../../utils/notify';
import logger from '../../utils/logger';
import formatAndValidateName from '../../utils/validator/name';
import logResponseError from '../../utils/loggerMessages';

const Signup = () => {
  const history = useHistory();
  const { fields, setFieldValue, handleChange, validateField, handleFinish } = useForm({
    name: {
      required: true,
      schema: nameSchema,
    },
    email: {
      required: true,
      schema: emailSchema,
    },
    cpfCnpj: {
      required: true,
      mask: cpfCnpjMask,
      schema: cpfCnpjSchema,
    },
    phone: {
      required: true,
      mask: cellphoneMask,
      schema: cellphoneSchema,
    },
    birthdate: {
      required: true,
      schema: birthdateSchema,
      mask: fullDateMask,
    },
  });
  const { fetchFromBackend } = useFetch();

  let signUserUp = async () => {
    let forms = await handleFinish();
    if (forms.valid) {
      const formattedName = formatAndValidateName(fields.name.value);
      if (!formattedName) {
        notify(MISSING_LAST_NAME, 'warn');
        return;
      }

      let formattedDate = fields.birthdate.value.split('/');
      let formattedCpf = fields.cpfCnpj.value.replace(/[.-]/g, '');
      let newCustomerObject = {
        cpf: formattedCpf.length <= 11 ? formattedCpf : undefined,
        cnpj: formattedCpf.length > 11 ? formattedCpf : undefined,
        first_name: formattedName[0],
        last_name: formattedName[1],
        email: fields.email.value,
        phone: fields.phone.value,
        birthdate: formattedDate[2] + '-' + formattedDate[1] + '-' + formattedDate[0],
      };
      const url = '/api/customers/create/';
      const method = 'POST';
      let response = await fetchFromBackend(url, method, newCustomerObject).catch((error) => {
        logger.error(
          'Failed to sign user up (internal error)\n',
          error,
          '\nBody of the Request:',
          newCustomerObject
        );
      });
      switch (response.response_code) {
        case 1:
          notify(USER_CREATED, 'success');
          setTimeout(() => {
            history.push({ pathname: '/login', search: window.location.search });
          }, 2500);
          break;
        case 2:
          notify(EXISTING_USER, 'warn');
          setTimeout(() => {
            history.push({ pathname: '/login', search: window.location.search });
          }, 2500);
          break;
        default:
          logResponseError('sign user up', url, method, response, false, newCustomerObject);
          break;
      }
    } else {
      notify(MISSING_FIELDS, 'error');
    }
  };

  return (
    <Layout
      handleChange={handleChange}
      validateField={validateField}
      fields={fields}
      setFieldValue={setFieldValue}
      goBack={() => {
        history.goBack();
      }}
      signup={() => {
        signUserUp();
      }}
    />
  );
};
export default Signup;
