export const MyDataIcon = ({ color = '1D1D1B', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M12.41 5.84v2.65c0 1.66-1.83 3.87-3.36 3.87-1.53 0-3.31-2.21-3.31-3.87V5.87"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M13.94 14.69l-.14-9.42A4.28 4.28 0 009.53 1h-.9C6.29 1 4.36 2.92 4.36 5.27l-.15 9.42"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M1 17.09c.45-1.28 1.79-1.75 1.79-1.75s3.16-1.22 3.38-1.31c.22-.1.71-.27.78-1.22.05-.55.08-1.49.08-1.49M17.15 17.06c-.46-1.26-1.79-1.72-1.79-1.72s-3.16-1.22-3.38-1.31c-.22-.1-.71-.27-.78-1.22-.05-.55-.08-1.49-.08-1.49M5.79 4.5s1.98 3.07 6.64 1.34M5.75 8.63c-.52 0-.94-.88-.94-1.4v-.91c0-.52.42-.49.94-.49M12.47 8.63c.52 0 .94-.88.94-1.4v-.91c0-.52-.42-.49-.94-.49"
      ></path>
    </svg>
  );
};

export default MyDataIcon;
