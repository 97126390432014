import { useState } from 'react';
import { OrderLayout, FormulaLayout } from './Layout';

export const OrderCard = ({
  displayNumber,
  orderNumber,
  orderDate,
  formulas,
  status,
  estimatedDelivery,
  orderValue,
  onClickRebuy,
  onClickDetails,
  className,
}) => {

  return (
    <OrderLayout
      orderNumber={displayNumber}
      orderDate={orderDate}
      formulas={formulas}
      status={status}
      estimatedDelivery={estimatedDelivery}
      orderValue={orderValue}
      className={className}
      onClickDetails={onClickDetails}
      onClickRebuy={onClickRebuy}
    />
  );
};

export const FormulaCard = ({
  formId,
  formulaName,
  formulaDate,
  formulaDesiredEffects,
  favorite,
  onChange,
  onClickDetails,
  className,
  onClickRebuy,
}) => {
  const [favoriteFormula, setFavoriteFormula] = useState(favorite);

  const handleClickFavorite = () => {
    setFavoriteFormula(!favoriteFormula);
    onChange?.(formId, !favoriteFormula);
  };

  return (
    <FormulaLayout
      formulaName={formulaName}
      formulaDate={formulaDate}
      formulaDesiredEffects={formulaDesiredEffects}
      favorite={favoriteFormula}
      onClickFavorite={handleClickFavorite}
      onClickDetails={onClickDetails}
      onClickRebuy={onClickRebuy}
      className={className}
    />
  );
};
