import { Component } from 'react';
import logger from './logger';

import Button from '../components/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error('*Catch by Error Boundary*', error, '', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div style={{ maxWidth: '90%' }}>
            <h1 style={{ fontSize: 30 }}>Algo deu errado</h1>
            <Button
              style={{ width: '80%', margin: '0 10%' }}
              primary
              text="Voltar para Home"
              onClick={() => window.location.replace('/')}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
