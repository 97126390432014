import { defaultTheme } from '../../../../lib/jfy-ui';
import { Container } from './styles';

import formatPrice from '../../../../utils/formatPrice';

import { Text } from '../../../../components/Text';

const colors = [
  {
    type: 'awaiting-approval',
    color: defaultTheme.color.primary.i900,
  },
  {
    type: 'approved',
    color: defaultTheme.color.success,
  },
  {
    type: 'confirmed',
    color: defaultTheme.color.success,
  },
  {
    type: 'denied',
    color: defaultTheme.color.warning,
  },
  {
    type: 'expired',
    color: defaultTheme.color.warning,
  },
];

const OrderInfo = ({ orderId, status, purchaseAmount }) => {
  const { color } = colors.find((color) => color.type === status);

  return (
    <Container>
      <div>
        <Text bold fontSize={defaultTheme.config.sizes.xsm} color={color}>
          Nº do Pedido {orderId}
        </Text>
        <br />
        <Text bold fontSize={defaultTheme.config.sizes.xsm} color={color}>
          Valor total: {formatPrice(purchaseAmount)}
        </Text>
      </div>
      <Text fontSize={defaultTheme.config.sizes.xsm} color={color}>
        Acompanhe o status da sua compra pelo e-mail ou pela área do cliente
      </Text>
    </Container>
  );
};

export { OrderInfo };
