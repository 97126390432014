import styled from "styled-components";

import { Button as UIButton } from '../../lib/jfy-ui';
import {
  Tab as UITab,
  Tabs as UITabs,
  TabList as UITabList,
  TabPanel as UITabPanel,
} from "react-tabs";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: start;
`;

export const Button = styled(UIButton)`
  min-width: 7.563rem;
  min-height: 1.875rem;
  margin-right: 0.7rem;
  font-size: 0.85rem;

  :hover {
    color: ${(props) => props.theme.color.base.i100};
  }

  ${(props) =>
    !props.selected &&
    `background-color: ${props.theme.color.base.i100};
          color: ${props.theme.color.base.i1000};
          font-weight: 500
      `}
`;

export const Tab = styled(UITab)`
  list-style: none;
  cursor: pointer;
  padding: 12px 25px;
  margin: 0 10px;
  background-color: var(--color-white);
  font-family: var(--font-family-title);
  font-weight: bold;
  white-space: nowrap;
`;
export const Tabs = styled(UITabs)`
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.activated &&
    `
    background-color: ${props.color.primary.i1000}
    color: ${props.color.base.i100}
  `}
`;

export const TabList = styled(UITabList)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const TabPanel = styled(UITabPanel)``;
