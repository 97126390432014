import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { Input } from '../../../components/Input';
import Button from '../../../components/Button';

import styles from './SignUp.module.css';

const Layout = ({ fields, setFieldValue, validateField, handleChange, goBack, signup }) => {
  return (
    <ClientAreaContainer
      headerLogo
      onGoBack={goBack}
      className={styles.container}
      headerClassName={styles.header}
    >
      <Block className={styles.block}>
        <h1 className={styles.title}>Seu cadastro</h1>
        <div className={styles.formInfoRootContainer}>
          <div className={styles.userInfosContainer + ' ' + styles.firstInput}>
            <span className={styles.inputLabel}>Seu nome completo</span>
            <Input
              name="name"
              placeholder="Digite seu nome completo"
              onChange={handleChange}
              onBlur={validateField}
              className={styles.inputStyle}
              value={fields.name?.value}
              valid={!fields.name?.valid ? fields.name?.valid : null}
              message={fields.name?.message}
            />
          </div>
          <div className={styles.userInfosContainer}>
            <span className={styles.inputLabel}>E-mail</span>
            <Input
              placeholder="usuario@exemplo.com.br"
              className={styles.inputStyle}
              autoComplete="email"
              name="email"
              type="email"
              noSpace
              onChange={handleChange}
              onBlur={validateField}
              value={fields.email?.value}
              valid={!fields.email?.valid ? fields.email?.valid : null}
              message={fields.email?.message}
              onSuggestion={(suggestion) => setFieldValue('email', suggestion, true)}
            />
          </div>
          <div className={styles.userInfosContainer}>
            <span className={styles.inputLabel}>CPF | CNPJ</span>
            <Input
              placeholder="000.000.000-00 | 00.000.000/0001-00"
              name="cpfCnpj"
              onlyDigit
              type="tel"
              maxLength={14}
              onChange={handleChange}
              onBlur={validateField}
              value={fields.cpfCnpj?.value}
              valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
              message={fields.cpfCnpj?.message}
              className={styles.inputStyle}
            />
          </div>
          <div className={styles.shortInputsRow}>
            <div className={styles.userInfosContainer + ' ' + styles.shortUserInfosContainer}>
              <span className={styles.inputLabel}>Data de nascimento</span>
              <Input
                className={styles.inputStyle + ' ' + styles.shortInputStyle}
                maxLength={8}
                type="tel"
                name="birthdate"
                autoComplete="bday"
                placeholder="DD/MM/AAAA"
                value={fields.birthdate?.value}
                valid={!fields.birthdate?.valid ? fields.birthdate?.valid : null}
                message={fields.birthdate?.message}
                onChange={handleChange}
                onBlur={validateField}
                onlyDigit
              />
            </div>
            <div className={styles.userInfosContainer}>
              <span className={styles.inputLabel}>Telefone</span>
              <Input
                className={styles.inputStyle}
                valid={!fields.phone?.valid ? fields.phone?.valid : null}
                message={fields.phone?.message}
                value={fields.phone?.value}
                onChange={handleChange}
                onBlur={validateField}
                prepend="+55"
                placeholder="(00) 00000-0000"
                onlyDigit
                maxLength={11}
                autoComplete="tel"
                name="phone"
                type="tel"
              />
            </div>
          </div>
        </div>
        <Button className={styles.button} primary text="Finalizar cadastro" onClick={signup} />
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
