import { IDaniedProps } from './Danied.i'
import * as React from 'react'


const DaniedIcon: React.FC<IDaniedProps> = ({ stroke = "#CF7985", width = "112", height = "99", strokeWidth = ".879", fill = "#1d1d1b", strokeMiterlimit = "10", ...props }) => {
    return (
        <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path d="M64.932 3.854h39.564v91.292H64.838m-18.078 0H7.504V3.854h39.359" stroke={stroke} stroke-width={strokeWidth} stroke-miterlimit={strokeMiterlimit} />
            <path d="m32.134 71.76 45.93-46.414c.504-.51 1.316-.5 1.82.02a1.36 1.36 0 0 1-.019 1.873l-45.93 46.405c-.503.51-1.315.5-1.82-.019a1.348 1.348 0 0 1 .02-1.864z" fill="#CE354B" />
            <path d="m77.615 74.077-45.06-47.299a1.36 1.36 0 0 1 .018-1.874c.504-.51 1.316-.5 1.82.019l45.07 47.299a1.36 1.36 0 0 1-.018 1.874c-.523.51-1.335.5-1.83-.019z" fill="#CE354B" />
        </svg>
    )
}

export { DaniedIcon };
