import Layout from './Layout';
const ProductDisplay = ({
  outlined,
  onClickCallback,
  title,
  size,
  price,
  productName,
  className,
  value,
  imageUrl
}) => {
  return <Layout
    productName={productName}
    outlined={outlined}
    onClickCallback={onClickCallback}
    title={title}
    size={size}
    price={price}
    className={className}
    value={value}
    imageUrl={imageUrl}
  />;
};
export default ProductDisplay;
