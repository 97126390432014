import { ISpanProps } from "./Span.i"
import { SSpan } from "./styles";
import * as React from "react";


export const Span: React.FC<ISpanProps> = ({ children, ...props }) => {
  return (
    <SSpan {...props}>{children}</SSpan>
  )
}
