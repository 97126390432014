import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { ReactComponent as FullHeartIcon } from '../../../assets/icons/full-heart.svg';
import formatPrice from '../../../utils/formatPrice';
import { ContainerButton, Button } from './styles'

import styles from './Cards.module.css';

const CardLayout = ({ title, date, children, className }) => {
  return (
    <div className={styles.card + (className ? ` ${className}` : '')}>
      <div className={styles.title}>
        <div>
          <h2>{title}</h2>
          <h2>{date}</h2>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export const OrderLayout = ({
  orderNumber,
  orderDate,
  formulas,
  status,
  estimatedDelivery,
  orderValue,
  onClickDetails,
  onClickRebuy,
  className,
}) => {
  return (
    <CardLayout title={`N°${orderNumber}`} date={orderDate} className={className}>
      <div className={styles.order}>
        <div className={styles.info}>
          <div>
            <p>
              {
                (formulas.length > 0 && formulas[0] !== '') ?
                  <>
                    <b>{formulas.length > 1 ? 'Fórmulas: ' : 'Fórmula: '}</b>
                    {formulas.join(', ')}
                  </>
                  :
                  null
              }

            </p>
            <p>
              <b>Status: </b>
              {status}
            </p>
            <p>
              <b>{status !== 'Entregue' ? 'Previsão de entrega até: ' : 'Entregue em: '}</b>
              {estimatedDelivery}
            </p>
          </div>
          <p><b>{(orderValue) ? formatPrice(orderValue) : null}</b></p>
        </div>
        <ContainerButton>
          <Button variant="solid" onClick={onClickRebuy} >Comprar novamente</Button>
          <Button variant="outline" onClick={onClickDetails}>Ver detalhes</Button>
        </ContainerButton>
      </div>
    </CardLayout>
  );
};

export const FormulaLayout = ({
  formulaName,
  formulaDate,
  formulaDesiredEffects,
  favorite,
  onClickFavorite,
  onClickDetails,
  onClickRebuy,
  className,
}) => {
  return (
    <CardLayout className={className} title={`Fórmula: ${formulaName}`} date={formulaDate}>
      <div className={styles.formula}>
        <p>
          <b>Efeitos:</b> {formulaDesiredEffects}
        </p>
        <div className={styles.favorite} onClick={onClickFavorite}>
          {favorite ? <FullHeartIcon /> : <HeartIcon />}
        </div>
      </div>
      <ContainerButton>
        <Button variant="solid" onClick={onClickRebuy} >Comprar novamente</Button>
        <Button variant="outline" onClick={onClickDetails}>Ver detalhes</Button>
      </ContainerButton>
    </CardLayout>
  );
};
