export const FormulasIcon = ({ color = '#1D1D1B', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      fill="none"
      viewBox="0 0 18 21"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M5.906 4.954v5.558l-4.78 9.55c0 .221.185.394.395.394H12.86a.398.398 0 00.395-.395l-4.78-9.549V4.954M9.043 4.818H5.35"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M8.97 14.725H7.535M8.413 15.898h-.877M8.413 13.539h-.877M8.97 17.084H7.535M8.413 18.258h-.877"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M9.105 8.536a4.714 4.714 0 013.446-1.087 4.722 4.722 0 014.299 5.114 4.725 4.725 0 01-4.163 4.286"
      ></path>
      <path fill="#fff" d="M13.255 9.129a3.46 3.46 0 011.989 2.73l-1.989-2.73z"></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M13.255 9.129a3.46 3.46 0 011.989 2.73"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M5.041 6.3c-1.544.26-3.001-.815-3.26-2.409C1.532 2.31 2.582.815 4.14.568c1.556-.26 3.013.828 3.26 2.41.05.345.05.69-.012 1.012"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M4.56 1.655c.802.21 1.445.89 1.593 1.78"
      ></path>
    </svg>
  );
};

export default FormulasIcon;
