import React from 'react';
import { defaultTheme } from '../../../../lib/jfy-ui';

import { Text } from '../../../../components/Text';

import {
  StatusPaymentContainer,
  IconContainer,
  AWaitingIcon,
  ApprovedIcon,
  DaniedIcon,
} from './styles';

const config = [
  {
    type: 'awaiting-approval',
    color: defaultTheme.color.primary.i900,
    title: 'Compra via CARTÃO',
    subtitle: 'aguardando aprovação',
  },
  {
    type: 'approved',
    color: defaultTheme.color.success,
    title: 'Compra via CARTÃO aprovada!',
    subtitle: '',
  },
  {
    type: 'confirmed',
    color: defaultTheme.color.success,
    title: 'Compra via PIX aprovada!',
    subtitle: '',
  },
  {
    type: 'denied',
    color: defaultTheme.color.error,
    title: 'Opa, deu um problema',
    subtitle: 'com seu cartão!',
  },
  {
    type: 'expired',
    color: defaultTheme.color.error,
    title: 'Opa, seu PIX expirou :(',
    subtitle: '',
  },
];

const StatusPayment = ({ status }) => {
  const { color, title, subtitle } = config.find(
    (config) => config.type === status
  );
  return (
    <StatusPaymentContainer>
      <Text
        bold
        align='center'
        color={color}
        fontSize={defaultTheme.config.sizes.sm}
      >
        {title}
      </Text>
      <Text bold color={color} fontSize={defaultTheme.config.sizes.sm}>
        {subtitle}
      </Text>
      <IconContainer>
        {status === 'awaiting-approval' && <AWaitingIcon color={color} />}
        {(status === 'approved' || status === 'confirmed') && (
          <ApprovedIcon color={color} />
        )}
        {(status === 'denied' || status === 'expired') && (
          <DaniedIcon color={color} />
        )}
      </IconContainer>
    </StatusPaymentContainer>
  );
};

export { StatusPayment };
