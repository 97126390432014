import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactPaginate from 'react-paginate';
import { deviceIsMobile } from "../../../utils/deviceIsMobile";
import ClientAreaContainer from '../../../components/ClientAreaContainer';
import { OrderCard } from '../../../components/Cards';
import LoadingDots from '../../../components/LoadingDots';
import tabsStyle from '../../../styles/Tabs.module.css';
import styles from './MyOrders.module.css';
import paginateStyles from '../../../styles/Paginate.module.css';
import { Button, NewOrderContainer } from './styles'

const Layout = ({
  orders,
  activeTab,
  setActiveTab,
  redirectToDetails,
  loading,
  pageCount,
  currentPage,
  handlePageChange,
  redirectToRebuy,
  handleNewOrder
}) => {
  const renderOrders = (orders) => {
    return (
      <div className={styles.orders}>
        {orders.length > 0 ? (
          orders.map((order, index) => {
            return (
              <OrderCard
                displayNumber={order.display_number}
                orderNumber={order.id}
                orderDate={order.create_date}
                formulas={order.formulas}
                status={order.status_id}
                estimatedDelivery={order.date_delivery}
                orderValue={order.value_total}
                className={styles.card}
                onClickDetails={() => {
                  redirectToDetails(order.id);
                }}
                onClickRebuy={() => {
                  redirectToRebuy(order.id);
                }}
                key={index}
              />
            );
          })
        ) : loading ? (
          <LoadingDots className={styles.loading} />
        ) : (
          <h2 className={styles.emptyListText}>Não existem pedidos!</h2>
        )}
      </div>
    );
  };

  const isMobile = deviceIsMobile()

  return (
    <ClientAreaContainer headerTitle="Meus Pedidos" headerMenu headerClassName={styles.header}>
      <Tabs
        defaultIndex={activeTab}
        onSelect={(index) => setActiveTab(index)}
        className={tabsStyle.tabs}
      >
        <div className={styles.containerTabList}>
          <TabList className={tabsStyle.list}>
            <Tab className={tabsStyle.tab + (activeTab === 0 ? ` ${tabsStyle.active}` : '')}>
              Mais recentes
            </Tab>
            <Tab className={tabsStyle.tab + (activeTab === 1 ? ` ${tabsStyle.active}` : '')}>
              Últimos 3 meses
            </Tab>
            <Tab className={tabsStyle.tab + (activeTab === 2 ? ` ${tabsStyle.active}` : '')}>
              Últimos 6 meses
            </Tab>
          </TabList>
          {!isMobile && (<NewOrderContainer className={tabsStyle.list}>
            <Button variant="solid" onClick={handleNewOrder}>
              Criar novo pedido
            </Button>
          </NewOrderContainer>)}
        </div>
        {isMobile && (<NewOrderContainer>
          <Button variant="solid">
            Criar novo pedido
          </Button>
        </NewOrderContainer>)}
        <TabPanel>{renderOrders(orders)}</TabPanel>
        <TabPanel>{renderOrders(orders)}</TabPanel>
        <TabPanel>{renderOrders(orders)}</TabPanel>
      </Tabs>
      <div className={styles.pages}>
        {pageCount > 1 && activeTab !== 0 ? (
          <ReactPaginate
            previousLabel="Anterior"
            nextLabel="Próxima"
            forcePage={currentPage}
            pageCount={pageCount}
            previousClassName={paginateStyles.paginationPreviousLink}
            containerClassName={paginateStyles.pagination}
            previousLinkClassName={paginateStyles.paginationLink}
            nextLinkClassName={paginateStyles.paginationLink}
            activeClassName={paginateStyles.paginationLinkActive}
            onPageChange={handlePageChange}
          />
        ) : (
          <></>
        )}
      </div>
    </ClientAreaContainer>
  );
};

export default Layout;
