/* eslint-disable no-template-curly-in-string */

export const FIELD_REQUIRED = 'campo obrigatório';
export const FIELD_INVALID = 'campo inválido';
export const FIELD_ONE_OF = 'deve ser um dos seguintes valores: ${values}';
export const FIELD_NOT_ONE_OF = 'não pode ser um dos seguintes valores: ${values}';

export const STRING_LENGTH = 'deve ter exatamente ${length} caracteres';
export const STRING_MIN = 'deve ter pelo menos ${min} caracteres';
export const STRING_MAX = 'deve ter no máximo ${max} caracteres';
export const STRING_EMAIL = 'formato de e-mail inválido';
export const STRING_URL = 'deve ter um formato de URL válida';
export const STRING_TRIM = 'não deve conter espaços no início ou no fim.';
export const STRING_LOWERCASE = 'deve estar em maiúsculo';
export const STRING_UPPERCASE = 'deve estar em minúsculo';
export const STRING_MATCHES = 'a formatação não condiz com o esperado';

export const NUMBER_MIN = 'deve ser no mínimo ${min}';
export const NUMBER_MAX = 'deve ser no máximo ${max}';
export const NUMBER_LESSTHAN = 'deve ser menor que ${less}';
export const NUMBER_MORETHAN = 'deve ser maior que ${more}';
export const NUMBER_NOTEQUAL = 'não pode ser igual à ${notEqual}';
export const NUMBER_POSITIVE = 'deve ser um número posítivo';
export const NUMBER_NEGATIVE = 'deve ser um número negativo';
export const NUMBER_INTEGER = 'deve ser um número inteiro';

export const DATE_INVALID = 'data inválida';
export const DATE_MIN = 'deve ser maior que a data ${min}';
export const DATE_MAX = 'deve ser menor que a data ${max}';

export const ARRAY_MIN = 'deve ter no mínimo ${min} itens';
export const ARRAY_MAX = 'deve ter no máximo ${max} itens';

export const BIRTHDATE_LESS_THAN_1_YEAR = 'Idade menor que 1 ano';
export const BIRTHDATE_MORE_THAN_120_YEAR = 'Idade maior que 120 anos';

export const CPF_LENGHT = 'CPF deve conter 11 números';
export const CPF_INVALID = 'CPF inválido';
export const CNPJ_LENGHT = 'CNPJ deve conter 14 números';
export const CNPJ_INVALID = 'CNPJ inválido';

export const INVALID_CARD_NUMBER = 'Número do cartão inválido';
export const EXPIRED_CARD = 'Cartão expirado';

export const CONFIRMATION_PASSWORD_INVALID = 'as senhas devem ser a mesma';
export const INVALID_HOLDER_NAME = 'Apenas letras são permitidas nesse campo';

export const NAME_INVALID = 'É necessário nome e sombrenome'
