const formatAndValidateName = (rawNameField) => {
  let splitedName = rawNameField.split(' ');
  // filtering extra spaces
  splitedName = splitedName.filter((i) => i);
  if (splitedName.length < 2) {
    return null;
  }
  const first_name = splitedName[0];
  const last_name = splitedName.slice(1).reduce((t, i) => t + ' ' + i);
  return [first_name, last_name];
};

export default formatAndValidateName;
