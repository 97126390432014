import { useEffect, useState, useRef, useCallback } from 'react';
import validator from '../utils/validator';
import { formater } from '../utils/formater';
import usePrevious from './usePrevious';

export const useForm = (initialState) => {
  const [anyInvalid, setAnyInvalid] = useState(false);
  const [anyEmpty, setAnyEmpty] = useState(true);
  const [anyChanged, setAnyChanged] = useState(false);
  const [fields, setFields] = useState(initialState);
  const previousFields = usePrevious(fields);
  const cbRef = useRef();

  const setFieldsCallback = useCallback((fields, cb) => {
    cbRef.current = cb;
    setFields(fields);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(fields);
      cbRef.current = null;
    }
  }, [fields]);

  useEffect(() => {
    if (JSON.stringify(fields) !== JSON.stringify(previousFields)) {
      setAnyInvalid(
        Boolean(
          Object.keys(fields).find((key) => {
            return fields[key].valid === false;
          })
        )
      );
      setAnyEmpty(
        Boolean(
          Object.keys(fields).find((key) => {
            return !fields[key].value;
          })
        )
      );
      setAnyChanged(
        Boolean(
          Object.keys(fields).find((key) => {
            return fields[key].value !== fields[key].initialValue;
          })
        )
      );
    }
  }, [fields, previousFields]);

  const setInitialValues = () => {
    Object.keys(fields).forEach((fieldName) => {
      setFieldsCallback((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          initialValue: prev[fieldName].value,
        },
      }));
    });
  };

  const getFieldsValues = () => {
    let values = {};
    Object.keys(fields).forEach(
      (fieldName) => (values = { ...values, [fieldName]: fields[fieldName].value })
    );
    return values;
  };

  const validateField = async ({ target }) => {
    setFields({
      ...fields,
      [target.name]: {
        ...fields[target.name],
        ...(await validator(
          target.value,
          fields[target.name].required,
          fields[target.name].schema
        )),
      },
    });
  };

  const handleChange = async ({ target }, validate = false) => {
    let inputValue = target.value === 'on' ? target.checked : target.value;
    return setFieldValue(target.name, inputValue, validate);
  };

  const setFieldValue = async (fieldName, value, validate = false) => {
    if (!fields[fieldName]) {
      throw new Error(`Field "${fieldName}" doesn't exist!`);
    }

    value = formater(value || '', fields[fieldName].mask || []);

    let validationResult = {};
    if (validate) {
      validationResult = await validator(
        value,
        fields[fieldName].required,
        fields[fieldName].schema
      );
    }

    setFieldsCallback((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        valid: undefined,
        message: undefined,
        value: value,
        ...validationResult,
      },
    }));
  };

  const handleFinish = async () => {
    let newFieldsData = fields;
    let invalidFields = [];

    for (const key of Object.keys(fields)) {
      let fieldValidation = await validator(
        newFieldsData[key].value,
        fields[key].required,
        fields[key].schema
      );
      if (!fieldValidation.valid) {
        invalidFields.push(key);
      }

      newFieldsData = {
        ...newFieldsData,
        [key]: {
          ...newFieldsData[key],
          ...fieldValidation,
        },
      };
    }

    if (invalidFields.length > 0) {
      setFields(newFieldsData);
      return {
        valid: false,
        data: newFieldsData,
        details: {
          invalidFields,
        },
      };
    }

    return { valid: true, details: {}, data: fields };
  };

  return {
    fields,
    handleChange,
    validateField,
    handleFinish,
    anyInvalid,
    anyEmpty,
    setFieldValue,
    anyChanged,
    setInitialValues,
    getFieldsValues,
  };
};

export default useForm;
