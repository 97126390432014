import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { Input, PasswordInput } from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';

import styles from './MyInfos.module.css';
import { Fragment } from 'react';
import Modal from '../../../components/Modal';
import { ReactComponent as InformImportantSVG } from '../../../assets/icons/inform-important.svg';

const Layout = ({
  handleChange,
  validateField,
  fields,
  setFieldValue,
  handleChangeMod,
  validateFieldMod,
  fieldsMod,
  finishedFetch,
  handleFirstSubmit,
  handleModalSubmit,
  handleModalCancel,
  modalOpen,
  anyChanged,
}) => {
  return (
    <ClientAreaContainer
      headerTitle="Dados Pessoais"
      headerMenu
      className={styles.container}
      headerClassName={styles.header}
      blocksClassName={styles.blocks}
    >
      {modalOpen && (
        <Modal onAccept={handleModalSubmit} onCancel={handleModalCancel}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <InformImportantSVG className={styles.modalHeaderIcon} />
              <p>
                Para a sua segurança, alterações no campo de email necessitam de confirmação com a
                sua senha de acesso.
              </p>
            </div>
            <h3>Senha</h3>
            <PasswordInput
              className={styles.modalInput}
              name="password"
              placeholder="Insira sua senha aqui"
              noSpace
              onChange={handleChangeMod}
              onBlur={validateFieldMod}
              value={fieldsMod.password?.value}
              valid={!fieldsMod.password?.valid ? fieldsMod.password?.valid : null}
              message={fieldsMod.password?.message}
            />
          </div>
        </Modal>
      )}
      <Block className={styles.block}>
        <h1 className={styles.title}>Seus dados</h1>
        {!finishedFetch ? (
          <LoadingDots />
        ) : (
          <Fragment>
            <div className={styles.inputs}>
              <div className={styles.input}>
                <h2>Seu nome completo</h2>
                <Input
                  placeholder=""
                  className={styles.inputStyle}
                  name="name"
                  onChange={handleChange}
                  onBlur={validateField}
                  value={fields.name?.value}
                  valid={!fields.name?.valid ? fields.name?.valid : null}
                  message={fields.name?.message}
                />
              </div>
              <div className={styles.shortInputs}>
                <div className={styles.input}>
                  <h2>E-mail</h2>
                  <Input
                    placeholder=""
                    className={styles.inputStyle}
                    autoComplete="email"
                    name="email"
                    type="email"
                    noSpace
                    onChange={handleChange}
                    onBlur={validateField}
                    value={fields.email?.value}
                    valid={!fields.email?.valid ? fields.email?.valid : null}
                    message={fields.email?.message}
                    onSuggestion={(suggestion) => setFieldValue('email', suggestion, true)}
                  />
                </div>
                <div className={styles.input}>
                  <h2>Telefone</h2>
                  <Input
                    value={fields.phone?.value}
                    onChange={handleChange}
                    prepend="+55"
                    placeholder=""
                    onlyDigit
                    maxLength={11}
                    autoComplete="tel"
                    name="phone"
                    type="tel"
                  />
                </div>
              </div>
              <div className={styles.shortInputs}>
                <div className={styles.input}>
                  <h2>CPF</h2>
                  <Input
                    placeholder=""
                    name="cpfCnpj"
                    type="tel"
                    maxLength={14}
                    onChange={handleChange}
                    onBlur={validateField}
                    value={fields.cpfCnpj?.value}
                    valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
                    message={fields.cpfCnpj?.message}
                    className={styles.inputStyle}
                    disabled={true}
                  />
                </div>
                <div className={styles.input}>
                  <h2>Data de nascimento</h2>
                  <Input
                    maxLength={8}
                    type="tel"
                    name="birthdate"
                    autoComplete="bday"
                    placeholder=""
                    value={fields.birthdate?.value}
                    valid={!fields.birthdate?.valid ? fields.birthdate?.valid : null}
                    message={fields.birthdate?.message}
                    onChange={handleChange}
                    onBlur={validateField}
                    onlyDigit
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <Button
          className={styles.button}
          primary
          text="Salvar alterações"
          onClick={handleFirstSubmit}
          disabled={!anyChanged}
        />
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
