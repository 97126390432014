import styles from './ProductDisplay.module.css';

const Layout = ({ outlined, productName, onClickCallback, title, size, price, className, value, imageUrl }) => {
  const benefit = Boolean(!value);
  return (
    <div
      id="root-product-container"
      className={
        styles.displayRootContainer +
        (outlined ? ` ${styles.outlined}` : '') +
        (className ? ` ${className}` : '')
      }
    >
      <div className={!benefit ? styles.productImageContainer : styles.benefitImageContainer}>
        <img
          className={!benefit ? styles.imageContent : ''}
          src={(imageUrl) ? imageUrl : process.env.PUBLIC_URL + `/img/products/${productName}.png`}
          alt={`Frasco de ${productName} JustForYou`}
        />
      </div>
      <div className={styles.productInfosContainer}>
        <span className={styles.titleContainer}>{title}</span>
        {!benefit &&
          <>
            <span className={styles.sizeContainer}>{size}</span>
            <span className={styles.priceContainer}>{price}</span>
          </>
        }
      </div>
    </div>
  );
};
export default Layout;
