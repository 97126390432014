import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../contexts/Auth';
import ReactGA from 'react-ga';

const CustomRoute = ({ isPrivate = false, component: Component, render, path, ...rest }) => {
  const { signed } = useContext(AuthContext);
  const isAuthenticated = signed;

  ReactGA.pageview(window.location.pathname + window.location.search);

  if (render) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return !isPrivate || isAuthenticated ? (
            render
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
                search: window.location.search,
              }}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return !isPrivate || isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
                search: window.location.search,
              }}
            />
          );
        }}
      />
    );
  }
};

export default CustomRoute;
