export const HelpIcon = ({ color = '#000', className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" fill="none" viewBox="0 0 19 17" className={className}>
      <path
        fill={color}
        d="M.244 0A.24.24 0 00.07.073.255.255 0 000 .251v12.545c0 .067.026.13.071.178a.24.24 0 00.173.073h3.994l2.37 3.836a.238.238 0 00.412 0l2.37-3.836h9.366a.24.24 0 00.173-.074.255.255 0 00.071-.177V.251a.255.255 0 00-.071-.178.24.24 0 00-.173-.073H.244zm18.269 12.545H9.256a.246.246 0 00-.205.115l-2.23 3.619-2.244-3.616a.246.246 0 00-.205-.115H.487V.504h18.026v12.041z"
      ></path>
      <path fill={color} d="M9.714 4.25H4.25v.607h5.464V4.25zM15.179 7.893H4.25V8.5h10.929v-.607z"></path>
    </svg>
  );
};

export default HelpIcon;
