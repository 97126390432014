import * as React from 'react';
import { IInvalidIconProps } from './InvalidIcon.i'


const InvalidIcon: React.FC<IInvalidIconProps> = ({ fill = "#D91717", viewBox = "0 0 14 14", ...props }) => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
            <path d="M7 0a7 7 0 0 0-7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0-7-7zm0 13.333A6.33 6.33 0 0 1 .667 7 6.33 6.33 0 0 1 7 .667 6.33 6.33 0 0 1 13.333 7 6.33 6.33 0 0 1 7 13.333z" fill={fill} />
            <path d="M7 8.333c.183 0 .333-.15.333-.333V4.167A.334.334 0 0 0 7 3.833a.334.334 0 0 0-.333.334V8c0 .183.15.333.333.333zm0 1.834a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" fill={fill} />
        </svg>
    );
}

export { InvalidIcon };
