import { Container, Button } from "./styles";

const tabInfoButtonContent = [
  {
    value: "my-cards",
    text: "Meus cartões",
  },
];

const TabNavigator = ({ activeTab, handleSwichActiveTab }) => {
  return (
    <Container>
      {tabInfoButtonContent.map((itemMenu) => (
        <Button
          key={itemMenu.value}
          selected={activeTab === itemMenu.value}
          variant="solid"
          onClick={() => handleSwichActiveTab(itemMenu.value)}
        >
          {itemMenu.text}
        </Button>
      ))}
    </Container>
  );
};

export { TabNavigator };
