import styled from 'styled-components';
import { Button as CButton, ResponsiveControl, defaultTheme } from '../../../../lib/jfy-ui';
import { KitBoxIcon as KitIcon } from '../../../../lib/jfy-icons';

import { Text as CText } from '../../../../components/Text';
import { QrCode as QR } from '../../../../components/QrCode';

export const GridContainer = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  padding: 1rem;
`;

export const Text = styled(CText)`
  font-size: ${(props) => props.theme.config.sizes.sm};
  @media ${ResponsiveControl.tablet('max')} {
    font-size: ${(props) => props.theme.config.sizes.xsm};
  }
`;
export const TextContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

export const QrContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QrCode = styled(QR)`
  width: 20rem;
  height: 12rem;
  padding: 0.5rem;

  @media ${ResponsiveControl.tablet('max')} {
    width: 15rem;
    height: 10rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled(CButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 50%; */
  min-height: 30%;
  @media ${ResponsiveControl.tablet('min')} {
    min-width: 50%;
    min-height: 30%;
  }
  margin-bottom: 0.8rem;
`;

export const PixPasteCopyContainer = styled.div`
  padding: 0.5rem;
  word-break: break-all;
  border: 2px solid ${(props) => props.theme.color.base.i1000};
`;

export const KitBoxIcon = styled(KitIcon).attrs(() => ({
  fill: defaultTheme.color.primary.i1000,
}))``;
