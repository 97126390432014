import { toast } from 'react-toastify';
import styles from './notify.module.css';

export const notify = (message, severity = '', styledMessage = {}, onClick = () => { }) => {
  const content = (
    <div className={styles.content}>
      <p>
        {message && message}
        {styledMessage?.bold && <b>{styledMessage.bold}</b>}
        {styledMessage?.normal && styledMessage.normal}
        {styledMessage?.underline && <u>{styledMessage.underline}</u>}
      </p>
    </div>
  );
  switch (severity) {
    case 'success':
      toast.success(content);
      break;
    case 'warn':
      toast.warn(content);
      break;
    case 'error':
      toast.error(content);
      break;
    case 'delivery':
      toast(content, {
        className: styles.deliveryBackground,
        bodyClassName: styles.deliveryFont,
        autoClose: 22000,
        hideProgressBar: true,
        onClick: onClick,
      });
      break;
    default:
      toast.info(content);
  }
};

export default notify;
export * from './toastMessages';
