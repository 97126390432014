import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { Input } from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';

import styles from './ForgotPassword.module.css';

export const Layout = ({
  onGoBack,
  handleChange,
  validateField,
  fields,
  setFieldValue,
  onClickSendEmail,
  onClickHelp,
  emailSent,
  invalidEmail,
  loading,
}) => {
  return (
    <ClientAreaContainer
      headerLogo
      onGoBack={onGoBack}
      className={styles.container}
      headerClassName={styles.header}
    >
      <Block className={styles.block}>
        <h1 className={styles.title}>Esqueci minha senha</h1>
        <div className={styles.input}>
          <h1>Email</h1>
          <Input
            type="email"
            placeholder="Seu Email"
            autoComplete="email"
            name="email"
            noSpace
            onChange={handleChange}
            onBlur={validateField}
            value={fields.email?.value}
            valid={!fields.email?.valid ? fields.email?.valid : null}
            message={fields.email?.message}
            onSuggestion={(suggestion) => setFieldValue('email', suggestion, true)}
          />
        </div>
        <div className={styles.feedback}>
          {emailSent && (
            <p>
              Se você já tiver uma conta cadastrada, dê uma olhada no seu e-mail e clique no
              <b> link de recuperação da senha</b>
            </p>
          )}
          {invalidEmail && (
            <div>
              <p>
                <b>Ops... Este e-mail não está cadastrado!</b>
              </p>
              <p>
                Tente outro e-mail, caso o erro persistir entre em contato com nosso atendimento.
              </p>
            </div>
          )}
          {loading && <LoadingDots />}
        </div>
        <div className={styles.buttons}>
          {!invalidEmail ? (
            <Button
              primary
              disabled={loading}
              text={emailSent ? 'Voltar ao login' : 'Enviar'}
              onClick={() => {
                emailSent ? onGoBack() : onClickSendEmail();
              }}
            />
          ) : (
            <>
              <Button primary text="Tentar Novamente" onClick={onClickSendEmail} />
              <Button secondary text="Entrar em contato" onClick={onClickHelp} />
            </>
          )}
        </div>
      </Block>
    </ClientAreaContainer>
  );
};

export default Layout;
