import { useContext } from 'react';
import { NetworkResponseWasNotOk, BadRequestError } from '../utils/customErrors';
import AuthContext from '../contexts/Auth';

const useFetch = () => {
  const context = useContext(AuthContext);

  const fetchFromBackend = async (
    endpoint = '',
    method,
    body = {},
    checkResponseForError = false,
    attemptReconnetion = 10
  ) => {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    const authToken = context.token;
    authToken && headers.append('Authorization', `Token ${authToken}`);

    let init = { method, headers };
    if (method !== 'GET') {
      init = { method, headers, body: JSON.stringify(body) };
    }

    return fetch(process.env.REACT_APP_API_BASE_URL + endpoint, init)
      .then(async (result) => {
        if (!result.ok && checkResponseForError) {
          throw new BadRequestError(`${result.status} (${result.statusText})`, await result.json());
        }

        if (result.status === 401) {
          context.logout();
          return;
        }

        const body = await result.json();
        return { ...body, status: result.status };
      })
      .catch(async (error) => {
        if (error?.message === 'Failed to fetch' && attemptReconnetion > 0) {
          //await 200ms before to request
          if (attemptReconnetion < 10) await new Promise(r => setTimeout(r, 200));

          let attempsRemaning = attemptReconnetion;
          return await fetchFromBackend(
            endpoint,
            method,
            body,
            checkResponseForError,
            --attempsRemaning
          )
        }
        throw new NetworkResponseWasNotOk(
          init.method,
          process.env.REACT_APP_API_BASE_URL + endpoint,
          error
        );
      });
  };

  return { fetchFromBackend };
};

export default useFetch;
