import styled from 'styled-components';

export const Text = styled.span`
  font-size: ${(props) => props.fontSize ? props.fontSize : props.theme.config.sizes.xsm};
  font-family: ${(props) => props.theme.font.primary};
  text-align:${(props) => props.align};
  font-weight:${(props) => props.boldWeight ? props.boldWeight : props.bold ? "bold" : 'normal'};
  ${(props) => props.fontweight && `font-weight: ${props.fontweight};`}
  color:${(props) => props.isSecundary ? props.theme.color?.base?.i800 : props.highlight ? props.theme.color.success : props.color ? props.color : props.theme.color?.base?.i1000};
  margin-bottom:${(props) => props.margin ? "0.5rem" : "0rem"};
`;
