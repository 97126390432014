import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useFetch } from '../../hooks';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import notify, {
  FORMULA_LOADING_FAIL,
  ORDER_DETAILS_FAIL,
} from '../../utils/notify';
import logger from '../../utils/logger';
import Layout from './Layout';
import logResponseError from '../../utils/loggerMessages';
import formatPrice from '../../utils/formatPrice';
import { validateDateToIncrement } from '../../utils/validateDateToIncrement';

dayjs.extend(dayjsBusinessDays);

const genera_skus = [
  'RL6FQ8BQJ',
  '6JPJ3DS2N',
  '5ZY954EVH'
];


const OrdersDetails = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const [formulaDetails, setFormulaDetails] = useState([]);
  const [finishedFetch, setFinishedFetch] = useState(false);
  const [finishedFormFetch, setFinishedFormFetch] = useState([]);
  const [date, setDate] = useState();
  const [orderInfos, setOrderInfos] = useState();
  const [noForm, setNoForm] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState({
    value: '',
    method: '',
    card: '',
    installments: '',
  });
  const [addressInfos, setAddressInfos] = useState({
    zipcode: '',
    city: '',
    state: '',
    neighborhood: '',
    number: '',
    street: '',
  });

  const history = useHistory();
  const { fetchFromBackend } = useFetch();

  const formatInstallments = (installmensStr) => {
    let splitted, value, formattedValue;

    try {
      splitted = installmensStr.split('de');
      if ((splitted.length || []) > 1) {
        value = splitted[1].split('R$')[0];
        formattedValue = formatPrice(parseFloat(value) * 100);
      }
    } catch (error) {
      logger.error('Failed to split installmensStr on formatInstallments function\n', error);
    }

    if (splitted[0] && formattedValue) {
      return splitted[0] + ' de ' + formattedValue
    }

    return '';
  };

  useEffect(() => {
    async function fetchOrder() {
      let noForm = false;
      const url = '/api/customers/orders/' + orderId;
      const method = 'GET';
      const response = await fetchFromBackend(url, method, {}).catch((error) => {
        logger.error('Failed to load order details (internal error)\n', error);
      });
      if (response.response_code === 0) {
        let orderDate = response.content.created_at.date
          ? dayjs(response.content.created_at.date).subtract(3, 'hours').format('DD/MM/YYYY')
          : '';
        let deliveryDate = response.content.date_delivery.date
          ? dayjs(response.content.created_at.date)
            .businessDaysAdd(parseInt(response.content.delivery_days) + Number(validateDateToIncrement(response.content.created_at.date, response.content?.status_id)?.daysToIncrement))
            .subtract(3, 'hours')
            .format('DD/MM/YYYY')
          : '';

        // Remove duplicates
        let parsed_formulas = response.content.formulas;
        response.content.formulas.forEach((formula, index) => {
          let new_products = [];
          let skus_added = [];
          formula.products.forEach((element) => {
            if (!skus_added.includes(element.sku)) {
              new_products.push(element);
              skus_added.push(element.sku);
            }
            if (genera_skus.includes(element.sku)) {
              setNoForm(true);
              noForm = true;
            }
          });
          parsed_formulas[index]['products'] = new_products;
        });

        setOrderInfos({
          display_number: response.content.display_id,
          delivery_date: deliveryDate,
          formulas: parsed_formulas,
          status: response.content.status.data.name,
          status_id: response.content.status_id,
          track_url: response.content.track_url,
          rebuy_allowed:
            response.content?.rebuy_allowed === undefined ? true : response.content.rebuy_allowed,
        });
        setDate(orderDate);
        setOrderDetails(response);
      } else {
        notify(ORDER_DETAILS_FAIL, 'error');
        logResponseError('load order details', url, method, response);
      }
      setFinishedFetch(true);
      if (!noForm) {
        fetchFormula();
      }
    }

    async function fetchFormula() {
      const url = '/api/customers/orders/' + orderId + '/formula/';
      const method = 'GET';
      const response = await fetchFromBackend(url, method, {}).catch((error) => {
        logger.error('Failed to load formulas (internal error)\n', error);
      });
      if (response.response_code === 0) {
        setFormulaDetails(
          response.content.map((formula) => {
            let ingredients = formula.ingredients
              .map((ingredient) => {
                return ingredient.title;
              })
              .join(', ');
            let effects = formula.questions[11].questionAnswer;
            return {
              id: formula['_id']['$oid'],
              ingredients: ingredients,
              effects: effects,
              formula_name: formula.formulaName,
            };
          })
        );
      } else {
        notify(FORMULA_LOADING_FAIL, 'error');
        logResponseError('load formulas', url, method, response);
      }
      setFinishedFormFetch(true);
    }

    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderDetails) {

      let method;
      if (orderDetails.content.payments) {
        method = orderDetails.content?.payments[0]?.name;
      }
      setPaymentInfos({
        value: parseFloat(orderDetails.content.value_total),
        method: method,
        card: method === 'credit_card' ? orderDetails?.content.payments[0]?.card : '',
        installments: orderDetails.content.installment_formated,
      });

      setAddressInfos({
        zipcode: orderDetails.content.shipping_address.data.zip_code,
        city: orderDetails.content.shipping_address.data.city,
        state: orderDetails.content.shipping_address.data.uf,
        neighborhood: orderDetails.content.shipping_address.data.neighborhood,
        number: orderDetails.content.shipping_address.data.number,
        street: orderDetails.content.shipping_address.data.street,
      });
    }
  }, [orderDetails]);

  return (
    <Layout
      finishedFetch={finishedFetch}
      finishedFormFetch={finishedFormFetch}
      orderNumber={orderId}
      date={date}
      formulas={orderDetails ? orderDetails?.content?.formulas : []}
      formulaDetails={formulaDetails}
      genericProducts={orderDetails?.content?.generic_products}
      addressInfos={addressInfos}
      paymentInfos={paymentInfos}
      orderInfos={orderInfos}
      goBack={() => {
        history.goBack();
      }}
      onClickFormulaDetails={(formId) => {
        history.push(`/formulas/details/${formId}`);
      }}
      redirectToReorder={() => {
        history.push({ pathname: '/pre-checkout/' + orderId + '/order', search: window.location.search });
      }}
      handleAddressChange={() => {
        history.push({ pathname: '/address/order-change/' + orderId, search: window.location.search });
      }}
      formatInstallments={formatInstallments}
      noFormulaOrder={noForm}
    />
  );
};
export default OrdersDetails;
