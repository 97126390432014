import { useContext, useEffect, useState } from 'react';
import { useFetch } from '../../hooks';
import Layout from './Layout';
import notify, { INCREASE_IN_DEMAND } from '../../utils/notify';
import logger from '../../utils/logger';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/Auth';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import { validateDateToIncrement } from '../../utils/validateDateToIncrement';

dayjs.extend(dayjsBusinessDays);

const Home = () => {
  const [orderInfos, setOrderInfos] = useState();
  const [fetching, setFetching] = useState(true);
  const history = useHistory();
  const context = useContext(AuthContext);
  const { fetchFromBackend } = useFetch();

  const redirectToForm = () => {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set('callback', `${window.location.origin}/formulas/details/{{formId}}`)
    window.location.replace(process.env.REACT_APP_FORM_URL + '?' + urlParams.toString())
  };

  const redirectToDetails = () => {
    history.push({ pathname: '/orders/details/' + orderInfos.orderId, search: window.location.search });
  };

  const redirectToReorder = async () => {
    history.push({ pathname: '/pre-checkout/' + orderInfos.orderId + '/order', search: window.location.search });
  };

  let getOrders = async (page, offset) => {
    let parsedOrders = []
    let url = '/api/customers/orders/?page=' + page + '&maxdays=' + offset

    let orders = await fetchFromBackend(url, 'GET', {}).catch((error) => {
      logger.silentError('Failed to load orders on home page\n', error);
    });

    if (orders?.content?.data.length > 0) {
      parsedOrders = orders.content.data.map((order) => {
        let formulas = order.formulas.map((formula) => {
          return formula.formula_name;
        });
        return {
          id: order.id,
          display_number: order.display_id,
          number: order.id,
          status_id: order.status.data.name,
          date_delivery: order.date_delivery.date
            ? dayjs(order.created_at.date)
              .businessDaysAdd(parseInt(order.delivery_days) + Number(validateDateToIncrement(order.created_at.date, order.status_id)?.daysToIncrement))
              .subtract(3, 'hours')
              .format('DD/MM/YYYY')
            : '-',
          //4-paid 11-production
          statusIsValidToNotifyDeleyedDelivery: [4, 11].includes(order.status_id),
          dateOrderIsValidToNotifyDeleyedDelivery: validateDateToIncrement(order.created_at.date)?.isValidToIncrement,
          create_date: order.created_at.date
            ? dayjs(order.created_at.date).subtract(3, 'hours').format('DD/MM/YYYY')
            : '-',
          value_total: parseFloat(order.value_total) * 100,
          formulas: formulas,
        };
      });
    }

    return parsedOrders
  };

  const productsEvaluator = (order) => {
    let formulas = order.content.formulas;
    let products = [];

    let genericProducts = {
      vitamin: {
        sku: '6JPP3VPJZ',
        qty: 0,
        string: {
          single: 'Vitamina',
          multiple: 'Vitaminas',
        },
      },
    };
    formulas.forEach((formula) => {
      // Remove duplicated products
      let new_products = [];
      let skus_added = [];
      formula.products.forEach((element) => {
        if (!skus_added.includes(element.sku)) {
          new_products.push(element);
          skus_added.push(element.sku);
        }
      });
      new_products.forEach((product) => {
        let foundProducts = products.find((item) => item.sku === product.sku);
        if (foundProducts) {
          foundProducts.quantity += product.quantity
        } else {
          products.push(product)
        }
      });
    });
    let result = [];
    products.forEach((product) => {
      if (product.quantity > 0) {
        result.push(product.quantity + ' ' + product.display_name);
      }
    });

    // handle generic products
    order.content?.generic_products?.forEach((product) => {
      Object.values(genericProducts).find((prod) => prod.sku === product.sku).qty +=
        product.quantity;
    });
    Object.values(genericProducts).forEach((product) => {
      if (product.qty > 1) {
        result.push(product.qty + ' ' + product.string.multiple);
      } else if (product.qty > 0) {
        result.push(product.qty + ' ' + product.string.single);
      }
    });

    return result.join(', ');
  };

  async function notifyClient() {
    const orders = await getOrders(1, 90);
    const ordersQtyIsValidToNotifyDeleyedDelivery = orders.reduce((acc, order) => acc + order.statusIsValidToNotifyDeleyedDelivery ? 1 : 0, 0)
    if (ordersQtyIsValidToNotifyDeleyedDelivery > 0) {
      notify(false, "delivery", INCREASE_IN_DEMAND, handleRedirectToAction);
    }
  }

  const handleRedirectToAction = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var urlParamsString = urlParams.toString();

    if (urlParamsString) {
      urlParamsString = '?' + urlParamsString;
    }

    window.location.replace(process.env.REACT_APP_URL_REDIRECT_ACTION + '?' + urlParams.toString());
  };

  useEffect(() => {
    async function fetchLastOrder() {
      let order = await fetchFromBackend('/api/customers/orders/last', 'GET', {}).catch((error) => {
        logger.error('Failed to get last orders\n', error);
      });
      if (order?.content?.formulas && order?.response_code === 0) {
        setOrderInfos({
          orderId: order.content.id,
          formulas: order.content.formulas
            .map((formula) => {
              return formula.formula_name;
            })
            .join(', '),
          products: productsEvaluator(order),
          statusLabel: order.content.status.data.name,
          statusId: order.content.status_id,
          deliveryDate: order.content.date_delivery.date
            ? dayjs(order.content.created_at.date)
              .businessDaysAdd(parseInt(order.content.delivery_days) + Number(validateDateToIncrement(order.content.created_at.date, order.content?.status_id)?.daysToIncrement))
              .subtract(3, 'hours')
              .format('DD/MM/YYYY')
            : '-',
          create_date: order.content.created_at.date
            ? dayjs(order.content.created_at.date).subtract(3, 'hours').format('DD/MM/YYYY')
            : '',
          rebuy_allowed:
            order.content.rebuy_allowed === undefined ? true : order.content.rebuy_allowed,
        });

        setFetching(false);
      }
    }
    fetchLastOrder();
    notifyClient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      userName={context.user}
      orderInfos={orderInfos}
      fetching={fetching}
      redirectToDetails={redirectToDetails}
      redirectToReorder={redirectToReorder}
      redirectToForm={redirectToForm}
    />
  );
};
export default Home;
