import CustomRadio from '../../CustomRadio';
import styles from './Choice.module.css';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
const Choice = ({
  title,
  value,
  description,
  info,
  selected,
  disabled,
  onChange,
  className,
  radioClassName,
  infoClassName,
  variant,
  children,
  deleteAction,
  keyToRender = 0,
  ignoreBorder = false,
  ...props
}) => {
  return (
    <div
      className={
        styles.choice +
        ' ' +
        styles[variant] +
        (disabled ? ' ' + styles.disabled : '') +
        (className ? ' ' + className : '') +
        (selected === (value || title) ? ' ' + styles.selected : '')
      }
      {...props}
      onClick={disabled ? null : () => onChange(value || title)}
    >
      <div className={styles.wrap}>
        <div className={styles.radio}>
          <CustomRadio
            value={value || title}
            selected={selected}
            disabled={disabled}
            onChange={disabled ? null : onChange}
            className={styles.input + (radioClassName ? ' ' + radioClassName : '')}
          />
        </div>
        <div className={styles.header}>
          <h4 className={styles.title}>{title || value}</h4>
          {description && <p className={styles.description}>{description}</p>}
          {deleteAction && (<div className={styles.deleteIconContainer}  >
            <DeleteIcon className={styles.iconStyle} onClick={deleteAction} data-testid="card-item-render-delete-action" id={keyToRender > 0 ? keyToRender + '-item-remove' : ''} />
          </div>)}
        </div>
        {info && (
          <div className={styles.infoWrap}>
            <h4 className={styles.info + (infoClassName ? ' ' + infoClassName : '')}>{info}</h4>
          </div>
        )}
      </div>
      <span className={!ignoreBorder ? styles.cardSeparator : ''} />
      <div className={styles.content}>{children && children}</div>
    </div >
  );
};

Choice.displayName = 'Choice';

export { Choice };
