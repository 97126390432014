import formatPrice from "../../utils/formatPrice";
import { formatProductsFromCart } from "../../utils/formatProducts";
import { getUrlParams } from "../../utils/urlParamsHandler";

const voucherCodes = {
  notFound: "NOT_FOUND",
  notActive: "NOT_ACTIVE",
  notSet: "NOT_SET",
  zipcodeLimit: "ZIPCODE_NOT_COVERED",
  missingZipcode: "MISSING_ZIPCODE",
  missingProductType: "MISSING_PRODUCT_TYPE",
  insufficientProducts: "PRODUCT_QUANTITY",
  minimunValue: "MINIMUN_VALUE",
  voucherApplied: "APPLIED",
  missingBenefit: "MISSING_BENEFIT",
};

export const findShippingInfoById = (shippingCompanies, selectedShippingId) => {
  return shippingCompanies.find(
    (company) => company.v_carrier_id === selectedShippingId
  );
};

export const findInstallmentsInfo = (
  installmentsPurchase,
  selectedInstallmentId
) => {
  return installmentsPurchase.find(
    ({ value }) => value?.toString() === selectedInstallmentId?.toString()
  );
};

export const selectBestVoucher = (cart, vouchers) => {
  let bestVoucher = {};
  let bestFinalValue;
  let productsQuantity = 0;
  cart.products.forEach((formula) => {
    formula.items.forEach((item) => {
      productsQuantity += item.quantity;
    });
  });
  vouchers.forEach((voucher) => {
    if (voucher.zip_limitation) return;
    if (voucher.minimun_spent > cart.totalValue) return;
    if (voucher.product_quantity > productsQuantity) return;

    let finalValue =
      cart.totalValue -
      calculateDiscount(
        cart.totalValue,
        voucher.discount_value,
        voucher.is_percent
      );

    if (cart.shipping && voucher.free_shipment) {
      finalValue -= cart.shipping.fee;
    }

    if (finalValue < bestFinalValue || !bestFinalValue) {
      bestVoucher = voucher;
      bestFinalValue = finalValue;
    }
  });

  return bestVoucher;
};

export const getValidVoucherMessage = (cart, voucher) => {
  const percent = voucher?.is_percent || cart.voucher?.percent;
  let discountValue = voucher?.discount_value || cart.voucher?.discountValue;
  let message = " ";
  if (discountValue) {
    if (percent) {
      message = `-${formatPrice(
        calculateDiscount(cart.totalValue, discountValue, percent)
      )} (${discountValue}% off)`;
    } else {
      message = `-${formatPrice(discountValue)}`;
    }
  }

  return message;
};

export const getInvalidVoucherMessage = (voucher) => {
  let message = "";
  let fix;
  if (
    voucher?.code === voucherCodes.insufficientProducts &&
    voucher?.suggest_fix
  ) {
    message = `Cupom aplicável a partir de ${voucher.expecting} produtos.`;
    fix = "multipleForms";
  } else if (
    voucher?.code === voucherCodes.insufficientProducts &&
    voucher?.expecting
  ) {
    message = `Cupom aplicável a partir de ${voucher.expecting} produtos.`;
  } else if (voucher?.code === voucherCodes.minimunValue) {
    message = `Valor mínimo para aplicação não atingido`;
  } else {
    message = "Cupom inválido";
  }

  return {
    message,
    fix,
  };
};

export const validVoucher = ({ voucher }) => {
  return voucher?.code === voucherCodes.voucherApplied && voucher?.is_valid;
};

export const calculateDiscount = (totalValue, discount, percent) => {
  return percent ? (totalValue / 100) * discount : discount;
};

export const redirectToHome = (history) => {
  history.replace({
    pathname: "/",
    search: window.location.search,
  });
};

export const getSummaryValues = (cart) => {
  return [
    {
      type: "Subtotal",
      value: cart?.totalValue || 0,
      isVoucher: false,
      freeShipping: false
    },
    {
      type: "Cupom",
      value: cart?.voucher?.percent
        ? (cart?.totalValue / 100) * cart?.voucher?.discountValue
        : cart?.voucher?.discountValue || 0,
      isVoucher: true,
      freeShipping: false
    },
    {
      type: "Frete",
      value: cart?.shipping?.v_valfee || 0,
      isVoucher: false,
      freeShipping: !!cart?.shipping === cart?.voucher?.freeShipping,
    },
  ];
};

export const generateNewInstallments = (amount,maxInstallment = Number(process.env.REACT_APP_MAX_INSTALLMENTS) || 5 ) => {
  const installments = Object.keys(new Array(maxInstallment).fill(null)).map(
    Number
  );

  return installments.map((installment) => {
    return {
      value: ++installment,
      label: `Em ${installment}x de ${formatPrice(
        amount / installment
      )} sem juros`,
    };
  });
};

export const handleValidadeItensQuantityCart = (cart) => {
  const productsCheckout = formatProductsFromCart(cart);

  const QuantityProductsCart = productsCheckout.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  return {
    valid: QuantityProductsCart > 0,
    quantityProducts: QuantityProductsCart,
  };
};

export const formatCartFinish = (cart) => {
  let formattedCart = {
    products: formatProducts(cart.products),
    zipcode: cart.addressInfo.customer_zip.replace("-", ""),
    carrier_id: cart.shipping.v_carrier_id,
    shipment_cost: cart.shipping.v_valfee,
    total_value: cart.totalValue,
  };

  if (cart.voucher) {
    formattedCart.voucher_code = cart.voucher?.code;
    formattedCart.discount_value = calculateDiscount(
      cart.totalValue,
      cart.voucher.discountValue,
      cart.voucher.percent
    );
    formattedCart.shipment_value = cart.voucher.freeShipping
      ? 0
      : cart.shipping.v_valfee;
  } else {
    formattedCart.discount_value = 0;
    formattedCart.shipment_value = cart.shipping.v_valfee;
  }
  return formattedCart;
};

export const formatProducts = (products) => {
  return products.map((form) => ({
    form_id: form.formId,
    form_name: form.formName,
    products: form.items.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
      weight: item.weight,
    })),
  }));
};

export const getUrlVoucher = (cart) => {
  const urlVoucher = getUrlParams(
    new URLSearchParams(window.location.search),
    ["promocode"]
  );
  let existingVoucher;
  if (urlVoucher?.promocode) {
    existingVoucher = urlVoucher.promocode;
  } else if (cart.voucher) {
    existingVoucher = cart.voucher.code;
  }
  return existingVoucher?.toUpperCase()
}

export const validateDateDifference = (start_date, end_date) => {
  const start = new Date(start_date).getTime();
  const end = new Date(end_date).getTime();
  const today = new Date().getTime();

  return today <= end && today >= start
}

export const filterValidVouchers = (vouchers) => {
  return vouchers.filter(({ start_date, end_date }) => validateDateDifference(start_date, end_date))
}

export const checkBenefit = (cart, product) => {
  return product.benefit && cart.voucher?.benefits.includes(product.id);
};

export const addBenefitToCart = (cart, voucher) => {
  if (!voucher?.benefit) return cart;

  const benefit = {
    id: voucher.prd_id,
    sku: voucher.prd_sku,
    name: voucher.prd_name,
    displayName: voucher.prd_dspnam,
    description: voucher.prd_dscrp,
    value: 0,
    custom: voucher.prd_custom,
    brand: voucher.prd_brand,
    image: voucher.prd_image,
    shelf: voucher.prd_shelf,
    quantity: 1,
    benefit: true,
    size: voucher.prd_szdisp,
    weight: voucher.prd_weight,
  };
  if (benefit.id) {
    const index = cart.products.findIndex((form) => !form.formId);
    if (index !== -1) {
      if (!cart.products[index].items.find((item) => item.id === benefit.id)) {
        cart.products[index].items.push(benefit);
      }
    } else {
      cart.products.push({
        formId: null,
        formName: null,
        items: [benefit],
      });
    }
  }
  return cart;
};

export const removeBenefitFromCart = (cart) => {
  let emptyFormIndex = null;
  cart.products.forEach((form, index) => {
    form.items = form.items.filter((item) => {
      return !checkBenefit(cart, item);
    });
    if (form.items.length === 0) {
      emptyFormIndex = index;
    }
  });
  if (emptyFormIndex) {
    cart.products.splice(emptyFormIndex, 1);
  }
  return cart;
};
