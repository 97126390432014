import pagarme from 'pagarme';

export const CCHashed = async ({ number, card_holder_name, card_expiration_date, cvc }) => {
    let client = await pagarme.client.connect({
        encryption_key: process.env.REACT_APP_ENCRYPTION_PAGARME,
    });
    let card_hash = await client.security.encrypt({
        card_number: number,
        card_holder_name: card_holder_name,
        card_expiration_date: card_expiration_date,
        card_cvv: cvc,
    });

    return card_hash
}

