import { useHistory } from 'react-router-dom';
import Layout from './Layout';

const Help = () => {
  const history = useHistory();
  const handleEmailClick = () => {
    history.push({ pathname: "/help/send-email", search: window.location.search });
  };

  const handleClickHelp = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var urlParamsString = urlParams.toString();

    if (urlParamsString) {
      urlParamsString = '?' + urlParamsString;
    }
    window.location.replace(process.env.REACT_APP_URL_REDIRECT_HELP_CENTER + '?' + urlParams.toString());
  }

  return <Layout onClickEmail={handleEmailClick} onClickHelp={handleClickHelp} />;
};

export default Help;
