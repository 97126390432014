import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import styles from './Login.module.css';

const Layout = ({
  fields,
  handleChange,
  validateField,
  onClickLogin,
  onClickSignup,
  onClickRecoverPassword,
  disableLoginButton,
}) => {
  return (
    <ClientAreaContainer headerLogo className={styles.container} headerClassName={styles.header}>
      <Block className={styles.block}>
        <h1 className={styles.title}>Bem vinda à área do cliente!</h1>
        <div className={styles.inputs}>
          <h1>CPF | CNPJ</h1>
          <Input
            className={styles.input}
            placeholder="Insira seu CPF ou CNPJ aqui"
            name="cpfCnpj"
            type="tel"
            maxLength={14}
            onChange={handleChange}
            onBlur={validateField}
            value={fields.cpfCnpj?.value}
            valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
            message={fields.cpfCnpj?.message}
            onlyDigit
          />
          <h1>Senha</h1>
          <Input
            className={styles.input}
            placeholder="Insira sua senha aqui"
            type="password"
            name="password"
            onChange={handleChange}
            value={fields.password?.value}
            valid={!fields.password?.valid ? fields.password?.valid : null}
            onBlur={validateField}
            message={fields.password?.message}
          />
        </div>
        <div className={styles.buttons}>
          <Button primary text="Entrar" onClick={onClickLogin} disabled={disableLoginButton} />
          <Button secondary text="Cadastrar" onClick={onClickSignup} />
          <div>
            <p>Esqueceu sua senha?</p>
            <Button tertiary text="Recupere aqui" onClick={onClickRecoverPassword} />
          </div>
        </div>
      </Block>
    </ClientAreaContainer>
  );
};

export default Layout;
