import { ChoiceGroup, Choice } from "../../../../components/ChoiceGroup";
import { Text } from "../../../../components/Text";

const DisplayPaymentMethod = ({
  styles,
  isCheckout,
  setSelectedCard,
  selectedCard,
  myCards,
  activeTab,
  openConfirm,
}) => {
  const favoriteCreditCard = myCards.at(-1) || {};

  return (
    <>
      {activeTab === "my-cards" || !isCheckout ? (
        <>
          <ChoiceGroup
            className={styles.transparentBlock}
            selected={selectedCard}
            data-testid="card-item-render-list"
            onChange={(value) => {
              setSelectedCard(value);
            }}
          >
            {myCards.map((element, key) => {
              return (
                <Choice
                  key={key}
                  keyToRender={element.last_digits}
                  value={element.card_id}
                  title="Cartão de Crédito"
                  className={styles.choiceRootContainer}
                  data-testid="card-item-render-item"
                  deleteAction={() => openConfirm(element.card_id)}
                >
                  <div className={styles.cardChoiceContainer}>
                    <span className={styles.cardGeneralInfos}>
                      {element.brand + " ****" + element.last_digits}
                    </span>
                    <span className={styles.cardExpireInfos}>
                      {"Expira em " +
                        element.exp_date.substring(0, 2) +
                        "/" +
                        element.exp_date.substring(2, 4)}
                    </span>
                  </div>
                </Choice>
              );
            })}
          </ChoiceGroup>
          {myCards.length === 0 && (
            <div className={styles.noContent}>
              <span>Nenhum cartão foi encontrado </span>
            </div>
          )}
        </>
      ) : (
        <ChoiceGroup
          className={styles.transparentBlock}
          selected={selectedCard}
          data-testid="card-item-render-list"
          onChange={(value) => {
            setSelectedCard(value);
          }}
        >
          {Object.keys(favoriteCreditCard).length > 0 && (
            <Choice
              keyToRender={favoriteCreditCard.last_digits}
              value={favoriteCreditCard.card_id}
              title="Cartão de Crédito"
              className={styles.choiceRootContainer}
              data-testid="card-item-render-item"
            >
              <div className={styles.cardChoiceContainer}>
                <span className={styles.cardGeneralInfos}>
                  {favoriteCreditCard.brand +
                    " ****" +
                    favoriteCreditCard.last_digits}
                </span>
                <span className={styles.cardExpireInfos}>
                  {"Expira em " +
                    favoriteCreditCard.exp_date.substring(0, 2) +
                    "/" +
                    favoriteCreditCard.exp_date.substring(2, 4)}
                </span>
              </div>
            </Choice>
          )}
          <Choice
            keyToRender="pix"
            value="Pix"
            title="Pix"
            className={styles.choiceRootContainer}
            data-testid="card-item-render-item"
          >
            <div className={styles.cardChoiceContainer}>
              <Text>
                A confirmação de pagamento é realizada em poucos minutos.
                Utilize o aplicativo do seu banco para pagar.
              </Text>
            </div>
          </Choice>
          <></>
        </ChoiceGroup>
      )}
    </>
  );
};

export { DisplayPaymentMethod };
