import { useEffect, useState } from 'react';
import Layout from './Layout';
import { useFetch, useComponentWillUnmount } from '../../hooks';
import { useHistory } from 'react-router-dom';
import logger from '../../utils/logger';
import dayjs from 'dayjs';

const MyFormulas = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [recentForms, setRecentForms] = useState();
  const [favoriteForms, setFavoriteForms] = useState();
  const [allForms, setAllForms] = useState();
  const [addToFavorites, setAddToFavorites] = useState([]);
  const [removeFromFavorites, setRemoveFromFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchFromBackend } = useFetch();

  useComponentWillUnmount(() => {
    if (addToFavorites.length !== 0 || removeFromFavorites.length !== 0) {
      updateFavoriteForms(addToFavorites, removeFromFavorites);
    }
  });

  const goToDetailsPage = (formulaId) => {
    history.push({ pathname: 'formulas/details/' + formulaId, search: window.location.search });
  };

  const redirectToRebuy = (formulaId) => {
    history.push({ pathname: '/pre-checkout/' + formulaId + '/formula', search: window.location.search })
  };

  const parseForm = (form) => {
    return {
      id: form['_id']['$oid'],
      name: form.formulaName,
      date: dayjs(form.date['$date']).format('DD/MM/YYYY'),
      desiredEffects: form.questions
        .find((question) => question.questionId === 13)
        .questionAnswer.replaceAll('|', ', '),
      favorite: form.is_favorite,
    };
  };

  const fetchForms = async (limit = 0, favorite = false) => {
    setLoading(true);
    const response = await fetchFromBackend(
      '/api/customers/formulas/' + (favorite ? 'favorite' : `?limit=${limit}`),
      'GET',
      {}
    ).catch((error) => {
      logger.error('Failed to load formulas\n', error);
    });
    if (response?.response_code === 0) {
      if (favorite) {
        setFavoriteForms(response?.content?.map((form) => parseForm(form)) || []);
      } else if (limit !== 0) {
        setRecentForms(response?.content?.map((form) => parseForm(form)));
      } else {
        setAllForms(response?.content?.map((form) => parseForm(form)));
      }
    }
    setLoading(false);
  };

  const updateFavoriteForms = async (addToFavorites, removeFromFavorites) => {
    const body = {
      add_to_favorite: addToFavorites,
      remove_from_favorite: removeFromFavorites,
    };
    await fetchFromBackend('/api/customers/formulas/set_favorite', 'POST', body).catch((error) => {
      logger.error('Failed to set formula as favorite\n', error, '\nBody of the Request:', body);
    });
  };

  const onClickTab = async (index) => {
    setActiveTab(index);
    if (addToFavorites.length !== 0 || removeFromFavorites.length !== 0) {
      setFavoriteForms();
      setRecentForms();
      setAllForms();
      setAddToFavorites([]);
      setRemoveFromFavorites([]);
      await updateFavoriteForms(addToFavorites, removeFromFavorites);
    }
    if (index === 0) fetchForms(10);
    if (index === 1) fetchForms(0, true);
    if (index === 2) fetchForms(0);
  };

  const onClickFavorite = (formId, favorite) => {
    const tempAddFav = addToFavorites;
    const tempRemoveFav = removeFromFavorites;
    if (favorite) {
      tempAddFav.push(formId);
      const removeIndex = tempRemoveFav.indexOf(formId);
      if (removeIndex > -1) tempRemoveFav.splice(removeIndex, 1);
    } else {
      const addIndex = tempAddFav.indexOf(formId);
      if (addIndex > -1) {
        tempAddFav.splice(addIndex, 1);
      } else {
        tempRemoveFav.push(formId);
      }
    }
    setAddToFavorites(tempAddFav);
    setRemoveFromFavorites(tempRemoveFav);
  };

  const onClickNewFormula = () => {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set('callback', `${window.location.origin}/formulas/details/{{formId}}`)
    window.location.replace(process.env.REACT_APP_FORM_URL + '?' + urlParams.toString())
  };

  useEffect(() => {
    fetchForms(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      activeTab={activeTab}
      onClickTab={onClickTab}
      onClickFavorite={onClickFavorite}
      onClickNewFormula={onClickNewFormula}
      recentForms={recentForms}
      favoriteForms={favoriteForms}
      allForms={allForms}
      redirectToDetails={goToDetailsPage}
      redirectToRebuy={redirectToRebuy}
      loading={loading}
    />
  );
};

export default MyFormulas;
