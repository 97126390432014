import styled from "styled-components";

import { defaultTheme as Theme } from "../../theme";
import { Span } from '../Span';
import { Input } from '../Input'



interface IFormInputProps {
  error: boolean;
}

export const SFormInput = styled(Input) <IFormInputProps>`
  border: 0;
  padding: 7px 25px 7px 12px;
`;

export const SSpan = styled(Span)`
  color: ${Theme?.color?.error};
`;

export const SContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
`;



export const ContainerInput = styled.div <IFormInputProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Theme?.color?.base?.i1000}
  ${props => props.error && `
    border: 1px solid ${Theme?.color?.error};
    box-shadow: 0 4px 4px ${Theme?.color?.error}80;
  `}

  svg {
    position: absolute;
    right: 8px;
  }
  `;
