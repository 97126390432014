import logger from '../utils/logger';

// EVENTS
const viewProducts = (
  cartProducts
) => {
  const products = cartProducts.map((product) => ({
    id: product.item_id,
    name: product.item_name,
    price: product.price,
    quantity: product.quantity,
  }));

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_products',
    products: products,
  });
};

const addToCart = (
  cartProducts,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    event_id: `add_to_cart-${cartToken}`,
    ecommerce: {
      items: cartProducts,
    },
  });
  viewProducts(cartProducts);
};

const removeFromCart = (
  cartProducts,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'remove_from_cart',
    event_id: `remove_from_cart-${cartToken}`,
    ecommerce: {
      items: cartProducts,
    },
  });
};

const beginCheckout = (
  checkoutProducts,
  value,
  coupon,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    event_id: `begin_checkout-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      items: checkoutProducts,
    },
  });
};

const addShippingInfo = (
  checkoutProducts,
  value,
  coupon,
  shippingTier,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_shipping_info',
    event_id: `add_shipping_info-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      shipping_tier: shippingTier,
      items: checkoutProducts,
    },
  });
};

const addPaymentInfo = (
  checkoutProducts,
  value,
  coupon,
  paymentMethod,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_payment_info',
    event_id: `add_payment_info-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      payment_type: paymentMethod,
      items: checkoutProducts,
    },
  });
};

const purchase = (
  purchaseProducts,
  purchaseInfo,
  cartToken
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    event_id: `purchase-${cartToken}`,
    ecommerce: {
      transaction_id: purchaseInfo.transaction_id,
      value: purchaseInfo.value,
      shipping: purchaseInfo.shipping,
      coupon: purchaseInfo.coupon,
      currency: 'BRL',
      items: purchaseProducts,
    },
  });
};

const purchaseUA = (
  purchaseProducts,
  purchaseInfo
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase-UA',
    ecommerce: {
      currencyCode: 'BRL',
      purchase: {
        actionField: {
          id: purchaseInfo.transaction_id,
          revenue: purchaseInfo.value,
          shipping: purchaseInfo.shipping,
          coupon: purchaseInfo.coupon,
        },
        products: purchaseProducts.map((i) => {
          return {
            name: i.item_name,
            id: i.item_id,
            price: i.price,
            quantity: i.quantity,
          };
        }),
      },
    },
  });
};

// Universal Analytics transaction event
const transaction = (
  transactionInfo
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'transaction',
    transactionId: transactionInfo.transaction_id,
    transactionTotal: transactionInfo.value,
    transactionShipping: transactionInfo.shipping,
  });
};

const purchaseCustom = (
  paymentMethod,
  purchaseProducts,
  purchaseInfo,
  cartToken
) => {
  if (paymentMethod !== 'pix' && paymentMethod !== 'credit_card') {
    logger.silentError(`Wrong payment method alias ${paymentMethod} for custom purchase event`);
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: `purchase-${paymentMethod}`,
    event_id: `purchase_${paymentMethod}-${cartToken}`,
    ecommerce: {
      transaction_id: purchaseInfo.transaction_id,
      value: purchaseInfo.value,
      shipping: purchaseInfo.shipping,
      coupon: purchaseInfo.coupon,
      currency: 'BRL',
      items: purchaseProducts,
    },
  });
};

export const formatCartForDataLayer = (cart) => {
  let dataLayerCart = {
    cartToken: cart.token,
    value: cart.finalValue,
    products: [],
  };
  cart.products.forEach((form) => {
    form.items.forEach((item) => {
      const index = dataLayerCart.products.findIndex(
        (dataLayerItem) => dataLayerItem.item_id?.toString() === item.id?.toString()
      );
      if (index !== -1) {
        dataLayerCart.products[index].quantity += item.quantity;
      } else {
        dataLayerCart.products.push({
          item_id: item.id || item.product_id,
          item_name: item.name || item.product_name,
          price: item.value / 100,
          quantity: item.quantity,
        });
      }
    });
  });

  if (cart?.voucher) {
    dataLayerCart.coupon = cart.voucher.code;
  }

  return dataLayerCart;
};

export {
  addToCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  addShippingInfo,
  purchase,
  purchaseUA,
  purchaseCustom,
  transaction
};
