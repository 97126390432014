import styled from "styled-components";
import { defaultTheme } from "../../theme/defaultTheme";
import { ITagProps } from './Tag.i'

export const STag = styled.div<ITagProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${defaultTheme?.config?.sizes?.xsm || '0.5 rem'};
  white-space: nowrap;
  font-size: ${defaultTheme?.config?.sizes?.md || '0.5 rem'};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-weight: bold;
  `
