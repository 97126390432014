import { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from './Layout';
import { useForm, useFetch } from '../../hooks';
import { cepSchema, streetSchema, numberSchema, complementSchema, neighborhoodSchema } from '../../utils/validator';
import { cepMask } from '../../utils/formater';
import { getAddress } from './addAddressHelper';
import notify, {
  ADD_ADDRESS_SUCCESS,
  REPEATED_SLUG_ADDRESS,
  UPDATE_ADDRESS_SUCCESS,
  INVALID_ADDRESS_ZIPCODE,
} from '../../utils/notify';
import logger from '../../utils/logger';
import logResponseError from '../../utils/loggerMessages';

import { CheckoutContext } from '../../contexts/CheckoutSession/CheckoutSession';

const AddAddress = ({ edit }) => {
  let history = useHistory();
  let { addressSlug } = useParams();
  const { fetchFromBackend } = useFetch();
  const { cart, updateCart } = useContext(CheckoutContext);
  const [isSavingInfo, setIsSavingInfo] = useState(false);
  const [fieldsReadOnly, setFieldsReadOnly] = useState({
    city: false,
    neighborhood: false,
    state: false,
    street: false,
  });

  const {
    fields,
    handleChange,
    validateField,
    handleFinish,
    setFieldValue,
    setInitialValues,
    anyChanged,
  } = useForm({
    alias: {
      required: true,
    },
    zip_code: {
      required: true,
      schema: cepSchema,
      mask: cepMask,
    },
    state: {
      required: true,
    },
    city: {
      required: true,
    },
    street: {
      required: true,
      schema: streetSchema,
    },
    number: {
      required: true,
      schema: numberSchema,
    },
    neighborhood: {
      required: true,
      schema: neighborhoodSchema,
    },
    complement: {
      required: false,
      schema: complementSchema,
    },
    reference: {
      required: false,
    },
  });

  const ufData = [
    { value: '', label: 'Selecione o estado' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo	' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'PR', label: 'Paraná' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'TO', label: 'Tocantins' },
  ];

  // Load address data if in edit page
  useEffect(() => {
    if (edit) {
      fetchAddressData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToCorreiosPage = () => {
    window.open(process.env.REACT_APP_URL_CORREIOS_PAGE);
  }

  const fetchAddressData = async () => {
    try {
      const res = await fetchFromBackend(
        `/api/customers/profile/addresses/${addressSlug}`,
        'GET',
        true
      );

      Object.keys(fields).forEach((fieldName) => setFieldValue(fieldName, res.content[fieldName]));

      setInitialValues(res.content);
    } catch (err) {
      logger.error('Failed to load adresses\n', err, '\n', err.responseJson || null);
    }
  };

  const updateAddress = async (body) => {
    return await fetchFromBackend(`/api/customers/profile/addresses/${addressSlug}`, 'PUT', body);
  };

  const addAddress = async (body) => {
    return await fetchFromBackend(`/api/customers/profile/addresses`, 'POST', body);
  };

  const validateZipcode = async (zipcode) => {
    let validZipcode = false;
    const url = `/api/purchase/payment/check_zipcode/${zipcode}`;

    try {
      const res = await fetchFromBackend(url, 'GET', {});
      if (Number(res?.response_code) === 0) {
        validZipcode = res?.content?.valid;
      }
    } catch (err) {
      logResponseError('validate zipcode on add address page', url, 'PATCH', err, zipcode);
    }

    return validZipcode;
  };

  const clearAddressFields = () => {
    Object.keys(fieldsReadOnly).forEach((fieldName) => {
      setFieldValue(fieldName, false)
    });
  }

  const handleChangeZipCode = async (event) => {
    await validateField(event)
    const zipcode = event.target.value?.replace(/[^0-9]/g, '');

    if (zipcode === '') return clearAddressFields();
    if (zipcode.length < 6) return clearAddressFields();

    const { city, neighborhood, state, street } = await getAddress(zipcode);
    const autoCompleteAddress = { city, neighborhood, state, street };

    setFieldsReadOnly({
      city: Boolean(city),
      state: Boolean(state),
      street: Boolean(street),
      neighborhood: Boolean(neighborhood),
    });

    if (!(await validateZipcode(zipcode)) && anyChanged) {
      return notify(INVALID_ADDRESS_ZIPCODE, 'warn');
    }

    Object.keys(autoCompleteAddress).forEach((fieldName) => setFieldValue(fieldName, autoCompleteAddress[fieldName]));
  };

  const handleSubmission = async () => {
    setIsSavingInfo(true)
    const { valid } = await handleFinish();
    const zipcode = fields.zip_code.value?.replace(/[^0-9]/g, '');
    if (valid) {
      if (!edit || (edit && anyChanged)) {
        if (await validateZipcode(zipcode)) {
          let body = {};
          Object.keys(fields).forEach(
            (fieldName) =>
            (body = {
              ...body,
              [fieldName]: fields[fieldName].value ? fields[fieldName].value : undefined,
            })
          );
          body = {
            ...body,
            state: body.state?.toUpperCase(),
          };
          try {
            //save alias to pre select address on checkout page
            if (cart?.token) {
              updateCart({
                ...cart,
                addressInfo: {
                  slug: body.alias,
                },
              });
            }
            if (edit) {
              const res = await updateAddress(body);
              if (res.response_code === 0) {
                setInitialValues(body);
                notify(UPDATE_ADDRESS_SUCCESS, 'success');
                history.goBack();
              } else if (res.response_code === 2) {
                notify(REPEATED_SLUG_ADDRESS, 'warn');
              } else {
                const url = `/api/customers/profile/addresses/${addressSlug}`;
                logResponseError('update address', url, 'PUT', res, false, body);
              }
            } else {
              const res = await addAddress(body);
              if (res.response_code === 1) {
                notify(ADD_ADDRESS_SUCCESS, 'success');
                history.goBack();
              } else if (res.response_code === 2) {
                notify(REPEATED_SLUG_ADDRESS, 'warn');
              } else {
                const url = '/api/customers/profile/addresses';
                logResponseError('add address', url, 'POST', res, false, body);
              }
            }
          } catch (err) {
            setIsSavingInfo(false)
            logger.error('Failed to update/add address\n', err, '\nBody of the Request:', body);
          }
        } else {
          notify(INVALID_ADDRESS_ZIPCODE, 'warn');
        }
      }
    }
    setIsSavingInfo(false)
  };

  return (
    <Layout
      fields={fields}
      isSavingInfo={isSavingInfo}
      handleChange={handleChange}
      validateField={validateField}
      goBackCallback={() => {
        history.goBack();
      }}
      edit={edit}
      handleSubmission={handleSubmission}
      anyChanged={anyChanged}
      handleChangeZipCode={handleChangeZipCode}
      redirectToCorreiosPage={redirectToCorreiosPage}
      ufData={ufData}
      fieldsReadOnly={fieldsReadOnly}
    />
  );
};
export default AddAddress;
