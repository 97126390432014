import { Text } from "../Text";
import formatPrice from "../../utils/formatPrice";

export const SummaryDescription = ({ text, price, isVoucher,freeShipping }) => {
  return (
    <>
      <Text boldWeight="600">{text}</Text>
      <Text align="right" boldWeight="600" highlight={isVoucher}>
        {freeShipping ? 'Grátis' : !isNaN(price) && formatPrice(isVoucher ? price * -1 : price)}
      </Text>
    </>
  );
};
