import styled from 'styled-components';
import { ClientAreaContainer as UIClientAreaContainer } from '../../../components/ClientAreaContainer';

import { defaultTheme } from '../../../lib/jfy-ui';
import { Text } from '../../../components/Text';
import { Select } from '../../../components/Select';
import { Input as inputUI } from '../../../components/Input';

import { TrashIcon as TrashSVG, PensilIcon as PensilSVG } from '../../../lib/jfy-icons';

import { ResponsiveControl, Button as CButton, Input as CInput } from '../../../lib/jfy-ui';

export const ClientAreaContainer = styled(UIClientAreaContainer)`
  padding-bottom: 1rem;
`;

export const Input = styled(inputUI)``;

export const ContentProducts = styled.div`
  @media ${ResponsiveControl.tablet('min')} {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem;
  }
`;

export const CartContainer = styled.div`
  @media ${ResponsiveControl.tablet('min')} {
    margin: 1rem;
    border-left: 1px solid ${(props) => props.theme.color.base.i500};
    border-right: 1px solid ${(props) => props.theme.color.base.i500};
  }
`;

export const Formula = styled.header`
  font-size: 0.875rem;
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
`;

export const Divider = styled.div`
  width: 100%;

  ${(props) =>
    props.generateLine ? `border-bottom: 1px solid ${props.theme.color.base.i800};` : ''};

  @media ${ResponsiveControl.tablet('min')} {
    ${(props) => !props.ignoreMargin && 'margin: 0.6rem 0rem'};
  }
  @media ${ResponsiveControl.tablet('max')} {
    ${(props) => !props.ignoreMargin && 'margin: 0.3rem 0rem'};
  }
`;

export const ContentAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const AddressInfo = styled.div`
  display: grid;
  gap: 1;
  min-height: 10vh;
  width: 100%;
`;
export const Right = styled.div`
  min-height: 10vh;
  align-items: end;
  display: flex;
`;

export const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

export const CustomSelect = styled(Select)`
  @media ${ResponsiveControl.laptop('max')} {
    max-width: 20rem;
  }
`;

export const ContentPayment = styled.div`
  display: grid;
  gap: 1rem;
`;

export const PaymentControl = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  @media ${ResponsiveControl.tablet('max')} {
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${ResponsiveControl.tablet('min')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const PaymentDescriptive = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const VoucherContainer = styled.div`
  padding: 18px;
  border-bottom: 1px solid ${(props) => props.theme.color.base.i1000};
`;

export const VoucherContent = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 9px;

  button {
    min-height: 2.2rem;
    height: 2.2rem;
    width: 40%;
    min-width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;

    @media ${ResponsiveControl.tablet('min')} {
      width: 23%;
      min-width: 23%;
    }
  }
`;

export const Button = styled(CButton)`
  font-size: ${(props) => props.theme.config.sizes.xsm};
  ${(props) =>
    props.isLoading &&
    `
    height: 2.75rem;
    padding: 0;
    margin: 0;
  `}

  ul li {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media ${ResponsiveControl.tablet('max')} {
    ul li {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const Cvv = styled(CInput)`
  @media ${ResponsiveControl.tablet('min')} {
    width: 6rem;
  }
  @media ${ResponsiveControl.tablet('max')} {
    width: 4rem;
  }
`;

export const SummaryContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  ${(props) => props.margin && `margin-top: 0.875rem;`}
`;

export const SubTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;

  @media ${ResponsiveControl.tablet('min')} {
    justify-content: end;
    span {
      margin-right: 0.5rem;
    }
  }
`;

export const HeaderDisplay = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  @media ${ResponsiveControl.tablet('max')} {
    border-bottom: 0.5px solid ${(props) => props.theme.color.base.i500};
  }
  @media ${ResponsiveControl.tablet('min')} {
    border-bottom: 2px solid ${(props) => props.theme.color.base.i1000};
  }
`;

export const TextDisplay = styled(Text)`
  margin: 0;
  font-size: ${(props) => props.theme.config.sizes.sm};
`;

export const TrashIcon = styled(TrashSVG)`
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.12rem;
  border: 0.5px solid ${(props) => props.theme.color.base.i1000};
`;

export const PensilIcon = styled(PensilSVG).attrs({
  fill: defaultTheme.color.base.i700,
})`
  padding: 0.22rem;
  fill: red;
  width: 0.9rem;
  height: 0.9rem;

  border: 0.5px solid ${(props) => props.theme.color.base.i1000};
  cursor: pointer;
`;

export const ContainerButton = styled.div`
  margin-top: 0.75rem;

  @media ${ResponsiveControl.laptop('min')} {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      min-width: 50%;
    }
  }
`;

export const NoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
`;

export const ContactContent = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  @media ${ResponsiveControl.tablet('max')} {
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${ResponsiveControl.tablet('min')} {
    grid-template-columns: repeat(2, 1fr);
  }
 `;

export const MessageModal = styled.p`
  font-size: 14px;
`;
