export const formatObjectToPatternProducts = (cart) => {
    let totalValue = 0;
    const products = cart.products.map((form) => {
        const formId = form.formId || form.form_id || null;
        const formName = form.formName || form.form_name || null;
        const items = form.items.map((item) => {
            if ((item.value || item.prd_value) > 0) {
                totalValue += (item.value || item.prd_value) * item.quantity;
            }
            return {
                id: item.id || item.prd_id,
                sku: item.sku || item.prd_sku,
                name: item.name || item.prd_name,
                displayName: item.display_name || item.prd_dspnam || item.displayName,
                description: item.description || item.prd_dscrp,
                value: item.value || item.prd_value,
                custom: item.custom || item.prd_custom,
                brand: item.brand || item.prd_brand,
                image: item.image || item.prd_image,
                shelf: item.shelf || item.prd_shelf,
                quantity: item.quantity,
                benefit: item.benefit || item.prd_benefit,
                size: item.size || item.prd_szdisp,
                weight: item.weight || item.prd_weight || 0
            }
        });
        return {
            formId,
            formName,
            items
        }
    });

    return { products, totalValue };
}


export const formatObjectToPatternVoucher = (cart) => {
    let voucher = null;
    if (cart?.voucher) {
        let benefits = [];
        if (cart.voucher.benefits?.length > 0) {
            benefits = [...cart.voucher.benefits];
        } else {
            benefits = cart.voucher.product_id ? [cart.voucher.product_id] : [];
        }
        let discountValue = cart.voucher.discountValue || cart.voucher.discount_value;
        if (!discountValue && cart.voucher.discountValue === 0) discountValue = 0;
        voucher = {
            id: cart.voucher.id,
            code: cart.voucher.code,
            discountValue,
            percent: cart.voucher.percent || cart.voucher.is_percent,
            freeShipping: cart.voucher.freeShipping || cart.voucher.free_shipment,
            benefits
        }
    }
    return { voucher };
}


export const formatObjectToPatternShipping = (cart) => {
    let shipping = null;
    if (cart?.shipping) {
        shipping = {
            v_carrier_alias: cart.shipping.v_carrier_alias,
            v_carrier_id: cart.shipping.v_carrier_id,
            v_expday: cart.shipping.v_expday,
            v_valfee: cart.shipping.v_valfee || 0,
        };
    }
    return { shipping }
}

export const formatObjectToPatternInstallment = (cart) => {
    let installment = null;
    if (cart?.installment) {
        installment = {
            value: cart.installment.value,
            label: cart.installment.label
        };
    }
    return { installment }
}

export const formatObjectToPatternAddressInfo = (cart) => {
    let addressInfo;

    if (cart?.addressInfo) {
        addressInfo = {
            customer_id: cart.addressInfo.customerAddressId || cart.addressInfo.customer_id,
            customer_address: cart.addressInfo.street || cart.addressInfo.customer_address,
            customer_state: cart.addressInfo.state || cart.addressInfo.customer_state,
            customer_district: cart.addressInfo.neighborhood || cart.addressInfo.customer_district,
            customer_number: cart.addressInfo.number || cart.addressInfo.customer_number,
            customer_city: cart.addressInfo.city || cart.addressInfo.customer_city,
            customer_zip: cart.addressInfo.zip_code || cart.addressInfo.customer_zip,
            customer_reference: cart.addressInfo.reference || cart.addressInfo.customer_reference,
            customer_complement: cart.addressInfo.complement || cart.addressInfo.customer_complement,
            slug: cart.addressInfo.slug
        }
    }
    return { addressInfo }
}

export const formatObjectToPatternPaymentInfo = (cart) => {
    let paymentInfo;
    if (cart?.paymentInfo) {
        paymentInfo = {
            cardInfo: {
                card_owner: cart.paymentInfo.cardInfo?.card_owner,
                expire_date: cart.paymentInfo.cardInfo?.expire_date,
                last4_number_card: cart.paymentInfo.cardInfo?.last4_number_card,
                card_flag: cart.paymentInfo.cardInfo?.card_flag,
                card: cart.paymentInfo.cardInfo?.card,
                id_card: cart.paymentInfo.cardInfo?.id_card,
                id: cart.paymentInfo.cardInfo?.id,
                customer_id: cart.paymentInfo.cardInfo?.customer_id,
                cvv: cart.paymentInfo.cardInfo?.cvv || '',
                number_card: cart.paymentInfo.cardInfo?.number_card,
                credit_card_hash: cart.paymentInfo.cardInfo?.credit_card_hash,
                customer_cpf: cart.paymentInfo.cardInfo?.customer_cpf,
                birth_date: cart.paymentInfo.cardInfo?.birth_date,
                save_card: cart.paymentInfo.cardInfo?.save_card || false
            },
            payment_method: cart.paymentInfo.cardInfo?.payment_method || cart.paymentInfo?.payment_method,
        };
    }
    return { paymentInfo }
}

export const formatObjectToPatternCart = ({ referenceId, referenceTypeId, gateway_alias, cartToken, products, paymentInfo, totalValue, voucher, addressInfo, installment, shipping, finalValue }) => {
    return {
        referenceId: referenceId, referenceTypeId: referenceTypeId, gateway_alias: gateway_alias, token: cartToken, products: products, paymentInfo: paymentInfo, totalValue: totalValue, voucher: voucher, addressInfo: addressInfo, installment: installment, shipping: shipping, finalValue: finalValue
    }
}
