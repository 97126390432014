import styled from 'styled-components';
import { Button as ButtonUI } from '../../../lib/jfy-ui'

export const Button = styled(ButtonUI)`
  font-size: 0.82rem;
  padding: 0px 7.5px 0px 7.5px;
`;

export const NewFormulaContainer = styled.div`
height: 2.35rem;
`
