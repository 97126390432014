import { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as dataLayerHandler from '../../analytics/dataLayerHandler';
import { sendPurchaseEvent } from '../../analytics/events';

import { Layout } from './Layout';
import dayjs from 'dayjs';
import logger from '../../utils/logger';
import { useFetch } from '../../hooks';

const timeBetweenRequests = [10, 15, 30, 60, 120];

const AwaitPayment = () => {
  const { orderId } = useParams();
  const orderIdParam = orderId?.replace(/[^0-9]/g, '');
  const { pix_data } = useLocation();
  const history = useHistory();
  const pixCode = useRef(pix_data?.pix_expire_date);
  const [countdown, setCountdown] = useState();
  const [cartInfo, setCartInfo] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const requestTimeIndex = useRef(0);
  const { fetchFromBackend } = useFetch();

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    setIsFetching(true);
    if (orderIdParam) {
      fetchFromBackend('/api/purchase/orders/' + orderIdParam, 'GET', {})
        .then((orderResponse) => {
          fetchFromBackend('/api/purchase/carts/' + orderResponse.content.cart_id, 'GET', {})
            .then((cartResponse) => {
              pixCode.current = orderResponse.content.pix_code;
              setCountdown(dayjs(orderResponse.content.pix_expiration).diff(dayjs(), 'seconds'));

              setCartInfo(cartResponse.content.result);
              setIsFetching(false);
            })
            .catch((error) => {
              logger.error(
                'Failed to get cart info - pix status\n',
                error,
                '\n',
                error?.content?.responseJson || null
              );
            });
        })
        .catch((error) => {
          logger.error(
            'Failed to get order info\n',
            error,
            '\n',
            error?.content?.responseJson || null
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderIdParam]);

  const checkPaymentStatus = async () => {
    await fetchFromBackend('/api/purchase/payment/pix/status/' + orderIdParam, 'GET', {})
      .then((response) => {
        if (response.response_code === 0) {
          if (response.content?.toLowerCase() === 'paid') {
            dispatchPurchaseEventDatalayer();
            history.push({
              pathname: '/checkout/order/' + orderIdParam + '/feedback/confirmed',
              search: window.location.search,
            });
          }

          if (requestTimeIndex.current < timeBetweenRequests.length) {
            requestTimeIndex.current = requestTimeIndex.current - 1;
          }
        }
      })
      .catch((error) => {
        if (requestTimeIndex.current < timeBetweenRequests.length) {
          requestTimeIndex.current = requestTimeIndex.current + 1;
        }
        logger.silentError(
          'Failed to get pix status\n',
          error,
          '\n',
          error?.content?.responseJson || null
        );
      });
  };

  useEffect(() => {
    if (countdown === undefined) return;
    if (countdown > 0) {
      setTimeout(() => {
        if (countdown % timeBetweenRequests[requestTimeIndex.current] === 0) {
          checkPaymentStatus();
        }
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      history.push({
        pathname: '/checkout/order/' + orderIdParam + '/feedback/expired',
        search: window.location.search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const dispatchPurchaseEventDatalayer = () => {
    //format object to dataLayer
    const objFormateDataLayerCart = dataLayerHandler.formatCartForDataLayer(cartInfo);

    sendPurchaseEvent(objFormateDataLayerCart?.products, orderIdParam, cartInfo, 'pix');
  };

  return (
    <Layout
      codeQr={pixCode.current}
      countdownDesablePix={countdown}
      purchaseAmount={cartInfo?.cart?.final_value}
      isFetching={isFetching}
    />
  );
};

export { AwaitPayment };
