import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import Button from '../../../components/Button';
import {ReactComponent as FlaskIcon} from '../../../assets/flask.svg';
import {ReactComponent as HairIcon} from '../../../assets/hair.svg';
import {ReactComponent as ShowerIcon} from '../../../assets/shower.svg';
import {ReactComponent as DryerIcon} from '../../../assets/dryer.svg';
import {ReactComponent as CoinIcon} from '../../../assets/coin.svg';
import {ReactComponent as SiliconeIcon} from '../../../assets/silicone.svg';
import {ReactComponent as ShieldIcon} from '../../../assets/shields.svg';

import styles from './Guide.module.css';

const Layout = ({ onClickPrimary, onClickHelp }) => {
  return (
    <ClientAreaContainer headerTitle="ORIENTAÇÕES DE USO" headerClassName={styles.header}>
      <Block className={styles.blocks}>
        <p>
          <span>
            Unimos tecnologia e natureza para formular produtos de hair care específicos para você.
            E, para que tenha os resultados desejados, sugerimos que siga as orientações de uso a
            seguir:
          </span>
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <FlaskIcon />
        </div>
        <p>
          O <span>SHAMPOO</span> deve ser aplicado <span>apenas na raiz do cabelo.</span> Criamos
          uma <span>espuma natural</span>, que limpa de forma saudável, pois não contém sulfatos
          (responsáveis por uma limpeza agressiva). <span>Não exagere na aplicação</span>, nosso
          shampoo limpa mesmo fazendo menos espuma!
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <HairIcon />
        </div>
        <p>
          Aplique o <span>CONDICIONADOR</span> no <span>comprimento e pontas</span>, evitando a
          raiz. Deixe-o agir de <span>5 a 10 minutos</span>. Ao enxaguar, não deixe "um restinho",
          pois seus fios podem ficar pesados.
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <ShowerIcon />
        </div>
        <p>
          A <span>MÁSCARA HIDRATANTE</span> pode ser usada{' '}
          <span>1x por semana, no lugar do condicionador</span>. Aplique massageando os fios, deixe
          agir por 10 minutos e enxágue bem para remover todo o produto.
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <DryerIcon />
        </div>
        <p>
          O <span>LEAVE-IN</span> pode ser aplicado nos <span>fios úmidos ou secos</span>, sempre
          que houver necessidade. Possui proteção térmica, ideal para ser usado antes da finalização
          com secador ou chapinha.
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <CoinIcon />
        </div>
        <p>
          Qual a quantidade certa? Sugerimos que inicie com uma{' '}
          <span>porção do tamanho da moeda de 1 Real</span>. Caso não sinta resultados, aumente ou
          reduza a quantidade aos poucos, até{' '}
          <span>descobrir a medida ideal para o seu cabelo</span>.
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <SiliconeIcon />
        </div>
        <p>
          <span>Evite o uso simultâneo de produtos</span> com silicones insolúveis (ex: reparadores
          de pontas), eles podem interferir na ação da sua fórmula.
        </p>
      </Block>
      <Block className={styles.blocks}>
        <div className={styles.icon}>
          <ShieldIcon />
        </div>
        <div>
          <p>
            <span>SATISFAÇÃO GARANTIDA</span>
            <br></br>
            <br></br>
            Nosso maior compromisso é potencializar a beleza do seu cabelo, respeitando a sua individualidade.
            <br></br>
            <br></br>
            Ao mudar os produtos que você usa na rotina com o seu cabelo para opções mais naturais e sem químicos agressivos, <span>é normal que os fios precisem de um período de adaptação</span> para este novo cuidado.
            <br></br>
            <br></br>
            Se em 15 dias do recebimento do produto você não tiver experimentado resultados no seu cabelo, <span>é possível solicitar a revisão da sua fórmula, sem nenhum custo adicional.</span> Este é o nosso compromisso com você!
            <br></br>
            <br></br>
          </p>
          <Button tertiary text="Falar com especialista" onClick={onClickHelp} />
        </div>
      </Block>
      <Button
        primary
        text="Acessar Área do Cliente"
        className={styles.button}
        onClick={onClickPrimary}
      />
    </ClientAreaContainer>
  );
};

export default Layout;
