import { useState } from 'react';
import Layout from './Layout';
import { useHistory } from 'react-router-dom';
import { emailSchema } from '../../utils/validator';
import { useForm, useFetch } from '../../hooks';
import logger from '../../utils/logger';
import logResponseError from '../../utils/loggerMessages';
import notify, { BAD_EMAIL_FIELD } from '../../utils/notify';

export const ForgotPassword = () => {
  const { handleChange, validateField, fields, setFieldValue, handleFinish } = useForm({
    email: {
      required: true,
      schema: emailSchema,
    },
  });

  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fetchFromBackend } = useFetch();

  const sendEmail = async () => {
    setLoading(true);
    const { valid } = await handleFinish();

    if (valid) {
      const body = {
        email: fields.email.value,
      };
      const url = '/api/customers/password_reset/';
      const method = 'POST';
      const response = await fetchFromBackend(url, method, body).catch((error) => {
        logger.error(
          'Failed to request a reset password email (internal error)\n',
          error,
          '\nBody of the Request:',
          body
        );
      });
      if (response.response_code === 0) {
        setEmailSent(!emailSent);
      } else if (response.response_code === 4 && response.message?.email) {
        notify(BAD_EMAIL_FIELD, 'warn');
      } else {
        logResponseError('request a reset password email', url, method, response, false, body);
      }
    }
    setLoading(false);
  };

  const onClickHelp = () => {};

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <Layout
      onGoBack={onGoBack}
      handleChange={handleChange}
      validateField={validateField}
      fields={fields}
      setFieldValue={setFieldValue}
      emailSent={emailSent}
      invalidEmail={false}
      onClickSendEmail={sendEmail}
      onClickHelp={onClickHelp}
      loading={loading}
    />
  );
};

export default ForgotPassword;
