import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import InfoIcon from '../../../assets/customIcons/InfoIcon';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Select } from '../../../components/Select'

import styles from './AddAddress.module.css';

const Layout = ({
  goBackCallback,
  edit,
  fields,
  handleChange,
  validateField,
  handleSubmission,
  anyChanged,
  handleChangeZipCode,
  redirectToCorreiosPage,
  isSavingInfo,
  ufData,
  fieldsReadOnly
}) => {
  return (
    <ClientAreaContainer
      headerTitle={edit ? 'Seu Endereço' : 'Adicionar Endereço'}
      onGoBack={goBackCallback}
      className={styles.rootStyle}
      headerClassName={styles.header}
      blocksClassName={styles.blocksStyle}
    >
      <Block title="Insira as informações abaixo">
        <div className={styles.formRootStyle}>
          <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>
            Identificação do endereço
          </span>
          <Input
            placeholder={edit ? '' : 'Exemplo: Trabalho'}
            className={styles.defaultFormMargin}
            name="alias"
            value={fields.alias?.value}
            valid={!fields.alias?.valid ? fields.alias?.valid : null}
            message={fields.alias?.message}
            onChange={handleChange}
            onBlur={validateField}
            maxLength={50}
          />
          <div className={styles.zipStateRow}>
            <div className={styles.zipBlock}>
              <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`} onClick={redirectToCorreiosPage}>CEP <InfoIcon onClick={redirectToCorreiosPage} className={styles.info} size={{ height: 10, width: 10 }} color="#f4a27e" viewBox="0 0 25 25" /></span>
              <Input
                placeholder={edit ? '' : 'Seu CEP'}
                className={styles.defaultFormMargin}
                name="zip_code"
                type="tel"
                maxLength={9}
                value={fields.zip_code?.value}
                valid={!fields.zip_code?.valid ? fields.zip_code?.valid : null}
                message={fields.zip_code?.message}
                onChange={handleChange}
                onBlur={handleChangeZipCode}
              />
            </div>
            <div className={styles.stateBlock}>
              <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Estado</span>
              <Select
                disabled={fieldsReadOnly.state}
                name="state"
                value={ufData.find((state) => state.value === fields.state?.value)?.value}
                valid={!fields.state?.valid ? fields.state?.valid : null}
                message={fields.state?.message}
                onChange={handleChange}
                onBlur={validateField}
                options={ufData}
                className={styles.select}
              />
            </div>
          </div>

          <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Cidade</span>
          <Input
            disabled={fieldsReadOnly.city}
            placeholder={edit ? '' : 'Nome da cidade'}
            className={styles.defaultFormMargin}
            name="city"
            autoComplete="shipping address-level2"
            value={fields.city?.value}
            message={fields.city?.message}
            valid={!fields.city?.valid ? fields.city?.valid : null}
            maxLength={250}
            onChange={handleChange}
            onBlur={validateField}
          />
          <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Endereço</span>
          <Input
            disabled={fieldsReadOnly.street}
            placeholder={edit ? '' : 'Seu endereço'}
            className={styles.defaultFormMargin}
            autoComplete="shipping address-line1"
            name="street"
            value={fields.street?.value}
            maxLength={50}
            onChange={handleChange}
            message={fields.street?.message}
            valid={!fields.street?.valid ? fields.street?.valid : null}
            onBlur={validateField}
          />

          <div className={styles.numberNeighborRow}>
            <div className={styles.numberBlock}>
              <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Número</span>
              <Input
                placeholder={edit ? '' : 'Ex: 161'}
                className={styles.defaultFormMargin}
                name="number"
                value={fields.number?.value}
                message={fields.number?.message}
                valid={!fields.number?.valid ? fields.number?.valid : null}
                maxLength={10}
                onChange={handleChange}
                onBlur={validateField}
              />
            </div>
            <div className={styles.neighborBlock}>
              <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Bairro</span>
              <Input
                disabled={fieldsReadOnly.neighborhood}
                placeholder={edit ? '' : 'Seu bairro'}
                className={styles.defaultFormMargin}
                name="neighborhood"
                autoComplete="shipping address-level3"
                value={fields.neighborhood?.value}
                message={fields.neighborhood?.message}
                valid={!fields.neighborhood?.valid ? fields.neighborhood?.valid : null}
                maxLength={40}
                onChange={handleChange}
                onBlur={validateField}
              />
            </div>
          </div>
          <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>Complemento</span>
          <Input
            placeholder={edit ? '' : 'Nº de Apto ou Casa, etc.'}
            className={styles.defaultFormMargin}
            name="complement"
            autoComplete="shipping address-line2"
            value={fields.complement?.value}
            message={fields.complement?.message}
            valid={!fields.complement?.valid ? fields.complement?.valid : null}
            maxLength={30}
            onChange={handleChange}
          />
          <span className={`${styles.inputLabel} ${styles.defaultFormMargin}`}>
            Pto. de referência
          </span>
          <Input
            placeholder={edit ? '' : 'Um shopping, um mercado etc. (opcional)'}
            className={styles.defaultFormMargin}
            name="reference"
            autoComplete="shipping address-line3"
            value={fields.reference?.value}
            message={fields.reference?.message}
            valid={!fields.reference?.valid ? fields.reference?.valid : null}
            maxLength={350}
            onChange={handleChange}
          />
          <div className={styles.buttonContainer}>
            <Button
              primary
              text={edit ? 'Atualizar endereço' : 'Adicionar Endereço'}
              className={styles.buttonStyle}
              onClick={handleSubmission}
              disabled={edit && !anyChanged}
              loading={isSavingInfo}
            />
          </div>
        </div>
      </Block>
    </ClientAreaContainer >
  );
};
export default Layout;
