export const INTERNAL_ERROR = 'Ops, algo deu errado. Por favor, tente mais tarde';
export const LOGIN_FAILED = 'Não foi possível fazer o login com as credenciais informadas';
export const WRONG_CURR_PW_ON_CHANGE = 'Senha atual incorreta';
export const SUCCESS_PW_CHANGE = 'Senha alterada com sucesso';
export const WRONG_OR_MISSING_FIELD = 'Campo(s) incorreto(s) ou faltante(s)';
export const DELETE_ADDRESS_SUCCESS = 'Endereço removido com sucesso';
export const UPDATE_ADDRESS_SUCCESS = 'Endereço atualizado com sucesso';
export const INVALID_ADDRESS_ZIPCODE = 'Ops, o CEP informado é inválido ou não foi encontrado. Clique "?" para verificar qual o seu CEP'
export const ADD_ADDRESS_SUCCESS = 'Novo endereço adicionado';
export const ADD_ADDRESS_TO_CHECKOUT_WARN = 'Ops, precisamos de ao menos um endereço cadastrado, por gentileza, adicione um novo endereço';
export const SELECT_ADDRESS_TO_CHECKOUT_WARN = 'Ops, precisamos de ao menos um endereço selecionado, por gentileza, selecione um endereço';
export const SELECT_ADDRESS_INVALID_TO_CHECKOUT_WARN = 'Ops, endereço selecionado é invalido, por gentileza, selecione ou crie um novo';
export const REPEATED_SLUG_ADDRESS = 'Identificação já utilizada, favor alterá-la';
export const CHANGE_ORDER_ADDRESS_SUCCESS = 'Endereço de entrega alterado com sucesso';
export const CHANGE_ORDER_ADDRESS_FAIL = 'Não foi possível alterar o endereço';
export const SERVER_ERROR = 'Erro ao conectar com servidor, tente novamente em instantes';
export const MISSING_FIELDS = 'Por favor, preencha todos os campos';
export const USER_CREATED = 'Usuário criado com sucesso, senha enviada por e-mail';
export const EXISTING_USER = 'Usuário já cadastrado';
export const MISSING_LAST_NAME = 'Por favor, insira ao menos um sobrenome';
export const REORDER_URL_FAIL = 'Erro ao gerar a URL do carrinho, tente novamente em instantes';
export const USER_DATA_UPDATED = 'Dados atualizados com sucesso';
export const WRONG_PASSWORD = 'Senha incorreta';
export const ORDER_DETAILS_FAIL =
  'Erro ao carregar detalhes do pedido, tente novamente em instantes';
export const FORMULA_LOADING_FAIL = 'Erro ao carregar fórmulas, tente novamente em instantes';
export const EMAIL_SUCCESS = 'E-mail enviado com sucesso';
export const EMAIL_FAIL = 'Erro ao enviar e-mail, tente novamente em instantes';
export const EMAIL_EMPTY = 'Por favor adicione uma mensagem';
export const BAD_EMAIL_FIELD =
  'Por favor, insira um email válido (sem acentos ou caracteres especiais)';
export const COMPLEMENT_TOO_LONG = 'Complemento deve ter no máximo 50 caracteres';
export const CARD_REMOVE_SUCCESS = 'Cartão removido com sucesso!';
export const CARD_ADD_SUCCESS = 'Cartão adicionado com sucesso!';
export const CARD_ADD_NEW_INFO_SUCCESS = 'Novas informações atualizadas com sucesso!';
export const CARD_ADD_TO_CHECKOUT_WARN = 'Ops, precisamos de ao menos um cartão cadastrado, por gentileza, adicione um novo cartão';
export const PAYMENT_METHOD_NOT_SELECTED = 'Ops, precisamos que selecione ao menos uma forma de pagamento';
export const CVV_INVALID_WARN = 'Ops, precisamos de um código de segurança válido para o cartão selecionado';
export const INSTALLMENTS_NOT_SELECTED_WARN = 'Ops, parcelamento não selecionado, por gentileza selecione em quantas parcelas deseja pagar a sua compra';
export const NO_PRODUCTS_IN_CART_WARN = 'Ops, para continuarmos é necessário ao menos 1 produto no carrinho';
export const CHARGE_NEW_REBUY = 'Ops, tivemos um erro ao processar a recompra do seu pedido, tente novamente mais tarde'
export const NO_CARD_SELETED = 'Ops, nenhum cartão foi cadastrado, cadastre um novo ou selecione outra forma de pagamento'
export const DELAYED_DELIVERY = 'Olá! Seu pedido sofreu uma pequena alteração na data de entrega. Produzimos sua fórmula com ingredientes naturais e doses extras de cuidado, e precisamos aumentar o tempo de produção devido à alta demanda. Reforçamos aqui nosso comprometimento: a previsão máxima e atualizada para seu pedido chegar até você é a informada abaixo'
export const INVALID_PHONE = 'Ops, número de celular informado inválido ou incompleto'
export const OLD_FORM = 'Ops, esta fórmula é antiga e não podemos utilizá-la, que tal fazer uma nova?'
export const VALIDATE_CARD = 'Erro ao realizar o pagamento, por gentileza, verifique os dados do cartão'
export const VALIDATE_CVV_CARD = 'Erro ao realizar o pagamento, por gentileza, verifique o cvv utilizado'
export const PAYMENT_ERRO = 'Erro ao realizar pagamento com método utilizado, tente novamente em instantes';
export const INCREASE_IN_DEMAND = { bold: 'TUDO OK COM SEU PEDIDO! ', normal: 'Estamos com uma demanda acima do esperado e os pedidos e atendimentos estão demorando mais que o normal. Sua situação será normalizada muito em breve. ', underline: 'Veja mais informações aqui.' };
