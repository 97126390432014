import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import LoadingDots from '../../../components/LoadingDots';
import { CAButton } from './styles'

import styles from './MyAddress.module.css';

const Layout = ({
  addNewAddress,
  editAddressCallback,
  selectedAddress,
  handleAddressPick,
  addressList,
  openConfirm,
  cancelAction,
  confirmAction,
  deleteAddress,
  fetching,
  incomingFromOrder,
  goBack,
  submitAddressChange,
  awaitingChangeResult,
  addressChangeEnabled,
  isCheckout,
  handleChangeAdressToCheckout
}) => {
  return (
    <ClientAreaContainer
      className={styles.rootStyle}
      headerMenu={!incomingFromOrder === !isCheckout}
      initializeMenuClosed={isCheckout}
      headerTitle="Selecionar endereço para entrega"
      headerClassName={styles.header}
      onGoBack={incomingFromOrder || isCheckout ? goBack : false}
    >
      <Modal
        active={openConfirm}
        onCancel={cancelAction}
        onAccept={confirmAction}
        acceptText="Remover"
      >
        <p className={styles.modalContent}>Deseja remover o endereço?</p>
      </Modal>
      <Block className={styles.block}>
        {fetching ? (
          <LoadingDots />
        ) : addressList.length <= 0 ? (
          <h2 className={styles.emptyListText}>Não há endereços cadastrados!</h2>
        ) : (
          <ChoiceGroup
            selected={selectedAddress}
            className={styles.choiceGroup}
            onChange={handleAddressPick}
          >
            {addressList.map((element, index) => {
              return (
                <Choice
                  title={element.alias}
                  value={element.slug}
                  className={styles.card}
                  deleteAction={() => deleteAddress(element.slug)}
                  key={index}
                >
                  <div className={styles.address}>
                    <p>
                      {element.street +
                        ', nº ' +
                        element.number +
                        ' - ' +
                        element.neighborhood +
                        ', ' +
                        element.city +
                        ' - ' +
                        element.state}
                    </p>
                    <p>
                      CEP:
                      <b> {element.zip_code}</b>
                    </p>
                    <Button
                      tertiary
                      text="Editar"
                      onClick={() => {
                        editAddressCallback(element.slug);
                      }}
                    />
                  </div>
                </Choice>
              );
            })}
          </ChoiceGroup>
        )}
      </Block>
      <div className={styles.buttons}>
        {incomingFromOrder ? (
          <div>
            {awaitingChangeResult ? (
              <LoadingDots />
            ) : (
              <Button
                primary
                text="Alterar endereço"
                onClick={submitAddressChange}
                disabled={!addressChangeEnabled}
              />
            )}
            <Button
              secondary
              text="Adicionar novo endereço"
              onClick={addNewAddress}
              disabled={awaitingChangeResult}
            />
          </div>
        ) : (
          <>
            {isCheckout && <CAButton isCheckout={isCheckout} onClick={handleChangeAdressToCheckout} data-gtm="action-checkout-address-continue" variant='solid'>Continuar</CAButton>}
            <CAButton isCheckout={isCheckout} data-gtm="action-address-add-new-adrress" variant={isCheckout ? 'outline' : 'solid'} onClick={addNewAddress} >Adicionar novo endereço</CAButton>
          </>
        )}
      </div>
    </ClientAreaContainer>
  );
};
export default Layout;
