import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { PasswordInput } from '../../../components/Input';
import Button from '../../../components/Button';

import styles from './ResetPasswordPrivate.module.css';

const Layout = ({ handleSubmit, handleChange, validateField, fields }) => {
  return (
    <ClientAreaContainer
      headerTitle="Alteração de senha"
      headerMenu
      className={styles.container}
      headerClassName={styles.header}
    >
      <Block className={styles.block}>
        <div className={styles.inputs}>
          <span className={styles.title}>Alterar sua senha</span>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Senha atual</span>
            <PasswordInput
              className={styles.input}
              name="currentPassword"
              placeholder="Sua senha atual"
              noSpace
              onChange={handleChange}
              onBlur={validateField}
              value={fields.currentPassword?.value}
              valid={!fields.currentPassword?.valid ? fields.currentPassword?.valid : null}
              message={fields.currentPassword?.message}
            />
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Nova senha</span>
            <PasswordInput
              className={styles.input}
              name="newPassword"
              placeholder="Sua nova senha"
              noSpace
              onChange={handleChange}
              onBlur={validateField}
              value={fields.newPassword?.value}
              valid={!fields.newPassword?.valid ? fields.newPassword?.valid : null}
              message={fields.newPassword?.message}
            />
            <p className={styles.inputHint}>Utilize pelo menos 8 caracteres</p>
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Confirme sua senha</span>
            <PasswordInput
              className={styles.input}
              name="passwordConfirmation"
              placeholder="Confirme sua nova senha"
              noSpace
              onChange={handleChange}
              onBlur={validateField}
              value={fields.passwordConfirmation?.value}
              valid={
                !fields.passwordConfirmation?.valid ? fields.passwordConfirmation?.valid : null
              }
              message={fields.passwordConfirmation?.message}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button text="Confirmar nova senha" primary onClick={handleSubmit} />
        </div>
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
