import { ITrashIconProps } from "./TrashIcon.i";
import * as React from 'react'


const TrashIcon: React.FC<ITrashIconProps> = ({ fill = "#000", viewBox = '0 0 13 16', ...props }) => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
            <path d="M10.059 15.269H2.325L1.3 2.019h9.789l-1.03 13.25zm-7.262-.576h6.785L10.5 2.596H1.84l.958 12.097z" fill={fill} />
            <path d="M11.806 2.596H.532a.243.243 0 0 1-.182-.084.307.307 0 0 1-.075-.204c0-.076.027-.15.075-.204a.243.243 0 0 1 .182-.084h11.274c.068 0 .133.03.181.084a.307.307 0 0 1 .075.204c0 .077-.026.15-.075.204a.243.243 0 0 1-.18.084z" fill="#000" /><path d="M8.413 2.596h-4.5v-.824c0-.382.136-.748.376-1.018s.566-.422.906-.422h1.937c.34 0 .666.152.906.422s.375.636.375 1.018v.824zM4.426 2.02h3.475v-.248a.927.927 0 0 0-.227-.61.734.734 0 0 0-.542-.254H5.195c-.204 0-.4.091-.544.253a.921.921 0 0 0-.225.611v.248zM6.42 4.63c0-.16-.115-.289-.256-.289-.142 0-.257.13-.257.288v8.048c0 .159.115.288.257.288.141 0 .256-.129.256-.288V4.629zm-1.994 0c0-.16-.114-.289-.256-.289-.141 0-.256.13-.256.288v8.048c0 .159.115.288.256.288.142 0 .256-.129.256-.288V4.629zm3.988 0c0-.16-.115-.289-.257-.289-.141 0-.256.13-.256.288v8.048c0 .159.115.288.256.288.142 0 .257-.129.257-.288V4.629z" fill={fill} />
        </svg>
    )
}


export { TrashIcon }
