import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { ReactComponent as MailIcon } from '../../../assets/mail.svg';

import styles from './Help.module.css';

const Layout = () => {
  return (
    <ClientAreaContainer
      headerTitle="Como podemos te ajudar?"
      headerMenu
      className={styles.container}
      headerClassName={styles.header}
    >
      <Block title="Nossas fórmulas funcionam, seu feedback mais ainda!" className={styles.block}>
        <p>
          A JustForYou nasceu em 2018 da vontade de celebrar a individualidade oferecendo produtos
          personalizados que funcionam de verdade. Queremos saber detalhes sobre sua experiência e
          resultados.
        </p>
        <p>
          Essa comunicação é essencial para nós e - assim como o uso da inteligência artificial e
          machine learning - é o que nos fez quase zerar casos em que a fórmula precisa ser refeita.
        </p>
        <p>
          Ainda assim não alcançamos suas expectativas? Converse com o time de bem-estar do cliente:
          nossos especialistas farão uma avaliação do seu caso.
        </p>
        <p>Elogios, dúvidas, sugestões também são bem-vindos, claro.</p>
      </Block>
      <Block title="Escolha por onde quer conversar:" className={styles.block}>
        <div className={styles.contactOptions}>
          <div className={styles.contact}>
            <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}>
              <div className={styles.icon}>
                <MailIcon styles={{ height: '60px', width: '60px' }} />
              </div>
              <p>
                Envie um e-mail para
                <br />
                <span>you@justfor.com.br</span>
              </p>
            </a>
          </div>
        </div>
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
