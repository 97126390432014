import styled from "styled-components";
import { IButtonProps } from "./Button.i";
import { defaultTheme as Theme } from "../../theme";

export const SButton = styled.button<IButtonProps>`
min-width: 100%;
min-height: 100%;
transition: all 0.1s ease-in-out;
-webkit-tap-highlight-color: ${Theme.highlight?.primary || 'rgba(244, 162, 126, .2)'};
font-size: ${Theme.config?.sizes?.sm};
font-weight: 700;
font-family: ${Theme?.font?.primary};
white-space: nowrap;

&:hover {
  cursor: pointer;
}

${(props) => {
    switch (props.variant) {
      case "solid":
        return `
        background-color: ${Theme?.color?.primary?.i900};
        color: ${Theme?.color?.base?.i100};
        border: none;
        padding: 11px 0;

        &:hover {
          background-color: ${Theme?.color?.primary?.i1000};
        }
      `;
      case "outline":
        return `
        background-color: transparent;
        color: ${Theme?.color?.primary?.i900};
        border: 1px solid ${Theme?.color?.primary?.i900};
        padding: 9px 0;

        &:hover {
          border-color: ${Theme?.color?.primary?.i1000};
          color: ${Theme?.color?.primary?.i1000};
        }
      `;
      case "ghost":
        return `
        background-color: transparent;
        color: ${Theme?.color?.primary?.i900};
        border: none;
        padding: 11px 0;

        &:hover{
          background-color: ${Theme?.color?.primary?.i900};
          color: ${Theme?.color?.base?.i100};
        }
        `;
      case "link":
        return `
        background-color: transparent;
        color: ${Theme?.color?.primary?.i900};
        border: none;
        text-decoration: underline;
        padding: 11px 0;

        &:hover{
          color: ${Theme?.color?.primary?.i1000};
        }
        `;
    }
  }}
`;
