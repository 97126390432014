import googleTagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/Auth';
import { DataProvider } from './contexts/Data';
import { CheckoutProvider } from './contexts/CheckoutSession/CheckoutSession';
import ErrorBoundary from './utils/ErrorBoundary';

import { useTheme } from './lib/jfy-ui'
import { ThemeProvider } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles/Toast.module.css';
import './styles/App.css';
import { makeServer } from './services/mirage';
import { startTracking } from './analytics/config';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_DEV_SERVER) {
  makeServer({ environment: "development" })
}


const App = () => {
  const appTheme = useTheme({
    config: {
      sizes: {
        xsm: "0.8rem",
      },
    },
    color: {
      i800: "#7f8386"
    }
  })



  //if we don't define in app.js the assistant tag doesn't find this app
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  googleTagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  startTracking()

  return (
    <ErrorBoundary>
      <AuthProvider>
        <DataProvider>
          <CheckoutProvider>
            <ThemeProvider theme={appTheme}>
              <Routes />
              <ToastContainer
                autoClose={4300}
                position="top-center"
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                className={styles.toast}
                bodyClassName={styles.body}
              />
            </ThemeProvider>
          </CheckoutProvider>
        </DataProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
};

export default App;
