import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import { PasswordInput } from '../../../components/Input';
import LoadingDots from '../../../components/LoadingDots';
import Button from '../../../components/Button';

import styles from './ResetPasswordPublic.module.css';

const Layout = ({
  handleChange,
  validateField,
  fields,
  validToken,
  onResetPassword,
  onClickLogin,
  passwordReset,
  loading,
}) => {
  return (
    <ClientAreaContainer headerLogo className={styles.container} headerClassName={styles.header}>
      <Block className={styles.block}>
        {validToken ? (
          <>
            <div className={styles.inputs}>
              <span className={styles.title}>Recuperação de senha</span>
              <div className={styles.inputContainer}>
                <span className={styles.inputLabel}>Nova senha</span>
                <PasswordInput
                  className={styles.input}
                  name="password"
                  placeholder="Sua nova senha"
                  noSpace
                  onChange={handleChange}
                  onBlur={validateField}
                  value={fields.password?.value}
                  valid={!fields.password?.valid ? fields.password?.valid : null}
                  message={fields.password?.message}
                  disabled={passwordReset || loading}
                />
                <p className={styles.inputHint}>Utilize pelo menos 8 caracteres</p>
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.inputLabel}>Confirme sua senha</span>
                <PasswordInput
                  className={styles.input}
                  name="passwordConfirmation"
                  placeholder="Confirme sua nova senha"
                  noSpace
                  onChange={handleChange}
                  onBlur={validateField}
                  value={fields.passwordConfirmation?.value}
                  valid={
                    !fields.passwordConfirmation?.valid ? fields.passwordConfirmation?.valid : null
                  }
                  message={fields.passwordConfirmation?.message}
                  disabled={passwordReset || loading}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                text={passwordReset ? 'Ir para Login' : 'Confirmar nova senha'}
                primary
                onClick={() => {
                  passwordReset ? onClickLogin() : onResetPassword();
                }}
                disabled={loading}
              />
            </div>
          </>
        ) : (
          <LoadingDots />
        )}
      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
