import styled from "styled-components";
import { defaultTheme  as Theme} from '../../theme/defaultTheme'

type ControllerSize = {
    sizeControl?: string;
}

export const Container = styled.div`
display: flex;
flex-direction: row;
/* padding:0.5rem; */
align-items: center;

justify-content: space-between;
border: 0.5px solid ${Theme?.color?.base?.i1000};
font-family: ${Theme?.font?.primary};
font-weight: bold;
`
export const Increase = styled.div<ControllerSize>`
border-left: 0.5px solid  ${Theme?.color?.base?.i1000};
padding: ${(props)=> props?.sizeControl? props?.sizeControl  : '6px'};
cursor: pointer;
`
export const Decrease = styled.div<ControllerSize>`
border-right: 0.5px solid  ${Theme?.color?.base?.i1000};
padding: ${(props)=> props?.sizeControl? props?.sizeControl : '6px'};
cursor: pointer;
`