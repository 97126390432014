import { IThemeProps } from "../theme.i";

export const defaultTheme: IThemeProps = {
  color: {
    primary: {
      "i1000": "#EB936D",
      "i900": "#F4A27E",
      "i800": "#F9C29D",
      "i700": "#FCD4A4",
      "i600": "#FFE6AB",
    },
    secondary: {
      "i1000": "#003141",
      "i900": "#799FB5",
      "i800": "#7BA7C6",
      "i700": "#7DB4DA",
      "i600": "#ACBBC2",
      "i500": "#A3C8E2",
      "i400": "#D0DFE3",
      "i300": "#EAEAEA",
    },
    terciary: {
      "i1000": "#003F40",
      "i900": "#74A8A5",
      "i800": "#7EA896",
      "i700": "#94BDAD",
      "i600": "#B9D8CF",
      "i500": "#D4EBEA",
      "i400": "#ff4e00",
      "i300": "##EAD17A",
    },
    base: {
      "i1000": "#0D0D0E",
      "i900": "#1F282C",
      "i800": "#90969A",
      "i600": "#BEC8CE",
      "i500": "#DADFE2",
      "i400": "#E1E1E1",
      "i300": "#EEEEEE",
      "i100": "#FFFFFF",
    },
    success: "#66BB6A",
    warning: "#EAD17A",
    error: "#d91717",
  },
  font: {
    primary: "Montserrat, sans-serif",
    secondary: "Roboto, sans-serif",
  },
  config: {
    sizes: {
      xsm: "0.75rem",
      sm: "1rem",
      md: "1.5rem",
      lg: "2rem",
      xlg: "2.5rem",
    },
  },
};
