import styled from "styled-components";
import { IInputProps } from "./Input.i";
import { defaultTheme as Theme } from "../../theme";

export const SInput = styled.input<IInputProps>`
  width: 100%;
  height: 100%;
  border: unset;
  font-weight: 500;
  font-family: ${Theme?.font?.primary};
  padding: 7px 12px;
  color: ${Theme?.color?.base?.i1000};
  font-size: 0.75rem;


  ${(props) => {
    switch (props.variant) {
      case "blank":
        return `
          border: 1px solid ${Theme?.color?.base?.i800};

          &:focus {
            outline: none;
            background: ${Theme?.color?.base?.i300};
            outline: none;
          }
        `;

      case "solid":
        return `
          border: 1px solid ${Theme?.color?.base?.i800};
          background: ${Theme?.color?.base?.i300};

          &:focus {
            outline: none;
            background: ${Theme?.color?.base?.i300};
            outline: none;
          }
        `;
    }
  }}
`;