import { ILoadingDotProps } from './LoadingDots.i'
import { Container, Dot } from './styles'
import * as React from 'react'


const LoadingDots: React.FC<ILoadingDotProps> = ({ delay, variant, size = 'md', diffTimeLoadingAnimation, ...props }) => {
  return (
    <Container {...props}>
      <Dot delay={0.4} variant={variant} size={size} diffTimeLoadingAnimation={diffTimeLoadingAnimation} />
      <Dot delay={0.6} variant={variant} size={size} diffTimeLoadingAnimation={diffTimeLoadingAnimation} />
      <Dot delay={0.8} variant={variant} size={size} diffTimeLoadingAnimation={diffTimeLoadingAnimation} />
      <Dot delay={1} variant={variant} size={size} diffTimeLoadingAnimation={diffTimeLoadingAnimation} />
    </Container>
  )
}

export { Dot, LoadingDots }
