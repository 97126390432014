import * as  React from 'react'
import { IPensilIconProps } from './PensilIcon.i'

const PensilIcon: React.FC<IPensilIconProps> = ({ fill = "#000", width = 19, height = 17, ...props }) => {
    return (
        <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.869.118a.407.407 0 0 1 .575 0l2.438 2.438a.407.407 0 0 1 0 .575l-8.126 8.125a.407.407 0 0 1-.136.09L.557 12.97a.407.407 0 0 1-.528-.528L1.654 8.38a.406.406 0 0 1 .09-.137L9.869.118zm-.763 1.913 1.863 1.863 1.05-1.05L10.156.98l-1.05 1.05zm1.288 2.438L8.531 2.606l-5.28 5.28v.239h.405a.406.406 0 0 1 .407.406v.406h.406a.406.406 0 0 1 .406.407v.406h.238l5.281-5.281zm-7.93 4.204-.087.086-1.241 3.105 3.105-1.242.086-.086a.406.406 0 0 1-.264-.38V9.75h-.407a.406.406 0 0 1-.406-.406v-.407h-.406a.406.406 0 0 1-.38-.264z" fill={fill} />
        </svg>

    )
}


export { PensilIcon }
