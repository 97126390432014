import { slide as Menu } from 'react-burger-menu';
import HomeIcon from '../../../assets/customIcons/HomeIcon';
import OrderIcon from '../../../assets/customIcons/OrdersIcon';
import FormulasIcon from '../../../assets/customIcons/FormulasIcon';
import MyDataIcon from '../../../assets/customIcons/MyDataIcon';
import { CardIcon } from '../../../lib/jfy-icons';
import AddressIcon from '../../../assets/customIcons/AddressIcon';
import KeyIcon from '../../../assets/customIcons/PasswordIcon';
import HelpIcon from '../../../assets/customIcons/HelpIcon';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as JFYLogo } from '../../../assets/jfy-white-logo.svg';
import { MenuItem } from './MenuItem'

import './burger-menu-styles.css';
import AuthContext from '../../../contexts/Auth';
import { useContext } from 'react';
import styles from './Menu.module.css';

const Layout = ({ redirectTo, selectedRoute, onClose, onOpen, isOpen }) => {
  const context = useContext(AuthContext);
  const colors = {
    selected: '#f4a27e',
    unselected: '#000000',
  };

  const routes = {
    home: { path: '', iconColor: colors.unselected, selected: false },
    orders: { path: 'orders', iconColor: colors.unselected, selected: false },
    formulas: { path: 'formulas', iconColor: colors.unselected, selected: false },
    userData: { path: 'personal-data', iconColor: colors.unselected, selected: false },
    address: { path: 'address', iconColor: colors.unselected, selected: false },
    resetPassword: { path: 'reset-password', iconColor: colors.unselected, selected: false },
    help: { path: 'help', iconColor: colors.unselected, selected: false },
    card: { path: 'cards', iconColor: colors.unselected, selected: false },
  };

  Object.entries(routes).forEach((route) => {
    if (selectedRoute === route[1].path) {
      route[1].iconColor = colors.selected;
      route[1].selected = true;
    }
  });

  return (
    <Menu styles={styles} isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <div className={styles.logoContainer}>
        <JFYLogo className={styles.jfyLogo} />
      </div>
      <MenuItem
        id="home"
        redirectTo={() => redirectTo('/')}
        selected={routes.home.selected}
        icon={<HomeIcon
          color={routes.home.iconColor}
          className={styles.iconContainer} />}>
        Home
      </MenuItem>
      <MenuItem
        id="orders"
        redirectTo={() => redirectTo(routes.orders.path)}
        selected={routes.orders.selected}
        icon={<OrderIcon
          color={routes.orders.iconColor}
          className={styles.iconContainer} />}>
        Meus Pedidos
      </MenuItem>
      <MenuItem
        id="formulas"
        redirectTo={() => redirectTo(routes.formulas.path)}
        selected={routes.formulas.selected}
        icon={<FormulasIcon
          color={routes.formulas.iconColor}
          className={styles.iconContainer} />}>
        Minhas Fórmulas
      </MenuItem>
      <MenuItem
        id="personal-data"
        redirectTo={() => redirectTo(routes.userData.path)}
        selected={routes.userData.selected}
        icon={<MyDataIcon
          color={routes.userData.iconColor}
          className={styles.iconContainer} />}>
        Meus Dados
      </MenuItem>
      <MenuItem
        id="address"
        redirectTo={() => redirectTo(routes.address.path)}
        selected={routes.address.selected}
        icon={<AddressIcon
          color={routes.address.iconColor}
          className={styles.iconContainer} />}>
        Meu Endereço
      </MenuItem>
      <MenuItem
        id="card"
        redirectTo={() => redirectTo(routes.card.path)}
        selected={routes.card.selected}
        icon={<CardIcon
          color={routes.card.iconColor}
          className={styles.iconContainer} />}>
        Meus Cartões
      </MenuItem>
      <MenuItem
        id="password"
        redirectTo={() => redirectTo(routes.resetPassword.path)}
        selected={routes.resetPassword.selected}
        icon={<KeyIcon
          color={routes.resetPassword.iconColor}
          className={styles.iconContainer} />}>
        Alterar Senha
      </MenuItem>
      <MenuItem
        id="help"
        redirectTo={() => redirectTo(routes.help.path)}
        selected={routes.help.selected}
        icon={<HelpIcon
          color={routes.help.iconColor}
          className={styles.iconContainer} />}>
        Atendimento
      </MenuItem>
      <MenuItem
        id="logout"
        redirectTo={() => context.logout()}
        selected={false}
        icon={<LogoutIcon
          color={routes.help.iconColor}
          className={styles.iconContainer} />}>
        Sair
      </MenuItem>
    </Menu>
  );
};

export default Layout;
