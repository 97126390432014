import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from './Layout';
import { useFetch } from '../../hooks';
import notify, { EMAIL_SUCCESS, EMAIL_FAIL, EMAIL_EMPTY } from '../../utils/notify';
import logger from '../../utils/logger';

const SendEmail = () => {
  const [subjectType, setSubjectType] = useState(0);
  const [message, setMessage] = useState('');
  const history = useHistory();
  const { fetchFromBackend } = useFetch();

  const handleSubjectChange = (evt) => {
    setSubjectType(evt.target.value);
  };

  const handleMessageChange = (text) => {
    setMessage(text);
  };
  const sendEmail = async () => {
    const url = '/api/customers/support/';
    const method = 'POST';
    let body = {
      contact_type: subjectType.toString(),
      message: message,
    };
    let response = await fetchFromBackend(url, method, body).catch((error) => {
      logger.error('Failed to send email \n', error);
    });
    if (response.response_code === 0) {
      notify(EMAIL_SUCCESS, 'success');
      history.push({ pathname: '/help', search: window.location.search });
    } else if (response.response_code === 6) {
      notify(EMAIL_FAIL, 'error');
    } else if (response.response_code === 4) {
      notify(EMAIL_EMPTY, 'error');
    }
  };

  return (
    <Layout
      goBack={() => {
        history.goBack();
      }}
      onSubjectChange={(evt) => {
        handleSubjectChange(evt);
      }}
      onMessageChange={(evt) => {
        handleMessageChange(evt.target.value);
      }}
      sendEmail={() => {
        if (message.replace(/ /g, '').length > 0) {
          sendEmail();
        } else {
          notify(EMAIL_EMPTY, 'warn');
        }
      }}
    />
  );
};
export default SendEmail;
