import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { CheckoutContext } from '../../contexts/CheckoutSession/CheckoutSession';
import { formatObjectToPatternProducts } from '../../contexts/CheckoutSession/CheckoutSessionHelper';
import { useForm, useFetch } from '../../hooks';
import * as dataLayerHandler from '../../analytics/dataLayerHandler';
import { cellphoneMask } from '../../utils/formater';
import { cellphoneSchema } from '../../utils/validator/schemas';

import logResponseError from '../../utils/loggerMessages';
import notify, {
  CVV_INVALID_WARN,
  NO_PRODUCTS_IN_CART_WARN,
  NO_CARD_SELETED,
  SELECT_ADDRESS_INVALID_TO_CHECKOUT_WARN,
  INVALID_PHONE,
  VALIDATE_CVV_CARD
} from '../../utils/notify';

import {
  getInvalidVoucherMessage,
  getValidVoucherMessage,
  validVoucher,
  selectBestVoucher,
  findInstallmentsInfo,
  redirectToHome,
  getSummaryValues,
  generateNewInstallments,
  handleValidadeItensQuantityCart,
  formatCartFinish,
  getUrlVoucher,
  formatProducts,
  filterValidVouchers,
  addBenefitToCart,
  removeBenefitFromCart,
} from './CheckoutHelpers';

import { Layout } from './Layout';

const Checkout = () => {
  const history = useHistory();
  let { token } = useParams();
  const location = useLocation();

  const { cart, updateCart } = useContext(CheckoutContext);
  const { fetchFromBackend } = useFetch();

  const [isLoadingVoucher, setIsloadingVoucher] = useState(false);
  const [isLoadingCard, setIsloadingCard] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState('credit-card');
  const [selectedAddress, setSelectedAddress] = useState(
    cart?.addressInfo?.slug?.toLowerCase()
  );
  const [selectedShipping, setSelectedShipping] = useState('2');
  const [favoriteCard, setFavoriteCard] = useState({});
  const [selectedInstallment, setSelectedInstallment] = useState('1');
  const [cvv, setCvv] = useState('');
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [voucher, setVoucher] = useState('');
  const [gatewayAlias, setGatewayAlias] = useState('');
  const [voucherValidation, setVoucherValidation] = useState({
    status: '',
    message: '',
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [suggestionVouchers, setSuggestionVouchers] = useState([]);
  const { fields, setFieldValue, handleChange, handleFinish, validateField } = useForm({
    phone: {
      mask: cellphoneMask,
      schema: cellphoneSchema,
      required: true,
    },
  });

  const [summaryPurchase, setSummaryPurchase] = useState(
    getSummaryValues(cart)
  );

  const installments = generateNewInstallments(cart?.finalValue);

  useEffect(() => {
    if (!cart.token) return redirectToHome(history);

    //if credit_card_hash exists, it means it is a new card and with that it needs to be redirected
    const isNewCard = cart?.paymentInfo?.cardInfo?.credit_card_hash;
    const statusCode = location.state?.statusCode;

    if (statusCode === 400 && isNewCard) return setOpenConfirm(true);
    if (statusCode === 400) notify(VALIDATE_CVV_CARD, 'error');

    getCards();
    getAdresses();
    getSuggestionVouchers();
    setVoucher(getUrlVoucher(cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSummaryPurchase(getSummaryValues(cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const navigateToCardDetails = () => {
    history.push({
      pathname: '/checkout/' + token + '/payment/new',
      state: {
        origin: 'checkout', checkCardInformation: true
      },
      search: window.location.search,
    });
  }

  const acepptActionModalValidateCard = () => {
    setOpenConfirm(false);
    navigateToCardDetails();
  }

  const updatePhoneInfoUser = async (phone) => {
    const body = {
      phone: phone.replace(/[^0-9]/g, ''),
    };
    try {
      await fetchFromBackend(
        '/api/customers/profile/update',
        'PUT',
        body,
        true
      );
    } catch (error) {
      logResponseError(
        'Failed update phone number on checkout page',
        `/api/customers/profile/update`,
        'PUT',
        error,
        body
      );
    }
  };

  const getLogisticsByZipCode = async (zipCode, products) => {
    let deliveryCompanies = [];
    zipCode = zipCode?.replace(/[^0-9]/g, '');
    try {
      const res = await fetchFromBackend(
        `/api/purchase/logistics/carrier/zip/${zipCode}`,
        'PATCH',
        formatProducts(products)
      );
      if (res.response_code === 0) {
        deliveryCompanies = res.content;
      }
    } catch (error) {
      logResponseError(
        'Failed get carriers',
        `/logistics/carrier/zip/${zipCode}`,
        'PATCH',
        error,
        { zip_code: zipCode }
      );
    }
    return deliveryCompanies;
  };

  const validateZipcode = async (zipcode) => {
    let validZipcode = false;
    zipcode = zipcode?.replace(/[^0-9]/g, '');

    const url = `/api/purchase/payment/check_zipcode/${zipcode}`;

    try {
      const res = await fetchFromBackend(url, 'GET', {});
      if (Number(res?.response_code) === 0) {
        validZipcode = res?.content?.valid;
      }
    } catch (err) {
      logResponseError(
        'validate zipcode on add checkout page',
        url,
        'PATCH',
        err,
        zipcode
      );
    }

    return validZipcode;
  };

  const getVoucherByVoucherCode = async (cartToken, products, voucherCode) => {
    var voucherResult = {};
    try {
      const res = await fetchFromBackend(
        `/api/purchase/carts/${cartToken}/validate`,
        'PATCH',
        {
          products: formatProducts(products),
          voucher_code: voucherCode,
        }
      );
      if (res.response_code === 0) {
        voucherResult = res.content;
      }
    } catch (error) {
      logResponseError(
        'Failed validade voucher',
        `/purchase/carts/${cartToken}/validate`,
        'PATCH',
        error,
        { products, voucher_code: voucherCode }
      );
    }
    return voucherResult;
  };

  const finishCart = async (cart) => {
    let cartFinished = false;

    cart = removeBenefitFromCart(cart);

    const { totalValue } = formatObjectToPatternProducts(cart);
    cart.totalValue = totalValue;

    try {
      const response = await fetchFromBackend(
        `/api/purchase/carts/${cart.token}/finish?track_event=true`,
        'PATCH',
        formatCartFinish(cart)
      );
      if (!response.content?.detail) cartFinished = true;
    } catch (error) {
      logResponseError(
        'Failed finish cart',
        `/api/purchase/carts/${cart.token}/finish?track_event=true`,
        'PATCH',
        error,
        formatCartFinish(cart)
      );
    }
    return cartFinished;
  };

  const getVouchers = async () => {
    let vouchers = [];
    try {
      const res = await fetchFromBackend(
        '/api/purchase/vouchers?suggestion=true',
        'GET',
        {}
      );
      if (res.response_code === 0) {
        vouchers = res.content;
      }
    } catch (error) {
      logResponseError(
        'Failed get suggestion vouchers',
        `/purchase/vouchers?suggestion=true`,
        'GET',
        error,
        {}
      );
    }
    return vouchers;
  };

  const getSuggestionVouchers = async () => {
    const vouchersApi = await getVouchers();
    const vouchers = filterValidVouchers(vouchersApi);
    setSuggestionVouchers(vouchers);
  };

  const getLogisticsCompanies = async (adressesCustumer) => {
    const customerZipCode = adressesCustumer.zip_code;
    const addressIsValid = await validateZipcode(customerZipCode);
    getLogisticsByZipCode(customerZipCode, cart.products).then((logistics) => {
      if (logistics.length > 0 && addressIsValid) {
        setShippingCompanies(logistics);
        const newCart = {
          ...cart,
          shipping: logistics[0],
          addressInfo: {
            customer_id: adressesCustumer.alias,
            customer_address: adressesCustumer.street,
            customer_state: adressesCustumer.state,
            customer_district: adressesCustumer.neighborhood,
            customer_number: adressesCustumer.number,
            customer_city: adressesCustumer.city,
            customer_zip: adressesCustumer.zip_code,
            customer_reference: adressesCustumer.reference,
            customer_complement: adressesCustumer.complement,
            slug: adressesCustumer.slug,
          },
        };
        updateCart(newCart);
        setSummaryPurchase(getSummaryValues(newCart));
        setSelectedShipping(logistics[0]?.v_carrier_id);
        dispatchShippingInfoEventDatalayer(newCart);
        const voucher = getUrlVoucher(newCart);

        if (!!voucher) {
          handleVoucher(voucher, false, newCart);
        }
      } else {
        const newCart = {
          ...cart,
          shipping: null,
          addressInfo: null,
        };
        updateCart(newCart);
        const voucher = getUrlVoucher(newCart);

        if (!!voucher) {
          handleVoucher(voucher, false, newCart);
        }
        setShippingCompanies([]);
        notify(SELECT_ADDRESS_INVALID_TO_CHECKOUT_WARN, 'warn');
      }
    });
  };

  const handleAlterAdress = (slug = false, addressList = []) => {
    const addressSlug = slug || cart?.addressInfo?.slug?.toLowerCase();
    const addressSelected = addressList.find(
      (address) => address.slug === addressSlug
    );

    setSelectedAddress(addressSelected.slug);
    getLogisticsCompanies(addressSelected);
  };

  const handleAlterCVV = (value) => {
    //regex to only match numbers
    const _value = value.replace(/[^0-9]/g, '');
    setCvv(_value);
  };

  const handleChangeInstallment = (value) => {
    setSelectedInstallment(value);
    const newCart = {
      ...cart,
      installment: findInstallmentsInfo(installments, value),
    };
    updateCart(newCart);
    setSummaryPurchase(getSummaryValues(newCart));
  };

  const handleChangeShipping = (event) => {
    setSelectedShipping(event);
    const newShippingCompanie = shippingCompanies.find(
      (companie) => companie.id === event
    );
    updateCart({ ...cart, shipping: newShippingCompanie });
    dispatchShippingInfoEventDatalayer({
      ...cart,
      shipping: newShippingCompanie,
    });
  };

  const handleQuantifierChange = (formId, productId, quantity) => {
    let updatedCart = cart;
    updatedCart.products
      .find((form) => form.formId === formId)
      .items.find((item) => item.id === productId).quantity = quantity;
    if (updatedCart.voucher || updatedCart.shipping) {
      if (updatedCart.shipping) {
        getLogisticsByZipCode(
          updatedCart.addressInfo?.customer_zip,
          updatedCart.products
        ).then((logistics) => {
          updatedCart = { ...updatedCart, shipping: logistics[0] };

          setShippingCompanies(logistics);
        });
      }
      if (getUrlVoucher(updatedCart)) {
        const suggestVoucher = !updatedCart.voucher.benefits?.length > 0;
        handleVoucher(updatedCart.voucher.code, suggestVoucher, updatedCart);
      }
    }
    updateCart(updatedCart);
  };

  const applyVoucher = (voucher) => {
    let updatedCart = removeBenefitFromCart(cart);
    updatedCart = { ...updatedCart, voucher: voucher };
    updatedCart = addBenefitToCart(updatedCart, voucher);
    updateCart(updatedCart);
  };

  const handleClearVoucher = () => {
    setVoucher('');
    setVoucherValidation({ status: '', message: '' });
  };

  const handleVoucher = async (
    voucher,
    suggestVoucher = false,
    newCart = false
  ) => {
    setIsloadingVoucher(true);
    let voucherResult;
    const cartCheckout = newCart || cart;
    await getVoucherByVoucherCode(token, cartCheckout.products, voucher).then(
      (data) => (voucherResult = data)
    );

    let seggestedVoucher;
    if (suggestVoucher) {
      seggestedVoucher = selectBestVoucher(cartCheckout, suggestionVouchers);
    }

    if (validVoucher(voucherResult)) {
      let bestVoucher = voucherResult.voucher;
      if (suggestVoucher) {
        bestVoucher = selectBestVoucher(cartCheckout, [
          voucherResult.voucher,
          seggestedVoucher,
        ]);
      }

      applyVoucher(bestVoucher.voucher);
      let updatedCart = {
        ...cartCheckout,
        voucher: bestVoucher.voucher || bestVoucher,
      };
      updateCart(updatedCart);

      setVoucherValidation({
        status: 'success',
        message: getValidVoucherMessage(
          updatedCart,
          bestVoucher.voucher || bestVoucher
        ),
      });
    } else {
      const { message } = getInvalidVoucherMessage(voucherResult.voucher);
      let updatedCart = removeBenefitFromCart(cart);
      updateCart({ ...updatedCart, voucher: null });
      if (message)
        setVoucherValidation({
          status: 'error',
          message: message,
        });
    }

    setIsloadingVoucher(false);
  };

  const handleFinishPurchase = async () => {
    setIsFinishLoading(true);
    const { valid } = await handleFinish();

    if (!valid) return setIsFinishLoading(false);

    if (
      Object.keys(favoriteCard).length === 0 &&
      selectedPaymentType === 'credit-card'
    ) {
      setIsFinishLoading(false)
      return notify(NO_CARD_SELETED, 'warn');
    }

    if ((!cvv || cvv.length < 3) && selectedPaymentType === 'credit-card') {
      setIsFinishLoading(false)
      return notify(CVV_INVALID_WARN, 'warn');
    }

    if (!handleValidadeItensQuantityCart(cart).valid) {
      setIsFinishLoading(false)
      return notify(NO_PRODUCTS_IN_CART_WARN, 'warn');
    }

    const addressIsValid = await validateZipcode(
      cart.addressInfo?.customer_zip
    );
    if (!cart.shipping || !addressIsValid) {
      setIsFinishLoading(false)
      return notify(SELECT_ADDRESS_INVALID_TO_CHECKOUT_WARN, 'warn');
    }

    if (!fields.phone?.value && selectedPaymentType === 'credit-card') {
      setIsFinishLoading(false)
      return notify(INVALID_PHONE, 'warn');
    }

    if (handleValidadeItensQuantityCart(cart).valid) {

      await updatePhoneInfoUser(fields.phone?.value);

      let newCart = {
        ...cart,
        installment: findInstallmentsInfo(installments, selectedInstallment),
      };

      newCart.gateway_alias = gatewayAlias;

      if (selectedPaymentType === 'credit-card') {
        if (!newCart.paymentInfo?.cardInfo?.credit_card_hash) {
          newCart = {
            ...newCart,
            paymentInfo: {
              cardInfo: {
                card_owner: favoriteCard?.holder_name,
                last4_number_card: favoriteCard?.last_digits,
                expire_date: favoriteCard?.exp_date,
                customer_id: favoriteCard?.customer_id,
                id_card: favoriteCard?.card_id,
                brand: favoriteCard?.brand,
                customer_cpf: favoriteCard?.customer_cpf,
                birth_date: favoriteCard?.birth_date,
                ...newCart?.paymentInfo?.cardInfo,
              },
              payment_method: 'credit-card',
            },
          };
          newCart.paymentInfo.cardInfo.cvv = cvv;
        }
      } else {
        newCart = {
          ...newCart,
          paymentInfo: {
            payment_method: selectedPaymentType,
          },
        };
      }

      const newAddress = addressList.find(
        (address) => address.slug === selectedAddress
      );
      newCart = {
        ...newCart,
        addressInfo: {
          customer_id: newAddress.customerAddressId,
          customer_address: newAddress.street,
          customer_state: newAddress.state,
          customer_district: newAddress.neighborhood,
          customer_number: newAddress.number,
          customer_city: newAddress.city,
          customer_zip: newAddress.zip_code,
          customer_reference: newAddress.reference,
          customer_complement: newAddress.complement,
          slug: newAddress.slug,
        },
      };
      updateCart(newCart);

      const cartFinished = await finishCart(newCart);
      if (cartFinished) {
        dispatchPaymentInfoEventDatalayer();
        setIsFinishLoading(false)
        const attempt = location.state?.attempt;
        history.push({
          pathname: '/checkout/' + token + '/order/processing',
          search: window.location.search,
          state: {
            attempt: attempt ? attempt : 1,
          },
        });
      }
      setIsFinishLoading(false)
    }
    setIsFinishLoading(false)
  };

  const dispatchPaymentInfoEventDatalayer = () => {
    //format object to dataLayer
    const objFormateDataLayerCart = dataLayerHandler.formatCartForDataLayer(
      cart
    );

    dataLayerHandler.addPaymentInfo(
      objFormateDataLayerCart.products,
      cart.finalValue / 100,
      cart.voucher?.code,
      selectedPaymentType,
      cart.token
    );
  };

  const dispatchShippingInfoEventDatalayer = (newCart = {}) => {
    //format object to dataLayer
    const objFormateDataLayerCart = dataLayerHandler.formatCartForDataLayer(
      newCart
    );

    dataLayerHandler.addShippingInfo(
      objFormateDataLayerCart.products,
      newCart?.finalValue,
      newCart.voucher?.code || getUrlVoucher(newCart) || '',
      newCart?.shipping?.v_carrier_alias,
      newCart.token
    );
  };

  async function getCards() {
    setIsloadingCard(true);
    try {
      const res = await fetchFromBackend(
        `/api/jfy/customers/payment_settings`,
        'GET',
        {}
      );
      if (Number(res?.response_code) === 0) {
        setGatewayAlias(res.content.gateway_alias);
        setFieldValue('phone', res.content?.phone_number);
        if (
          res.content.credit_cards.length > 0 &&
          !cart?.paymentInfo?.cardInfo
        ) {
          const cardToRender =
            res.content.credit_cards.find(
              (item) =>
                item.card_id?.toString() ===
                res.content.favorite_credit_card?.toString()
            ) || res.content.credit_cards.at(-1);
          setFavoriteCard({
            holder_name: cardToRender?.holder_name,
            last_digits: cardToRender?.last_digits,
            exp_date: cardToRender?.exp_date,
            customer_id: cardToRender?.customer_id,
            card_id: cardToRender?.card_id,
            brand: cardToRender?.brand,
            customer_cpf: cardToRender?.customer_cpf || 122,
            birth_date: cardToRender?.birth_date || 321,
            credit_card_hash: '',
          });
        } else if (cart?.paymentInfo?.cardInfo?.last4_number_card) {
          setFavoriteCard({
            holder_name: cart?.paymentInfo?.cardInfo?.card_owner,
            last_digits: cart?.paymentInfo?.cardInfo?.last4_number_card,
            exp_date: cart?.paymentInfo?.cardInfo?.expire_date,
            customer_id: cart?.paymentInfo?.cardInfo?.customer_id,
            card_id: cart?.paymentInfo?.cardInfo?.id_card,
            customer_cpf: cart?.paymentInfo?.cardInfo?.customer_cpf || 122,
            birth_date: cart?.paymentInfo?.cardInfo?.birth_date || 321,
            brand: '',
          });
          handleAlterCVV(cart?.paymentInfo?.cardInfo?.cvv);
        }
      }
      setIsloadingCard(false);
    } catch (error) {
      logResponseError(
        'Failed get Cards - getCards - Checkout',
        '/api/jfy/customers/payment_settings',
        'GET',
        error,
        {}
      );
    }
  }

  const getAdresses = async () => {
    try {
      const res = await fetchFromBackend(
        '/api/customers/profile/addresses',
        'GET',
        true
      );
      let mainAddress = false;
      const addresses = res?.content.map((address) => {
        if (address.is_main_address && !cart?.addressInfo?.slug) {
          mainAddress = address?.slug;
        }
        return {
          alias: address.alias,
          slug: address.slug,
          is_main_address: address.is_main_address,
          zip_code: address.zip_code,
          street: address.street,
          number: address.number,
          complement: address.complement || '',
          reference: address.reference || '',
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
          customerAddressId: address.alias,
        };
      });
      setAddressList(addresses);
      if (addresses.length > 0) {
        handleAlterAdress(
          mainAddress ? mainAddress : addresses.at(-1)?.slug,
          addresses
        );
      }
    } catch (err) {
      logResponseError(
        'Failed to fetch adresses\n',
        '/api/customers/profile/addresses',
        'GET',
        err,
        {}
      );
    }
  };

  return (
    <Layout
      goBack={() => history.goBack()}
      paymentCheckout={cart?.paymentInfo}
      addressCheckout={cart?.addressInfo}
      formula={cart?.products || []}
      cart={cart}
      handleQuantifierChange={handleQuantifierChange}
      purchaseAmount={cart?.finalValue}
      subtotal={cart?.totalValue}
      freeShipping={!!cart?.shipping === cart?.voucher?.freeShipping}
      shippingCompanies={shippingCompanies}
      installmentsPurchase={installments}
      handleChangeInstallment={handleChangeInstallment}
      handleChangeShipping={handleChangeShipping}
      selectedShipping={selectedShipping}
      selectedInstallment={selectedInstallment}
      summaryPurchase={summaryPurchase}
      handleFinishPurchase={handleFinishPurchase}
      setVoucher={setVoucher}
      voucher={voucher}
      history={history}
      token={token}
      handleAlterCVV={handleAlterCVV}
      cvv={cvv}
      findInstallmentsInfo={findInstallmentsInfo}
      isLoadingVoucher={isLoadingVoucher}
      voucherValidation={voucherValidation}
      handleVoucher={handleVoucher}
      handleClearVoucher={handleClearVoucher}
      isLoadingCard={isLoadingCard}
      favoriteCard={favoriteCard}
      selectedPaymentType={selectedPaymentType}
      setSelectedPaymentType={setSelectedPaymentType}
      addressList={addressList}
      selectedAddress={selectedAddress}
      handleAlterAdress={handleAlterAdress}
      handleChange={handleChange}
      fields={fields}
      isFinishLoading={isFinishLoading}
      validateField={validateField}
      isOpen={openConfirm}
      confirmAction={acepptActionModalValidateCard}
    />
  );
};

export { Checkout };
