import logger from './logger';

const logResponseError = (operation, url, method, response, silent = true, body = null) => {
  const messages = [
    `Failed to ${operation} (response error)\n`,
    'Request:',
    `*${method}* \`${url}\``,
    'Response:',
    response,
    body ? 'Body of request:' : null,
    body ? body : null,
  ];

  if (silent) {
    logger.silentError(...messages);
  } else {
    logger.error(...messages);
  }
};

export default logResponseError;
