export const HomeIcon = ({ color = '#1D1D1B', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M.97 7.13L8.75.62l2.62 2.35V.62h2.27v4.06l2.82 2.45"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M2.41 5.93v10.45h12.61V5.93"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M6.57 16.32V9.86h4.24v6.46"
      ></path>
    </svg>
  );
};

export default HomeIcon;
