export const AddressIcon = ({ color = '#1D1D1B', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="21"
      fill="none"
      viewBox="0 0 17 21"
      className={className}
    >
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M2.538 11.756C1.695 10.548 1 9.211 1 7.674 1 3.991 4.287 1 8.332 1c4.044 0 7.331 2.991 7.331 6.674 0 1.537-.62 2.839-1.538 4.082"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M14.125 11.756l-5.905 8.07-5.681-8.07"
      ></path>
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M8.332 11.756c2.569 0 4.652-1.969 4.652-4.398 0-2.43-2.083-4.399-4.652-4.399-2.57 0-4.653 1.97-4.653 4.399 0 2.429 2.083 4.398 4.653 4.398z"
      ></path>
    </svg>
  );
};

export default AddressIcon;
