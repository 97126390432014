import { defaultTheme } from '../../../../lib/jfy-ui';

import { Container } from './styles';
import formatPrice from '../../../../utils/formatPrice';

import { Text } from '../../../../components/Text';

const CartInfo = ({ status, productsCheckout, addressCheckout }) => {
  const formulaDistinct = [
    ...new Set(productsCheckout.map((item) => item.formulaName)),
  ];

  const formatCarrierInfo = (cartInfos) => {
    if (cartInfos && cartInfos.cart) {
      if (cartInfos.cart.cart_shippment_fee === 0) {
        return cartInfos.cart.carrier_name + ' - Grátis';
      } else {
        return (
          cartInfos.cart.carrier_name +
          ' - ' +
          formatPrice(cartInfos.cart.cart_shippment_fee)
        );
      }
    }
    return null;
  };

  return (
    <Container>
      <Text bold fontSize={defaultTheme.config.sizes.sm}>
        Informações do Pedido
      </Text>
      <Text bold fontSize={defaultTheme.config.sizes.xsm}>
        {status === 'approved' || status === 'confirmed'
          ? 'PRODUTOS ADQUIRIDOS'
          : 'PRODUTOS A SEREM ADQUIRIDOS'}
      </Text>
      <Text bold fontSize='0.75rem'>
        {formulaDistinct.length > 1 ? 'Fórmulas: ' : 'Formula: '}
        {formulaDistinct.join(', ')}
      </Text>
      <Text fontSize='0.75rem'>
        {productsCheckout.length > 0 &&
          productsCheckout.map((formulas, index) =>
            formulas.products.map((item, index) => {
              return (
                <div key={index}>
                  {item.quantity > 0 && (
                    <>
                      <Text fontSize='0.75rem'>
                        {item.quantity}x {item.product_display_name}
                      </Text>
                      <br />
                    </>
                  )}
                </div>
              );
            })
          )}
      </Text>
      <Text bold fontSize={defaultTheme.config.sizes.xsm}>
        DADOS DA ENTREGA
      </Text>
      <Text fontSize='0.75rem'>
        <b>Nome do comprador:</b>{' '}
        {addressCheckout ? addressCheckout.customer_name : ''}
      </Text>
      <Text fontSize='0.75rem'>
        <b>Endereço:</b> {addressCheckout?.customer_address},{' '}
        {addressCheckout?.customer_number}
      </Text>
      <Text fontSize='0.75rem'>
        {addressCheckout ? formatCarrierInfo(addressCheckout) : ''}
      </Text>
      <Text bold fontSize={defaultTheme.config.sizes.xsm}>
        PREVISÃO DE ENTREGA
      </Text>
      <Text fontSize='0.75rem'>
        Em {addressCheckout.days_to_deliver}{' '}
        {addressCheckout.days_to_deliver > 1 ? 'dias' : 'dia'}
      </Text>
    </Container>
  );
};

export { CartInfo };
