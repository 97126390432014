import { useEffect, useRef } from 'react';

const useComponentWillUnmount = (callback) => {
  const mem = useRef();

  useEffect(() => {
    mem.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      const func = mem.current;
      func();
    };
  }, []);
};

export default useComponentWillUnmount;
