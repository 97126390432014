import React from 'react';
import { InvalidIcon, ValidIcon } from '../../lib/jfy-icons';

import { Container, Message, Input, Content } from './styles';

const Voucher = ({ status, message, onChange, value = '', placeholder, readOnly }) => {
  return (
    <Container>
      <Content status={status}>
        <Input
          variant='blank'
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          readOnly={readOnly}
        />
        {status === 'success' && <ValidIcon />}
        {status === 'error' && <InvalidIcon />}
      </Content>
      <Message error={status === 'error'}>{message}</Message>
    </Container>
  );
};

export { Voucher };
