import { Button as CButton, ResponsiveControl } from '../../../lib/jfy-ui';

import styled from 'styled-components';

export const Button = styled(CButton)`
  margin-bottom: 0.75rem;
  min-width: 100%;

  @media ${ResponsiveControl.laptop('min')} {
    min-width: 21.375rem;
  }
`;

export const Line = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.base.i500};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width:  100%;
`;
