import { defaultTheme } from "../defaultTheme";
import { IThemeProps } from "../theme.i";

export function useTheme(params: IThemeProps): IThemeProps {
  return Object.assign(defaultTheme, {
    color: {
      primary: {
        i1000: params.color?.primary?.i1000
          ? params.color?.primary?.i1000
          : defaultTheme.color?.primary?.i1000,
        i900: params.color?.primary?.i900
          ? params.color?.primary?.i900
          : defaultTheme.color?.primary?.i900,
        i800: params.color?.primary?.i800
          ? params.color?.primary?.i800
          : defaultTheme.color?.primary?.i800,
        i700: params.color?.primary?.i700
          ? params.color?.primary?.i700
          : defaultTheme.color?.primary?.i700,
        i600: params.color?.primary?.i600
          ? params.color?.primary?.i600
          : defaultTheme.color?.primary?.i600,
        i500: params.color?.primary?.i500
          ? params.color?.primary?.i500
          : defaultTheme.color?.primary?.i500,
        i400: params.color?.primary?.i400
          ? params.color?.primary?.i400
          : defaultTheme.color?.primary?.i400,
        i300: params.color?.primary?.i300
          ? params.color?.primary?.i300
          : defaultTheme.color?.primary?.i300,
        i200: params.color?.primary?.i200
          ? params.color?.primary?.i200
          : defaultTheme.color?.primary?.i200,
        i100: params.color?.primary?.i100
          ? params.color?.primary?.i100
          : defaultTheme.color?.primary?.i100,
      },
      secondary: {
        i1000: params.color?.secondary?.i1000
          ? params.color?.secondary?.i1000
          : defaultTheme.color?.secondary?.i1000,
        i900: params.color?.secondary?.i900
          ? params.color?.secondary?.i900
          : defaultTheme.color?.secondary?.i900,
        i800: params.color?.secondary?.i800
          ? params.color?.secondary?.i800
          : defaultTheme.color?.secondary?.i800,
        i700: params.color?.secondary?.i700
          ? params.color?.secondary?.i700
          : defaultTheme.color?.secondary?.i700,
        i600: params.color?.secondary?.i600
          ? params.color?.secondary?.i600
          : defaultTheme.color?.secondary?.i600,
        i500: params.color?.secondary?.i500
          ? params.color?.secondary?.i500
          : defaultTheme.color?.secondary?.i500,
        i400: params.color?.secondary?.i400
          ? params.color?.secondary?.i400
          : defaultTheme.color?.secondary?.i400,
        i300: params.color?.secondary?.i300
          ? params.color?.secondary?.i300
          : defaultTheme.color?.secondary?.i300,
        i200: params.color?.secondary?.i200
          ? params.color?.secondary?.i200
          : defaultTheme.color?.secondary?.i200,
        i100: params.color?.secondary?.i100
          ? params.color?.secondary?.i100
          : defaultTheme.color?.secondary?.i100,
      },
      terciary: {
        i1000: params.color?.terciary?.i1000
          ? params.color?.terciary?.i1000
          : defaultTheme.color?.terciary?.i1000,
        i900: params.color?.terciary?.i900
          ? params.color?.terciary?.i900
          : defaultTheme.color?.terciary?.i900,
        i800: params.color?.terciary?.i800
          ? params?.color?.terciary.i800
          : defaultTheme.color?.terciary?.i800,
        i700: params.color?.terciary?.i700
          ? params?.color?.terciary.i700
          : defaultTheme.color?.terciary?.i700,
        i600: params.color?.terciary?.i600
          ? params?.color?.terciary.i600
          : defaultTheme.color?.terciary?.i600,
        i500: params.color?.terciary?.i500
          ? params?.color?.terciary.i500
          : defaultTheme.color?.terciary?.i500,
        i400: params.color?.terciary?.i400
          ? params.color?.terciary?.i400
          : defaultTheme.color?.terciary?.i400,
        i300: params.color?.terciary?.i300
          ? params.color?.terciary?.i300
          : defaultTheme.color?.terciary?.i300,
        i200: params.color?.terciary?.i200
          ? params.color?.terciary?.i200
          : defaultTheme.color?.terciary?.i200,
        i100: params.color?.terciary?.i100
          ? params.color?.terciary?.i100
          : defaultTheme.color?.terciary?.i100,
      },
      base: {
        i1000: params.color?.base?.i1000
          ? params?.color?.base?.i1000
          : defaultTheme.color?.base?.i1000,
        i900: params.color?.base?.i900
          ? params?.color?.base?.i900
          : defaultTheme.color?.base?.i900,
        i800: params.color?.base?.i800
          ? params?.color?.base.i800
          : defaultTheme.color?.base?.i800,
        i700: params.color?.base?.i700
          ? params?.color?.base.i700
          : defaultTheme.color?.base?.i700,
        i600: params.color?.base?.i600
          ? params?.color?.base.i600
          : defaultTheme.color?.base?.i600,
        i500: params.color?.base?.i500
          ? params?.color?.base.i500
          : defaultTheme.color?.base?.i500,
        i400: params.color?.base?.i400
          ? params?.color?.base?.i400
          : defaultTheme.color?.base?.i400,
        i300: params.color?.base?.i300
          ? params?.color?.base?.i300
          : defaultTheme.color?.base?.i300,
        i200: params.color?.base?.i200
          ? params?.color?.base?.i200
          : defaultTheme.color?.base?.i200,
        i100: params.color?.base?.i100
          ? params?.color?.base?.i100
          : defaultTheme.color?.base?.i100,
      },
      success: params.color?.success
        ? params?.color?.success
        : defaultTheme.color?.success,
      warning: params.color?.warning
        ? params?.color?.warning
        : defaultTheme.color?.warning,
      error: params.color?.error
        ? params?.color?.error
        : defaultTheme.color?.error,
    },
    highlight: {
      primary: {
        i1000: params.highlight?.primary?.i1000
          ? params.highlight?.primary?.i1000
          : defaultTheme.highlight?.primary?.i1000,
        i900: params.highlight?.primary?.i900
          ? params.highlight?.primary?.i900
          : defaultTheme.highlight?.primary?.i900,
        i800: params.highlight?.primary?.i800
          ? params.highlight?.primary?.i800
          : defaultTheme.highlight?.primary?.i800,
        i700: params.highlight?.primary?.i700
          ? params.highlight?.primary?.i700
          : defaultTheme.highlight?.primary?.i700,
        i600: params.highlight?.primary?.i600
          ? params.highlight?.primary?.i600
          : defaultTheme.highlight?.primary?.i600,
        i500: params.highlight?.primary?.i500
          ? params.highlight?.primary?.i500
          : defaultTheme.highlight?.primary?.i500,
        i400: params.highlight?.primary?.i400
          ? params.highlight?.primary?.i400
          : defaultTheme.highlight?.primary?.i400,
        i300: params.highlight?.primary?.i300
          ? params.highlight?.primary?.i300
          : defaultTheme.highlight?.primary?.i300,
        i200: params.highlight?.primary?.i200
          ? params.highlight?.primary?.i200
          : defaultTheme.highlight?.primary?.i200,
        i100: params.highlight?.primary?.i100
          ? params.highlight?.primary?.i100
          : defaultTheme.highlight?.primary?.i100,
      },
    },
    font: {
      primary: params.font?.primary
        ? params.font?.primary
        : defaultTheme.font?.primary,
      secondary: params.font?.secondary
        ? params.font?.secondary
        : defaultTheme.font?.secondary,
    },
    config: {
      sizes: {
        xsm: params?.config?.sizes?.xsm
          ? params?.config?.sizes?.xsm
          : defaultTheme.config?.sizes?.xsm,
        sm: params?.config?.sizes?.sm
          ? params?.config?.sizes?.sm
          : defaultTheme.config?.sizes?.sm,
        md: params?.config?.sizes?.md
          ? params?.config?.sizes?.md
          : defaultTheme.config?.sizes?.md,
        lg: params?.config?.sizes?.lg
          ? params?.config?.sizes?.lg
          : defaultTheme.config?.sizes?.lg,
        xlg: params?.config?.sizes?.xlg
          ? params?.config?.sizes?.xlg
          : defaultTheme.config?.sizes?.xlg,
      },
    },
  });
}
