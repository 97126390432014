import telegramBot from './telegramBot';
import slackBot from './slackBot';
import notify, { INTERNAL_ERROR } from '../notify';

const logger = {};

const DEBUG_COLOR = '#31AFD4';
const TRACE_COLOR = '#FF007F';
const INFO_COLOR = '#DAA588';
const SEND_WARNING_TO_BOTS = process.env.REACT_APP_WARNING_TO_BOTS?.toLowerCase?.() === 'true';

const logflags = process.env.REACT_APP_LOG_FLAGS?.split?.('') || [];
const ACTIVE_FLAGS = {
  ERROR: logflags.indexOf('E') > -1,
  WARNING: logflags.indexOf('W') > -1,
  INFO: logflags.indexOf('I') > -1,
  DEBUG: logflags.indexOf('D') > -1,
  TRACE: logflags.indexOf('T') > -1,
};

/**
 * Send message to specific channel to warn if
 * one of our third party services is offline or maintance
 * @param {any} infos - things to informe
 */
logger.thirdParty = (...infos) => {
  slackBot.sendMessage('third_services', 'ERROR\n', ...infos);
};

/**
 * One or more functionalities are not working, preventing some
 * functionalities from working correctly.
 * @param {any} errors - Errors to show
 */
logger.error = (...errors) => {
  if (!ACTIVE_FLAGS.ERROR) return;

  console.error('[JFY]', ...errors);
  telegramBot.sendMessage('<b>ERROR:</b>\n', ...errors);
  slackBot.sendMessage('ERROR\n', ...errors);
  notify(INTERNAL_ERROR, 'error');
};

/**
 * One or more functionalities are not working, preventing some
 * functionalities from working correctly. Log it but don't inform the user
 * @param {any} errors - Errors to show
 */
logger.silentError = (...errors) => {
  if (!ACTIVE_FLAGS.ERROR) return;

  console.error('[JFY]', ...errors);
  telegramBot.sendMessage('<b>ERROR:</b>\n', ...errors);
  slackBot.sendMessage('ERROR\n', ...errors);
};

/**
 * Unexpected behavior happened inside the application, but it is
 * continuing its work and the key business features are operating as
 * expected.
 * @param {any} warnings - things to warn
 */
logger.warn = (...warnings) => {
  if (!ACTIVE_FLAGS.WARNING) return;

  console.warn('[JFY]', ...warnings);
  if (SEND_WARNING_TO_BOTS) {
    telegramBot.sendMessage('<b>WARNING:</b>\n', ...warnings);
    slackBot.sendMessage('WARNING\n', ...warnings);
  }
};

/**
 * An event happened, the event is purely informative and can be ignored
 * during normal operations.
 * @param {any} infos - things to informe
 */
logger.info = (...infos) => {
  if (!ACTIVE_FLAGS.INFO) return;

  console.log('%c[JFY] Info:', `color: ${INFO_COLOR};`, ...infos);
};

/**
 * A log level used for events considered to be useful during software
 * debugging when more granular information is needed.
 * @param {any} debugs - things to debug
 */
logger.debug = (...debugs) => {
  if (!ACTIVE_FLAGS.DEBUG) return;

  console.log('%c[JFY] Debug:', `color: ${DEBUG_COLOR};`, ...debugs);
};

/**
 * Log an object, considered to be useful during software debugging when
 * more granular information is needed.
 * @param {any} debug - thing to debug
 */
logger.debugDir = (debug) => {
  if (!ACTIVE_FLAGS.DEBUG) return;

  console.dir(debug);
};

/**
 * A log level describing events showing step by step execution of your
 * code that can be ignored during the standard operation, but may be
 * useful during extended debugging sessions.
 * @param {any} traces - things to trace
 */
logger.trace = (...traces) => {
  if (!ACTIVE_FLAGS.TRACE) return;

  console.log('%c[JFY] Trace:', `color: ${TRACE_COLOR};`, ...traces);
};

export default logger;
