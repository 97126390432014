export const formatProductsFromCart = (cart) => {
  let products = [];

  if ((cart.products?.length || []) > 0) {
    cart.products.forEach((form) => {
      form.items.forEach((item) => {
        let index = products.findIndex(
          (productItem) => productItem.id.toString() === item.id.toString()
        );
        if (index !== -1) {
          products[index].quantity += item.quantity;
        } else {
          products.push({ ...item, formId: form.formId });
        }
      });
    });
  }
  return products;
}
