export const PasswordIcon = ({ color = '#000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="22"
      fill="none"
      viewBox="0 0 12 22"
      className={className}
    >
      <path
        fill={color}
        d="M5.644 5.259a1.88 1.88 0 110-3.76 1.88 1.88 0 010 3.76zm0-3.008a1.128 1.128 0 100 2.256 1.128 1.128 0 000-2.256z"
      ></path>
      <path
        fill={color}
        d="M5.644 21.205l-1.88-1.88v-2.594l.617-.601-.617-.752v-1.135l.617-.601-.617-.752v-1.933A5.639 5.639 0 117.69.384a5.646 5.646 0 013.57 4.694 5.669 5.669 0 01-3.758 5.88v8.367l-1.857 1.88zm-1.128-2.188l1.128 1.128 1.128-1.128V10.4l.27-.083A4.887 4.887 0 006.215.785a4.887 4.887 0 00-1.962 9.533l.27.083v2.256l.888 1.082-.895.827v.549l.887 1.083-.887.857v1.962z"
      ></path>
    </svg>
  );
};

export default PasswordIcon;
