import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import EffectIcons from '../../../components/EffectIcons';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { ReactComponent as FullHeartIcon } from '../../../assets/icons/full-heart.svg';

import tabsStyle from '../../../styles/Tabs.module.css';
import styles from './FormulaDetails.module.css';

const Layout = ({
  activeTab,
  oldForm,
  setActiveTab,
  onClickFavorite,
  onAddToCart,
  formulaName,
  formulaDate,
  favorite,
  effects,
  ingredients,
  composition,
  answers,
  goBack,
  loading,
  onCreateNewForm
}) => {
  return (
    <ClientAreaContainer
      headerTitle={'Detalhes Fórmula: ' + formulaName}
      onGoBack={goBack}
      className={styles.container}
      headerClassName={styles.header}
    >
      <Tabs
        defaultIndex={activeTab}
        onSelect={(index) => setActiveTab(index)}
        className={tabsStyle.tabs}
      >
        <TabList className={tabsStyle.list}>
          <Tab
            className={
              tabsStyle.tab + ' ' + styles.tab + (activeTab === 0 ? ` ${tabsStyle.active}` : '')
            }
          >
            Detalhes
          </Tab>
          <Tab
            className={
              tabsStyle.tab + ' ' + styles.tab + (activeTab === 1 ? ` ${tabsStyle.active}` : '')
            }
          >
            Minhas respostas
          </Tab>
        </TabList>
        <TabPanel>
          {loading ? (
            <LoadingDots className={styles.loading} />
          ) : (
            <>
              <Block className={styles.block + ' ' + styles.general}>
                <div>
                  <div className={styles.infos}>
                    <p>
                      <b>Nome no Rótulo: </b>
                    </p>
                    <p>{formulaName}</p>
                  </div>
                  <div className={styles.infos}>
                    <p>
                      <b>Criada em: </b>
                    </p>
                    <p>{formulaDate}</p>
                  </div>
                </div>
                <div className={styles.favorite} onClick={onClickFavorite}>
                  {favorite ? <FullHeartIcon /> : <HeartIcon />}
                </div>
              </Block>
              <Block className={styles.block + ' ' + styles.button}>
                <Button
                  primary
                  text={!oldForm ? "Comprar novamente!" : 'Criar nova fórmula'}
                  className={styles.button}
                  onClick={!oldForm ? onAddToCart : onCreateNewForm}
                />
              </Block>
              <Block className={styles.block}>
                <h2>Efeitos:</h2>
                <div className={styles.effectsIcons}>
                  {effects.map((element, index) => {
                    return (
                      <div className={styles.effect} key={index}>
                        <div className={styles.effectIcon}>
                          <EffectIcons icon={element.iconName} />
                        </div>
                        <p className={styles.iconSubtitle}>{element.description}</p>
                      </div>
                    );
                  })}
                </div>
              </Block>
              {!oldForm && (<Block className={styles.block}>
                <h2>Ingredientes:</h2>
                <div className={styles.ingredientIcons}>
                  {ingredients.map((element, index) => {
                    return (
                      <div className={styles.ingredientIcon} key={index}>
                        <img
                          src={process.env.PUBLIC_URL + `/img/ingredients/${element.id}.svg`}
                          alt={element.ingredient}
                          className={styles.icon}
                        />
                        <span className={styles.iconSubtitle}>{element.ingredient}</span>
                      </div>
                    );
                  })}
                </div>
              </Block>)}
              {!oldForm ? (<Block className={styles.block + ' ' + styles.lastContent}>
                <b>Composição: </b>
                {composition}
              </Block>) : (<Block className={styles.block + ' ' + styles.lastContent}>
                <b>Ops!</b>{" "}
                Esta fórmula é antiga e não podemos utilizá-la, que tal fazer uma nova?
              </Block>)}
            </>
          )}
        </TabPanel>
        <TabPanel>
          {loading ? (
            <LoadingDots className={styles.loading} />
          ) : (
            <>
              {!oldForm ? (<Block title="Suas respostas" className={styles.block + ' ' + styles.formAnswers}>
                {answers.map((item, index) => {
                  return (
                    <div className={styles.questions} key={index}>
                      <b>{item.question}</b>
                      <div className={styles.answers}>
                        {item.answers.map((questionAnswer, index) => {
                          return (
                            <span className={styles.answer} key={index}>
                              {questionAnswer}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
                }
              </Block>) : (
                <Block className={styles.block}>
                  <b>Ops!</b>{" "}
                  Esta fórmula é antiga e não podemos utilizá-la, que tal fazer uma nova?
                </Block>)}
              <Block className={styles.block + ' ' + styles.button}>
                <Button
                  primary
                  text={!oldForm ? "Comprar novamente!" : 'Criar nova fórmula'}
                  className={styles.button}
                  onClick={!oldForm ? onAddToCart : onCreateNewForm}
                />
              </Block>
            </>
          )}
        </TabPanel>
      </Tabs>
    </ClientAreaContainer>
  );
};
export default Layout;
