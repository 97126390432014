import styled from 'styled-components';

import { ClientAreaContainer as ClientAreaContainerUI } from '../../../components/ClientAreaContainer';
import { JFYLogo as CJFYLogo } from '../../../lib/jfy-icons';
import { BlockDisplay } from '../../../components/Layout/BlockDisplay';

export const ClientAreaContainer = styled(ClientAreaContainerUI)`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
export const Container = styled(BlockDisplay)`
  padding: 1rem;
`;
export const JFYLogo = styled(CJFYLogo)`
  padding: 1rem;
`;
export const ButtonContainer = styled.div`
  padding-top: 1rem;
`;
