import styled from "styled-components";
import { IRadioButtonProps } from "./RadioButton.i";
import { defaultTheme as Theme } from "../../theme";

export const SRadioButton = styled.input<IRadioButtonProps>`
  position: relative;
  appearance: none;
  min-width: 100%;
  min-height: 100%;
  border-radius: 50%;
  outline: none;
  border: 2px solid ${Theme?.color?.base?.i600};
  background-color: ${Theme?.color?.base?.i500};
  margin: 0;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 50%;
    height: 50%;
    margin: 25% 25%;
    border-radius: 50%;
  }

  &:checked::before {
    background: ${Theme?.color?.primary?.i1000};; 
  }
    
  /* &:checked {
    border-color: ${Theme?.color?.base?.i500};
    background-color: ${Theme?.color?.base?.i400};
  } */
`;

// export const SCheckMark = styled.span`
//   width: 13px;
//   height: 13px;
//   border-radius: 50%;
//   background-color: ${Theme?.color?.base?.i400};
//   border: 1px solid ${Theme?.color?.base?.i500};
//   pointer-events: none;

//   ${SRadioButton}:checked + && {
//     background-color: red;
//   }
// `;
