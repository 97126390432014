import { IInputProps } from "./Input.i"
import * as React from "react";
import { SInput } from "./styles";

export const Input: React.FC<IInputProps> = ({ variant, ...props }) => {
  return (
    <SInput variant={variant} {...props} />
  )
}

