import { MoipCreditCard } from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';

export const CCHashed = async ({ number, cvc, expMonth, expYear }) => {
  let hash = await MoipCreditCard
    .setEncrypter(JSEncrypt, 'node')
    .setPubKey(process.env.REACT_APP_CC_WIRECARD)
    .setCreditCard({
      number: number,
      cvc: cvc,
      expirationMonth: expMonth,
      expirationYear: expYear
    })
    .hash()
  //.then(_hash => { return _hash });
  return hash;
};
