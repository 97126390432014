import { IResponsiveControlProps } from "./ResponsiveControl.i";

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const ResponsiveControl = {
  mobileS: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.mobileS})`,
  mobileM: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.mobileM})`,
  mobileL: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.mobileL})`,
  tablet: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.tablet})`,
  laptop: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.laptop})`,
  laptopL: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.laptopL})`,
  desktop: (minOrMax: IResponsiveControlProps) =>
    `(${minOrMax}-width: ${sizes.desktop})`,
};
