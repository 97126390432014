import {keyframes} from 'styled-components'

export const BounceAnimation = keyframes`
  0%,
  40%,
  100% {
    transform: scale(0.4);
  }

  20% {
    transform: scale(0.7);
  }
`