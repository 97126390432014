import { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Layout from './Layout';
import { creditCardNumberMask, shortDateMask, cpfMask, fullDateMask } from '../../utils/formater';
import {
  cpfSchema,
  birthdateSchema,
  creditCardNumberSchema,
  creditCardExpirationSchema,
} from '../../utils/validator';
import notify, { CARD_ADD_SUCCESS, VALIDATE_CARD, CARD_ADD_NEW_INFO_SUCCESS } from '../../utils/notify';
import { CheckoutContext } from '../../contexts/CheckoutSession/CheckoutSession';
import { creditCardService } from '../../services/creditCardService';
import { useForm, useFetch } from '../../hooks';
import logResponseError from '../../utils/loggerMessages';

const mockCardData = {
  number: '',
  holder: '',
  expirationMonth: '',
  expirationYear: '',
};

const CardDetails = ({ isCheckout }) => {
  const history = useHistory();
  let { token } = useParams();
  const location = useLocation();
  const { cart, updateCart } = useContext(CheckoutContext);
  const { fetchFromBackend } = useFetch();

  const [cardData, setCardData] = useState({ mockCardData });
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyData, setIsVerifyData] = useState(false);
  const [saveCardToNewOrder, setSaveCardToNewOrder] = useState(true);
  const { fields, handleChange, validateField, handleFinish, setFieldValue } = useForm({
    cardNumber: {
      schema: creditCardNumberSchema,
      mask: creditCardNumberMask,
      required: true,
    },
    expirationDate: {
      schema: creditCardExpirationSchema,
      mask: shortDateMask,
      required: true,
    },
    cvv2: {
      required: true,
    },
    cardHolder: {
      required: true,
    },
    cpfCnpj: {
      mask: cpfMask,
      schema: cpfSchema,
      required: true,
    },
    birthdate: {
      required: true,
      schema: birthdateSchema,
      mask: fullDateMask,
    },
    saveCard: {
      value: true,
    },
  });

  const redirectToHome = () => {
    history.replace({
      pathname: '/',
      search: window.location.search,
    });
  };

  const restoreCardInfo = () => {
    const contextCardInfo = cart.paymentInfo.cardInfo;

    setFieldValue('cardNumber', contextCardInfo?.number_card);
    setFieldValue('expirationDate', contextCardInfo?.expire_date);
    setFieldValue('cardHolder', contextCardInfo?.card_owner);
    setFieldValue('cvv2', contextCardInfo?.cvv);
    setFieldValue('cpfCnpj', contextCardInfo?.customer_cpf);
    setFieldValue('birthdate', contextCardInfo?.birth_date);
    setFieldValue('saveCard', contextCardInfo?.save_card);
  }

  const getGatewayInfo = async () => {
    let gateway = '';
    try {
      const res = await fetchFromBackend(`/api/jfy/customers/payment_settings`, 'GET', {});
      if (Number(res?.response_code) === 0) {
        gateway = res.content.gateway_alias;
      }
    } catch (error) {
      logResponseError(
        'Failed get Cards - getGatewayInfo - CardDetails',
        '/api/jfy/customers/payment_settings',
        'GET',
        error,
        {}
      );
    }
    return gateway;
  };

  const saveCard = async (id) => {
    const { valid } = await handleFinish();

    if (valid) {
      notify(isVerifyData ? CARD_ADD_NEW_INFO_SUCCESS : CARD_ADD_SUCCESS, 'success');
      if (isCheckout) {
        setIsLoading(true);
        const gatewayPayment = await getGatewayInfo();

        await creditCardService({
          gatewayPayment: gatewayPayment,
          number: fields.cardNumber.value,
          card_holder_name: fields.cardHolder.value,
          card_expiration_date: fields.expirationDate.value,
          cvc: fields.cvv2.value,
        }).then((credit_card_hash) => {
          const numberCard = fields.cardNumber.value.replace(/\D/g, '');
          const newCardInfo = {
            cardInfo: {
              card_owner: fields.cardHolder.value.trim(),
              expire_date: fields.expirationDate.value.replace(/\D/g, ''),
              last4_number_card: numberCard.substring(numberCard.length - 4, numberCard.length),
              number_card: numberCard,
              birth_date: fields.birthdate.value,
              cvv: fields.cvv2.value,
              card: 'Cartão de Crédito',
              customer_cpf: fields.cpfCnpj.value.replace(/\D/g, ''),
              credit_card_hash: credit_card_hash,
              save_card: fields.saveCard.value,
            },
            payment_method: 'credit-card',
          };
          setIsLoading(false);
          updateCart({ ...cart, paymentInfo: newCardInfo });
          history.push({
            pathname: '/checkout/' + token + '/summary-purchase',
            search: window.location.search,
          });
        });
      }
    }
  };

  useEffect(() => {
    setCardData({
      number: fields.cardNumber?.value || '',
      holder: fields.cardHolder?.value || '',
      expirationMonth: fields.expirationDate?.value?.substring?.(0, 2) || '-',
      expirationYear: fields.expirationDate?.value?.substring?.(3) || '-',
    });
  }, [fields.expirationDate, fields.cardHolder, fields.cardNumber]);

  useEffect(() => {
    if (!cart.token) return redirectToHome(history);
    if (!location.state?.checkCardInformation) return;
    setIsVerifyData(true);
    restoreCardInfo();

    notify(VALIDATE_CARD, 'error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      goBack={() => {
        history.goBack();
      }}
      isVerifyData={isVerifyData}
      cardPreviewData={cardData}
      fields={fields}
      handleChange={handleChange}
      validateField={validateField}
      handleSave={() => saveCard()}
      saveCardToNewOrder={saveCardToNewOrder}
      isLoading={isLoading}
      setSaveCardToNewOrder={setSaveCardToNewOrder}
    />
  );
};
export default CardDetails;
