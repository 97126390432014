import styled from 'styled-components';
import { ClientAreaContainer as UIClientAreaContainer } from '../../../components/ClientAreaContainer';
import { JFYLogo as Logo } from '../../../lib/jfy-icons';

export const ClientAreaContainer = styled(UIClientAreaContainer)`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JFYLogo = styled(Logo)`
  width: 80%;
  height: 50%;
`;

export const LoadingContainer = styled.div`
  display: block;
`;
