import styled from 'styled-components';
import { defaultTheme } from '../../../lib/jfy-ui'

import { TrashIcon as TrashSVG, PensilIcon as PensilSVG } from "../../../lib/jfy-icons";

export const TrashIcon = styled(TrashSVG)`
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.12rem;
  border: 0.5px solid ${(props) => props.theme.color.base.i1000};
`;

export const PensilIcon = styled(PensilSVG).attrs({
  fill: defaultTheme.color.base.i700
})`
  padding: 0.22rem;
  fill: red;
  width: 0.9rem;
  height: 0.9rem;

  border: 0.5px solid ${(props) => props.theme.color.base.i1000};
`;
