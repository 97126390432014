import React from "react";
import formatPrice from "../../utils/formatPrice";
import { Text } from "../../components/Text";

import {
  Container,
  RadioContainer,
  DescriptionContainer,
  AmountContainer,
  Radio,
  Marked,
  Amount,
} from "./styles";

function ShippingChoice({
  value,
  selected,
  displayName,
  days,
  price,
  name,
  handleChangeShipping,
  freeShipping,
}) {
  return (
    <Container
      selected={value === selected}
      onClick={() => handleChangeShipping(value)}
    >
      <RadioContainer>
        <Radio
          type="radio"
          name={name}
          checked={value === selected}
          Frete
          onChange={() => handleChangeShipping(value)}
        />
      </RadioContainer>
      <DescriptionContainer>
        <Text bold>{displayName}</Text>
        <Text isSecundary fontSize="0.6rem">
          Entregue em até {days} dias úteis
        </Text>
      </DescriptionContainer>
      <AmountContainer>
        {value === selected && <Marked />}
        <Amount selected={value === selected}>
          <Text bold highlight={value === selected && freeShipping}>
            {freeShipping ? "Grátis" : formatPrice(price)}
          </Text>
        </Amount>
      </AmountContainer>
    </Container>
  );
}

export { ShippingChoice };
