import styled from 'styled-components';
import {
  AWaitingIcon as CAWaitingIcon,
  ApprovedIcon as CApprovedIcon,
  DaniedIcon as CDaniedIcon,
} from '../../../../lib/jfy-icons';

export const StatusPaymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const IconContainer = styled.div`
  padding-top: 0.5rem;
`;

export const AWaitingIcon = styled(CAWaitingIcon).attrs({
  height: 90,
  width: 90
})``;
export const ApprovedIcon = styled(CApprovedIcon)``;
export const DaniedIcon = styled(CDaniedIcon)``;
