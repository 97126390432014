import React from "react";

import { LoadingDots } from "../../../lib/jfy-ui";

import { BlockDisplay } from "../../../components/Layout/BlockDisplay";

import { Pix } from "./Pix";

import { JFYLogo, LogoContainer, ClientAreaContainer } from "./styles";

const Layout = ({
  codeQr,
  countdownDesablePix,
  isFetching,
  purchaseAmount,
}) => {
  return (
    <>
      {isFetching ? (
        <>
          <ClientAreaContainer>
            <LoadingDots
              data-testid="loding-dots"
              size="md"
              diffTimeLoadingAnimation={1.6}
            />
          </ClientAreaContainer>
        </>
      ) : (
        <ClientAreaContainer>
          <LogoContainer>
            <JFYLogo />
          </LogoContainer>
          <BlockDisplay title="Aguardando Pagamento">
            <Pix
              pixContentQrCode={codeQr}
              countdownDesablePix={countdownDesablePix}
              purchaseAmount={purchaseAmount}
            />
          </BlockDisplay>
        </ClientAreaContainer>
      )}
    </>
  );
};

export { Layout };
