import { SelectContainer, Message } from './styles';

function Select({
  value,
  name,
  className,
  classNameContainer,
  onChange,
  valid,
  message,
  onBlur,
  options,
  ...props
}) {
  const handleChange = (event) => {
    if (onChange) {
      onChange?.(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur?.(event);
    }
  };

  return (
    <>
      <SelectContainer
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        value={value}
        invalid={!valid && message}
        {...props}
      >
        {options &&
          options.map((item) => (
            <option key={item.value.toString()} value={item.value}>
              {item.label}
            </option>
          ))}
      </SelectContainer>
      {message && <Message>{message}</Message>}
    </>
  );
}

export { Select };
