import { ClientAreaContainer, Block } from '../../../components/ClientAreaContainer';
import Button from '../../../components/Button';

import styles from './SendEmail.module.css';
const Layout = ({ goBack, onSubjectChange, onMessageChange, sendEmail }) => {
  return (
    <ClientAreaContainer
      headerTitle="Enviar e-mail"
      onGoBack={goBack}
      className={styles.container}
      headerClassName={styles.header}
    >
      <Block
        title="Envie sua mensagem:"
        className={styles.block}
      >
        <div className={styles.emailContainer}>
          <b className={styles.labels}>Assunto: </b>
          <select name="Assunto" onChange={onSubjectChange} className={styles.selectContainer}>
            <option value="0">Sobre minha fórmula</option>
            <option value="1">Sobre minha entrega/pedido</option>
            <option value="2">Outros assuntos</option>
          </select>
          <b className={styles.labels}>Mensagem:</b>
          <textarea
            rows="10"
            placeholder="Envie sua mensagem..."
            className={styles.textarea}
            onChange={onMessageChange}
          >
          </textarea>
          <div className={styles.buttonContainer}>
            <Button primary text="Enviar" onClick={sendEmail} className={styles.button} />
          </div>
        </div>

      </Block>
    </ClientAreaContainer>
  );
};
export default Layout;
