import React from 'react';

import styles from './Menu.module.css';

function MenuItem({redirectTo,id,selected,icon,children}) {
  return( 
    <div
    id={id}
    className={styles.itemContainer}
    onClick={redirectTo}>
        <div className={styles.item}>
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={selected ? styles.selected : ''}>{children}</div>
        </div>
    </div>
  );
}

export  {MenuItem};