import { LoadingDots } from '../../../lib/jfy-ui';
import { ClientAreaContainer } from '../../../components/ClientAreaContainer';

const Layout = ({ goBack }) => {
  return (
    <ClientAreaContainer onGoBack={goBack} headerTitle='Preparando o carrinho'>
      <LoadingDots size='md' diffTimeLoadingAnimation={1.6} />
    </ClientAreaContainer>
  );
};

export { Layout };
