import Layout from './Layout';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../hooks';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import logger from '../../utils/logger';
import { validateDateToIncrement } from '../../utils/validateDateToIncrement';

dayjs.extend(dayjsBusinessDays);

const MyOrders = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  let history = useHistory();
  const { fetchFromBackend } = useFetch();

  let redirectToDetails = (orderID) => {
    history.push({ pathname: '/orders/details/' + orderID, search: window.location.search });
  };

  const handleNewOrder = () => {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set('callback', `${window.location.origin}/formulas/details/{{formId}}`)
    window.location.replace(process.env.REACT_APP_FORM_URL + '?' + urlParams.toString())
  };

  const redirectToRebuy = (orderID) => {
    history.push({ pathname: '/pre-checkout/' + orderID + '/order', search: window.location.search });
  };

  let handleFetch = async (page, offset) => {
    setLoading(true);
    let url = page
      ? '/api/customers/orders/?page=' + page + '&maxdays=' + offset
      : '/api/customers/orders/';
    let orders = await fetchFromBackend(url, 'GET', {}).catch((error) => {
      logger.error('Failed to load orders\n', error);
    });

    if (orders?.content?.data.length > 0) {
      let parsedOrders = orders.content.data.map((order) => {
        let formulas = order.formulas.map((formula) => {
          return formula.formula_name;
        });
        return {
          id: order.id,
          display_number: order.display_id,
          number: order.id,
          status_id: order.status.data.name,
          date_delivery: order.date_delivery.date
            ? dayjs(order.created_at.date)
              .businessDaysAdd(parseInt(order.delivery_days) + Number(validateDateToIncrement(order.created_at.date, order?.status_id)?.daysToIncrement))
              .subtract(3, 'hours')
              .format('DD/MM/YYYY')
            : '-',
          create_date: order.created_at.date
            ? dayjs(order.created_at.date).subtract(3, 'hours').format('DD/MM/YYYY')
            : '-',
          value_total: parseFloat(order.value_total) * 100,
          formulas: formulas,
        };
      });
      setOrders(parsedOrders);
      setCurrentPage(orders.content.meta.pagination.current_page - 1);
      setPageCount(orders.content.meta.pagination.total_pages);
    }
    setLoading(false);
  };

  useEffect(() => {
    setOrders([]);
    handleFetch(1, activeTab * 90);
    // when change tab, we need fix current page
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  let handlePageChange = (page) => {
    setOrders([]);
    // page is indexed in  0, so we need to add 1 to keep things consistent
    let corrected_page = page.selected + 1;
    handleFetch(corrected_page, activeTab * 90);
  };

  return (
    <Layout
      loading={loading}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      redirectToDetails={redirectToDetails}
      orders={orders}
      pageCount={pageCount}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      redirectToRebuy={redirectToRebuy}
      handleNewOrder={handleNewOrder}
    />
  );
};

export default MyOrders;
