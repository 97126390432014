export const InfoIcon = ({ color = '#1D1D1B', className, size, viewBox }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ? viewBox : '0 0 17 17'}
      width={typeof size === 'object' ? size?.width : size}
      height={typeof size === 'object' ? size?.height : size}
      className={className}
      aria-labelledby="titleIcon"
      aria-hidden="true"
      focusable="false"
      role="img"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        fill={color}
        d="M12.1184 22.065C13.316 22.065 14.2869 21.1035 14.2869 19.9175C14.2869 18.7315 13.316 17.77 12.1184 17.77C10.9208 17.77 9.94995 18.7315 9.94995 19.9175C9.94995 21.1035 10.9208 22.065 12.1184 22.065Z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        fill={color}
        d="M12.07 2.2C8.78407 2.2 6.08866 4.75339 5.90073 7.96178C5.88916 8.15864 6.0518 8.3197 6.2513 8.3197H9.57628C9.77578 8.3197 9.9169 8.15792 9.95015 7.96321C10.1215 6.96891 11.0169 6.20869 12.07 6.20869C13.2453 6.20869 14.2501 7.15646 14.2501 8.32041C14.2501 9.16582 13.6928 9.73362 12.7162 10.7C11.5893 11.8167 10.0461 13.3213 10.0461 15.8668V16.5168C10.0461 16.7136 10.208 16.8747 10.4075 16.8747H13.7325C13.932 16.8747 14.0939 16.7136 14.0939 16.5168V15.8668C14.0939 15.0207 14.6035 14.4773 15.58 13.5102C16.7076 12.3935 18.2501 10.8652 18.2501 8.3197C18.2501 4.94524 15.4774 2.2 12.07 2.2Z"
      ></path>
    </svg>
  );
};

export default InfoIcon;
