import styles from './CreditCardPreview.module.css';

export const CreditCardPreview = ({ number, holder, expirationMonth, expirationYear }) => (
  <div className={styles.card}>
    <div className={styles.brand}>
      <div className={styles.logo}></div>
    </div>
    <div className={styles.number}>
      <h3>{number?.substring(0, 4)}</h3>
      <div className={styles.dots}>
        {number?.substring(4)
          .split('')
          .map((x, index) => x !== ' ' && <div className={styles.dot} key={index}></div>)}
      </div>
    </div>
    <div className={styles.holderAndExpiration}>
      <div className={styles.holder}>
        <p className={styles.label}>Nome</p>
        <p className={styles.name}>{holder}</p>
      </div>
      <div className={styles.expiration}>
        <p className={styles.label}>Mês/Ano</p>
        <p className={styles.date}>
          {expirationMonth}
          <span>/</span>
          {expirationYear}
        </p>
      </div>
    </div>
  </div>
);

export default CreditCardPreview;
