import Layout from './Layout';
import { passwordSchema, confirmationPasswordSchema } from '../../utils/validator';
import { useForm, useFetch } from '../../hooks';
import { useEffect } from 'react';
import logger from '../../utils/logger';
import notify, { WRONG_CURR_PW_ON_CHANGE, SUCCESS_PW_CHANGE } from '../../utils/notify';
import logResponseError from '../../utils/loggerMessages';

const ResetPasswordPrivate = () => {
  const { fields, handleChange, validateField, setFieldValue, handleFinish } = useForm({
    currentPassword: {
      required: true,
    },
    newPassword: {
      required: true,
      schema: passwordSchema,
    },
    passwordConfirmation: {
      required: true,
      schema: confirmationPasswordSchema,
    },
  });

  const { fetchFromBackend } = useFetch();

  useEffect(() => {
    confirmationPasswordSchema.refValue = fields.newPassword.value;
  }, [fields]);

  const handleSubmit = async () => {
    const { valid } = await handleFinish();

    if (valid) {
      const body = {
        current_password: fields.currentPassword.value,
        new_password: fields.newPassword.value,
      };
      try {
        const url = '/api/customers/password_change/';
        const method = 'POST';
        const res = await fetchFromBackend(url, method, body);
        switch (res.response_code) {
          case 0:
            notify(SUCCESS_PW_CHANGE, 'success');
            Object.keys(fields).forEach((fieldName) => setFieldValue(fieldName, ''));
            break;
          case 4:
            notify(WRONG_CURR_PW_ON_CHANGE, 'warn');
            break;
          default:
            logResponseError('reset password - private', url, method, res, false);
            break;
        }
      } catch (error) {
        logger.error('Failed to reset password - private (internal error)\n', error);
      }
    }
  };

  return (
    <Layout
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      validateField={validateField}
      fields={fields}
    />
  );
};

export default ResetPasswordPrivate;
