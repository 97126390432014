export const OrdersIcon = ({ color = '#000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
      className={className}
    >
      <path
        fill={color}
        d="M18.908 7.045a.443.443 0 00-.347-.173h-3.883V5A5.105 5.105 0 009.573 0a5.107 5.107 0 00-5.105 4.999v1.873H.442a.442.442 0 00-.427.557l3.016 11.244a.442.442 0 00.427.327h12.084a.442.442 0 00.427-.327L18.985 7.43a.441.441 0 00-.077-.384zM5.35 4.999a4.221 4.221 0 017.173-2.922 4.22 4.22 0 011.27 2.922v1.873H5.351V5zm9.853 13.117H3.798L1.021 7.756h3.447v1.471a.442.442 0 00.883 0V7.756h8.444v1.471a.442.442 0 00.883 0V7.756h3.304l-2.778 10.36z"
      ></path>
    </svg>
  );
};

export default OrdersIcon;
