import { Layout } from "./Layout";

const DisplayProductController = ({
  decrementFunction,
  incrementFunction,
  numberToRender,
  productName,
  productSize,
  price,
  imageLink,
  enableBenefitProduct,
  ...props
}) => {
  return (
    <Layout
      decrementFunction={decrementFunction}
      incrementFunction={incrementFunction}
      numberToRender={numberToRender}
      productName={productName}
      productSize={productSize}
      price={price}
      imageLink={imageLink}
      enableBenefitProduct={enableBenefitProduct}
      {...props}
    />
  );
};

export { DisplayProductController };
