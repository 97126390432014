import styled from 'styled-components';

import { JFYLogo as Logo } from '../../../lib/jfy-icons';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 2rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JFYLogo = styled(Logo)`
  width: 70%;
  height: 70%;
`;
