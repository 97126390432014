import React from 'react';
import { LoadingDots } from '../../../lib/jfy-ui';

import { ClientAreaContainer } from '../../../components/ClientAreaContainer';

import { Text } from '../../../components/Text';

import { GridContainer, JFYLogo, LogoContainer } from './styles';

export const Layout = () => {
  return (
    <ClientAreaContainer>
      <GridContainer>
        <LogoContainer>
          <JFYLogo />
        </LogoContainer>
        <Text bold align='center' fontSize='1rem'>
          Aguarde uns instantes
        </Text>
        <Text align='center' fontSize='0.8rem'>
          Estamos processando seu pedido! não feche esta tela!
        </Text>
        <LoadingDots size='md' diffTimeLoadingAnimation={1.6} />
      </GridContainer>
    </ClientAreaContainer>
  );
};
