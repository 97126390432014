import Layout from './Layout';
import { confirmationPasswordSchema, passwordSchema } from '../../utils/validator';
import { useForm, useFetch } from '../../hooks';
import { useEffect, useState } from 'react';
import notify, { SUCCESS_PW_CHANGE } from '../../utils/notify';
import logger from '../../utils/logger';
import { useHistory } from 'react-router-dom';
import logResponseError from '../../utils/loggerMessages';

const ResetPasswordPublic = () => {
  const { fields, handleChange, validateField, handleFinish } = useForm({
    password: {
      required: true,
      schema: passwordSchema,
    },
    passwordConfirmation: {
      required: true,
      schema: confirmationPasswordSchema,
    },
  });
  const [validToken, setValidToken] = useState(false);
  const [uid, setUid] = useState();
  const [token, setToken] = useState();
  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { fetchFromBackend } = useFetch();

  const validateToken = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uidTemp = urlSearchParams.get('uid');
    const tokenTemp = urlSearchParams.get('token');
    const response = await fetchFromBackend(
      '/api/customers/password_reset/confirm/?uidb64=' + uidTemp + '&token=' + tokenTemp,
      'GET',
      {}
    ).catch((error) => {
      logger.error('Failed to validate token on public password reset\n', error);
    });

    if (response.response_code === 0) {
      setValidToken(true);
      setUid(uidTemp);
      setToken(tokenTemp);
    } else {
      history.push({ pathname: '/login', search: window.location.search });
    }
  };

  const resetPassword = async () => {
    const { valid } = await handleFinish();

    if (valid) {
      const body = {
        uidb64: uid,
        token: token,
        new_password: fields.password.value,
      };
      const url = '/api/customers/password_reset/confirm/';
      const method = 'POST';
      const response = await fetchFromBackend(url, method, body).catch((error) => {
        logger.error('Failed to reset password - public (internal error)\n', error);
      });

      if (response.response_code === 0) {
        notify(SUCCESS_PW_CHANGE, 'success');
        setPasswordReset(true);
      } else {
        logResponseError('reset password - public', url, method, response, false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    confirmationPasswordSchema.refValue = fields.password.value;
  }, [fields]);

  useEffect(() => {
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      handleChange={handleChange}
      validateField={validateField}
      fields={fields}
      onResetPassword={resetPassword}
      validToken={validToken}
      loading={loading}
      passwordReset={passwordReset}
      onClickLogin={() => {
        history.push({ pathname: '/login', search: window.location.search });
      }}
    />
  );
};

export default ResetPasswordPublic;
