import styled from 'styled-components';

export const SelectContainer = styled.select`
  border: 1px solid ${(props) => props.theme.color.base.i800};
  font-family: ${(props) => props.theme.font.secondary};
  width: 100%;
  outline: none;
  padding: 7px 12px;
  font-size: 12px;
  max-height: 30px;

  ${({ invalid }) =>
    invalid &&
    `
    border: 1px solid var(--color-maximum-red);
    box-shadow: 0 4px 4px rgba(var(--color-new-york-pink-rgb), 0.8);`}

  option {
    color: ${(props) => props.theme.color.base.i900};
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0.5rem;

    :hover {
      background: ${(props) => props.theme.color.base.i300};
    }
  }
`;

export const Message = styled.p`
  margin: 6px 7px;
  font-family: var(--font-family-body);
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: var(--color-maximum-red);
`;
