import { useContext, useEffect } from 'react';
import Layout from './Layout';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../hooks';
import { cpfCnpjSchema } from '../../utils/validator';
import { cpfCnpjMask } from '../../utils/formater';
import AuthContext from '../../contexts/Auth';

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { fields, handleChange, validateField, handleFinish, formIsInvalid } = useForm({
    cpfCnpj: {
      required: true,
      mask: cpfCnpjMask,
      schema: cpfCnpjSchema,
    },
    password: {
      required: true,
    },
  });

  const onClickLogin = async () => {
    const { valid } = await handleFinish();

    if (valid) {
      authContext.login(fields.cpfCnpj.value, fields.password.value);
    }
  };

  useEffect(() => {
    if (authContext.signed) {
      history.push({ pathname: '/', search: window.location.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.signed]);

  const onClickSignup = () => {
    history.push({ pathname: '/signup', search: window.location.search });
  };

  const onClickRecoverPassword = () => {
    history.push({ pathname: '/forgot-password', search: window.location.search });
  };

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      validateField={validateField}
      onClickLogin={onClickLogin}
      onClickSignup={onClickSignup}
      onClickRecoverPassword={onClickRecoverPassword}
      disableLoginButton={formIsInvalid}
    />
  );
};

export default Login;
